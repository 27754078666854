export const reagents_kombi = {
  "ferrocene": '[CH-]1C=CC=C1.[CH-]1C=CC=C1.[Fe+2]',
  "1,1'-Bis(diphenylphosphino)ferrocene": 'c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.[Fe+2]',
  "dppf": 'c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.[Fe+2]',
  "[1,1'-Bis(diphenylphosphino)ferrocene]palladium(II) dichloride": 'c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.Cl[Pd]Cl.[Fe+2]',
  "Pd(dppf)Cl2": 'c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.Cl[Pd]Cl.[Fe+2]',
  "[1,1′-Bis(diphenylphosphino)ferrocene]dichloropalladium(II) complex with dichloromethane": 'c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.c1ccc(cc1)P(c1ccccc1)C1=CC=C[CH-]1.ClCCl.Cl[Pd]Cl.[Fe+2]',
  "(-)-10,2-Camphorsultam": 'CC1(C)C2(C3)CCC1CC2NS3(=O)=O',
  "(-)-10-Camphorsulfonic Acid": 'O=C(CC(C(C)1C)CC2)C21CS(=O)(O)=O',
  "(-)-2,2'-Methylenebis[(3aS,8aR)-3a,8a-dihydro-8H-indeno[1,2-d]oxazole]": '[H][C@@]12N=C(CC3=N[C@]4([H])[C@](CC5=C4C=CC=C5)([H])O3)O[C@]1([H])CC6=C2C=CC=C6',
  "(-)-4,5-Bis[hydroxy(diphenyl)methyl]-2,2-dimethyl-1,3-dioxolane": 'CC1(C)OC(C(C2=CC=CC=C2)(O)C3=CC=CC=C3)C(C(C4=CC=CC=C4)(O)C5=CC=CC=C5)O1',
  "(-)-B-Chlorodiisopinocampheylborane": 'CC1(C)C2CC(B(Cl)C3CC4CC(C3C)C4(C)C)C(C)C1C2',
  "(-)-Benzotetramisole": 'C12=N[C@@H](C3=CC=CC=C3)CN1C4=CC=CC=C4S2',
  "(-)-Isopinocampheylborane TMEDA Complex": 'B[C@H]1[C@H](C)C2C(C(C1)C2)(C)C.B[C@H]3[C@H](C)C4C(C(C3)C4)(C)C.CN(C)CCN(C)C',
  "(-)-Sparteine": '[H][C@]12CCCCN1C[C@H]3[C@]4([H])CCCCN4C[C@@H]2C3',
  "(+)-(5aR,10bS)-5a,10b-Dihydro-2-(2,4,6-trimethylphenyl)-4H,6H-indeno[2,1-b][1,2,4]triazolo[4,3-d][1,4]oxazinium Chloride Monohydrate": 'CC1=C(N2N=C3[N+]([C@]4([H])[C@](CC5=C4C=CC=C5)([H])OC3)=C2)C(C)=CC(C)=C1.[H]O[H].[Cl-]',
  "(+)-10,2-Camphorsultam": 'CC1(C)C2(C3)CCC1CC2NS3(=O)=O',
  "(+)-10-Camphorsulfonic Acid": 'O=C(CC(C(C)1C)CC2)C21CS(=O)(O)=O',
  "(+)-2,2'-Methylenebis[(3aR,8aS)-3a,8a-dihydro-8H-indeno[1,2-d]oxazole]": '[H][C@]12N=C(CC3=N[C@@]4([H])[C@@](CC5=C4C=CC=C5)([H])O3)O[C@@]1([H])CC6=C2C=CC=C6',
  "(+)-4,5-Bis[hydroxy(diphenyl)methyl]-2,2-dimethyl-1,3-dioxolane": 'CC1(C)OC(C(C2=CC=CC=C2)(O)C3=CC=CC=C3)C(C(C4=CC=CC=C4)(O)C5=CC=CC=C5)O1',
  "(+)-4,5-Bis[hydroxy(diphenyl)methyl]-2-methyl-2-phenyl-1,3-dioxolane": 'CC1(C2=CC=CC=C2)OC(C(C3=CC=CC=C3)(O)C4=CC=CC=C4)C(C(C5=CC=CC=C5)(O)C6=CC=CC=C6)O1',
  "(+)-B-Chlorodiisopinocampheylborane": 'CN(C)CCN(C)C.CC1(C)C2CC(BC3CC4CC(C3C)C4(C)C)C(C)C1C2',
  "(+)-Sparteine": '[H][C@]12CCCCN1C[C@H]3[C@]4([H])CCCCN4C[C@@H]2C3',
  "(±)-1,1'-Binaphthyl-2,2'-diyl Hydrogenphosphate": 'O=P1(OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)O',
  "(±)-10-Camphorsulfonic Acid": 'O=C(CC(C(C)1C)CC2)C21CS(=O)(O)=O',
  "(1,10-Phenanthroline)(trifluoromethyl)(triphenylphosphine)copper(I)": '[Cu]C(F)(F)F.P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.C45=CC=C6C=CC=NC6=C4N=CC=C5',
  "(1,5-Cyclooctadiene)(methoxy)iridium(I)": '[Ir]OC.C1=C\\CC/C=C\\CC/1',
  "(1,5-Cyclooctadiene)(pyridine)(tricyclohexylphosphine)iridium(I) Hexafluorophosphate": 'F[P-](F)(F)(F)(F)F.P(C1CCCCC1)(C2CCCCC2)C3CCCCC3.[Ir+].C4=C\\CC/C=C\\CC/4.C5=NC=CC=C5',
  "(11bS)-2,6-Bis[bis[3,5-bis(trifluoromethyl)phenyl]hydroxymethyl]-3,5-dihydrospiro[4H-dinaphth[2,1-c:1',2'-e]azepine-4,4'-morpholinium] Bromide": 'OC(C1=C2C(C3=C4C=CC=CC4=CC(C(C5=CC(C(F)(F)F)=CC(C(F)(F)F)=C5)(C6=CC(C(F)(F)F)=CC(C(F)(F)F)=C6)O)=C3C(Br)[N]7(CCOCC7)C2)=C8C=CC=CC8=C1)(C9=CC(C(F)(F)F)=CC(C(F)(F)F)=C9)C%10=CC(C(F)(F)F)=CC(C(F)(F)F)=C%10',
  "(11R,12R)-9,10-Dihydro-9,10-ethanoanthracene-11,12-diamine": 'N[C@H]1[C@H](N)C2C3=C(C=CC=C3)C1C4=CC=CC=C24',
  "(11S,12S)-9,10-Dihydro-9,10-ethanoanthracene-11,12-diamine": 'N[C@@H]1[C@@H](N)C2C3=C(C=CC=C3)C1C4=CC=CC=C24',
  "(1-Bromovinyl)trimethylsilane": 'C[Si](C)(C(Br)=C)C',
  "(1E)-1-tert-Butoxy-1-(trimethylsilyloxy)propene": 'C/C=C(OC(C)(C)C)/O[Si](C)(C)C',
  "(1R,2R)-(-)-1,2-Cyclohexanediamine": 'N[C@H]1[C@H](N)CCCC1',
  "(1R,2R)-(-)-N,N'-Dimethylcyclohexane-1,2-diamine": 'CN[C@H]1[C@H](NC)CCCC1',
  "(1R,2R)-(+)-1,2-Diphenylethylenediamine": 'N[C@H](C1=CC=CC=C1)[C@@H](C2=CC=CC=C2)N',
  "(1R,2R)-1,2-Bis(2,4,6-trimethylphenyl)ethylenediamine": 'N[C@H](C1=C(C)C=C(C)C=C1C)[C@@H](C2=C(C)C=C(C)C=C2C)N',
  "(1R,2R)-1,2-Bis(2-hydroxyphenyl)ethylenediamine": 'N[C@H](C1=CC=CC=C1O)[C@@H](C2=CC=CC=C2O)N',
  "(1R,2R)-N,N'-Bis(2-acetyl-3-oxo-2-butenylidene)-1,2-dimesitylethylenediaminato Cobalt(II)": 'CC1=C(C(C)=CC(C)=C1)[C@@H](/N=C/C=C(C(C)=O)\\C[O-])[C@@H](C2=C(C)C=C(C)C=C2C)/N=C/C=C(C(C)=O)\\C[O-].[Co+2]',
  "(1R,2R)-N,N'-Bis[3-oxo-2-(2,4,6-trimethylbenzoyl)butylidene]-1,2-diphenylethylenediaminato Cobalt(II)": '[O-]/C(C)=C(C(C1=C(C)C=C(C)C=C1C)=O)\\C=N\\[C@@H]([C@@H](C2=CC=CC=C2)/N=C/C(C(C3=C(C)C=C(C)C=C3C)=O)=C(C)\\[O-])C4=CC=CC=C4.[Co+2]',
  "(1R,2R)-N,N'-Dihydroxy-N,N'-bis(diphenylacetyl)cyclohexane-1,2-diamine": 'O=C(N(O)[C@H]1[C@H](N(O)C(C(C2=CC=CC=C2)C3=CC=CC=C3)=O)CCCC1)C(C4=CC=CC=C4)C5=CC=CC=C5',
  "(1R,2R,4R,5R)-2,5-Bis(3,5-di-tert-butyl-2-hydroxybenzylideneamino)bicyclo[2.2.1]heptane": 'OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=N/[C@H]2[C@@]3([H])C[C@@H](/N=C/C4=CC(C(C)(C)C)=CC(C(C)(C)C)=C4O)[C@@](C3)([H])C2',
  "(1R,2S)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenyl-1-propanol": 'C[C@H](N(CC1=CC=CC=C1)S(=O)(C(C(C)=CC(C)=C2)=C2C)=O)[C@@H](C3=CC=CC=C3)O',
  "(1R,2S)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenylpropyl Propionate": 'CCC(O[C@H](C1=CC=CC=C1)[C@@H](N(CC2=CC=CC=C2)S(=O)(C(C(C)=CC(C)=C3)=C3C)=O)C)=O',
  "(1R,3R)-5-(Bromomethylene)-1,3-bis(tert-butyldimethylsilyloxy)cyclohexane": 'C[Si](O[C@H](C1)C[C@H](O[Si](C)(C(C)(C)C)C)CC1=CBr)(C(C)(C)C)C',
  "(1R,3S,5R,7R,8aS)-7-Ethylhexahydro-1-(6-hydroxy-4-quinolinyl)-3,7-methano-1H-pyrrolo[2,1-c][1,4]oxazine": 'OC1=CC=C2N=CC=C([C@H]3O[C@](C4)([H])C[N@@]5[C@@]3([H])C[C@@]4(CC)C5)C2=C1',
  "(1R,4R,5R)-4,7,7-Trimethyl-6-thiabicyclo[3.2.1]octane": 'CC1(C)S[C@@]2([H])[C@H](C)CC[C@]1([H])C2',
  "(1S)-3-(1,10-Phenanthrolin-2-yl)-2'-phenyl-[1,1'-binaphthalen]-2-ol": 'OC1=C(C2=NC3=C4N=CC=CC4=CC=C3C=C2)C=C5C=CC=CC5=C1C6=C7C=CC=CC7=CC=C6C8=CC=CC=C8',
  "(1S,2R)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenyl-1-propanol": 'C[C@@H](N(CC1=CC=CC=C1)S(=O)(C(C(C)=CC(C)=C2)=C2C)=O)[C@H](C3=CC=CC=C3)O',
  "(1S,2R)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenylpropyl Propionate": 'CCC(O[C@@H](C1=CC=CC=C1)[C@H](N(CC2=CC=CC=C2)S(=O)(C(C(C)=CC(C)=C3)=C3C)=O)C)=O',
  "(1S,2S)-(-)-1,2-Diphenylethylenediamine": 'N[C@@H](C1=CC=CC=C1)[C@H](C2=CC=CC=C2)N',
  "(1S,2S)-(+)-1,2-Cyclohexanediamine": 'N[C@@H]1[C@@H](N)CCCC1',
  "(1S,2S)-(+)-N,N'-Dimethylcyclohexane-1,2-diamine": 'CN[C@@H]1[C@@H](NC)CCCC1',
  "(1S,2S)-1,2-Bis(2,4,6-trimethylphenyl)ethylenediamine": 'N[C@@H](C1=C(C)C=C(C)C=C1C)[C@H](C2=C(C)C=C(C)C=C2C)N',
  "(1S,2S)-1,2-Bis(2-hydroxyphenyl)ethylenediamine": 'N[C@@H](C1=CC=CC=C1O)[C@H](C2=CC=CC=C2O)N',
  "(1S,2S)-N,N'-Bis(2-acetyl-3-oxo-2-butenylidene)-1,2-dimesitylethylenediaminato Cobalt(II)": 'CC1=C(C(C)=CC(C)=C1)[C@H](/N=C/C=C(C(C)=O)\\C[O-])[C@H](C2=C(C)C=C(C)C=C2C)/N=C/C=C(C(C)=O)\\C[O-].[Co+2]',
  "(1S,2S)-N,N'-Bis[(R)-2-hydroxy-2'-phenyl-1,1'-binaphthyl-3-ylmethylene]-1,2-diphenylethylenediaminato Manganese(III) Acetate": 'CC([O-])=O.OC1=C(C([Mn+]2)=N[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N=C2C5=C(O)C(C6=C7C=CC=CC7=CC=C6C8=CC=CC=C8)=C9C=CC=CC9=C5)C=C%10C=CC=CC%10=C1C%11=C%12C=CC=CC%12=CC=C%11C%13=CC=CC=C%13',
  "(1S,2S)-N,N'-Bis[3-oxo-2-(2,4,6-trimethylbenzoyl)butylidene]-1,2-diphenylethylenediaminato Cobalt(II)": '[O-]/C(C)=C(C(C1=C(C)C=C(C)C=C1C)=O)\\C=N\\[C@H]([C@H](C2=CC=CC=C2)/N=C/C(C(C3=C(C)C=C(C)C=C3C)=O)=C(C)\\[O-])C4=CC=CC=C4.[Co+2]',
  "(1S,2S)-N,N'-Dihydroxy-N,N'-bis(diphenylacetyl)cyclohexane-1,2-diamine": 'O=C(N(O)[C@@H]1[C@@H](N(O)C(C(C2=CC=CC=C2)C3=CC=CC=C3)=O)CCCC1)C(C4=CC=CC=C4)C5=CC=CC=C5',
  "(1S,2S,4S,5S)-2,5-Bis(3,5-di-tert-butyl-2-hydroxybenzylideneamino)bicyclo[2.2.1]heptane": 'OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=N/[C@@H]2[C@]3([H])C[C@H](/N=C/C4=CC(C(C)(C)C)=CC(C(C)(C)C)=C4O)[C@](C3)([H])C2',
  "(1S,4S,5S)-4,7,7-Trimethyl-6-thiabicyclo[3.2.1]octane": 'CC1(C)S[C@]2([H])[C@@H](C)CC[C@@]1([H])C2',
  "(2,5-Dimethylphenyl)magnesium Bromide": 'Br[Mg]C1=CC(C)=CC=C1C',
  "(2-Bromo-4,5-dimethoxyphenoxy)trimethylsilane": 'C[Si](C)(OC1=CC(OC)=C(OC)C=C1Br)C',
  "(2-Bromoallyl)trimethylsilane": 'C[Si](C)(CC(Br)=C)C',
  "(2-Bromophenyl)(2,4,6-trimethylphenyl)iodonium triflate": 'CC1=C([I+]C2=CC=CC=C2Br)C(C)=CC(C)=C1.O=S([O-])(C(F)(F)F)=O',
  "(2-Bromophenyl)diphenylphosphine": 'BrC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3',
  "(2-Bromophenylethynyl)trimethylsilane": 'C[Si](C)(C#CC1=CC=CC=C1Br)C',
  "(2R,3R)-(-)-1,4-Bis(diphenylphosphino)-2,3-O-isopropylidene-2,3-butanediol": 'CC1(O[C@H]([C@@H](O1)CP(C2=CC=CC=C2)C3=CC=CC=C3)CP(C4=CC=CC=C4)C5=CC=CC=C5)C',
  "(2R,3R)-(-)-2,3-Bis(diphenylphosphino)bicyclo[2.2.1]hept-5-ene": 'C1(C2)[C@@H](P(C3=CC=CC=C3)C4=CC=CC=C4)[C@H](P(C5=CC=CC=C5)C6=CC=CC=C6)C2C=C1',
  "(2R,5R)-2,5-Diphenylpyrrolidine": '[C@@H]1(C2=CC=CC=C2)N[C@@H](C3=CC=CC=C3)CC1',
  "(2R,8aS)-(+)-(Camphorylsulfonyl)oxaziridine": 'O=S(N1OC1)(CC23C(CC(C(C)3C)CC2)=O)=O',
  "(2S)-N-[(1S)-1-(Hydroxydiphenylmethyl)-3-methylbutyl]-2-pyrrolidinecarboxamide": 'O=C([C@H]1NCCC1)N[C@H](C(C2=CC=CC=C2)(O)C3=CC=CC=C3)CC(C)C',
  "(2S,3aS,7aS)-Octahydro-1H-indole-2-carboxylic Acid": 'O=C([C@H]1N[C@@]2([H])CCCC[C@@]2([H])C1)O',
  "(2S,3R)-3,4-Dihydro-3-isopropyl-2-phenyl-2H-pyrimido[2,1-b]benzothiazole": 'CC([C@H]1[C@@H](C2=CC=CC=C2)N=C3SC4=CC=CC=C4N3C1)C',
  "(2S,3S)-(-)-Bis(diphenylphosphino)butane": 'C[C@H](P(C1=CC=CC=C1)C2=CC=CC=C2)[C@@H](P(C3=CC=CC=C3)C4=CC=CC=C4)C',
  "(2S,3S)-(+)-1,4-Bis(diphenylphosphino)-2,3-O-isopropylidene-2,3-butanediol": 'CC1(O[C@@H]([C@H](O1)CP(C2=CC=CC=C2)C3=CC=CC=C3)CP(C4=CC=CC=C4)C5=CC=CC=C5)C',
  "(2S,3S)-(+)-2,3-Bis(diphenylphosphino)bicyclo[2.2.1]hept-5-ene": 'C1(C2)[C@H](P(C3=CC=CC=C3)C4=CC=CC=C4)[C@@H](P(C5=CC=CC=C5)C6=CC=CC=C6)C2C=C1',
  "(2S,5S)-(-)-2-tert-Butyl-3-methyl-5-benzyl-4-imidazolidinone": 'O=C1N(C)[C@@H](C(C)(C)C)N[C@H]1CC2=CC=CC=C2',
  "(2S,5S)-2,5-Diphenylpyrrolidine": '[C@H]1(C2=CC=CC=C2)N[C@H](C3=CC=CC=C3)CC1',
  "(2S,8aR)-(-)-(Camphorylsulfonyl)oxaziridine": 'O=S(N1OC1)(CC23C(CC(C(C)3C)CC2)=O)=O',
  "(3,5-Dibromophenyl)triphenylsilane": 'BrC1=CC(Br)=CC([Si](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=C1',
  "(3-Bromopropoxy)(tert-butyl)dimethylsilane": 'C[Si](C(C)(C)C)(OCCCBr)C',
  "(3-Bromopropyl)trimethoxysilane": 'CO[Si](OC)(CCCBr)OC',
  "(3-Chloropropyl)tris(trimethylsilyloxy)silane": 'C[Si](O[Si](O[Si](C)(C)C)(CCCCl)O[Si](C)(C)C)(C)C',
  "(3-Mercaptopropyl)triethoxysilane": 'CCO[Si](OCC)(CCCS)OCC',
  "(3-Mercaptopropyl)trimethoxysilane": 'CO[Si](OC)(CCCS)OC',
  "(3-Phenyl-1H-inden-1-ylidene)bis(tricyclohexylphosphine)ruthenium(II) Dichloride": '[Ru+2]=C1C=C(C2=CC=CC=C2)C3=C1C=CC=C3.P(C4CCCCC4)(C5CCCCC5)C6CCCCC6.P(C7CCCCC7)(C8CCCCC8)C9CCCCC9.[Cl-].[Cl-]',
  "(4-(Isopropoxycarbonyl)phenyl)boronic acid": 'B(C1=CC=C(C=C1)C(=O)OC(C)C)(O)O',
  "(4-Dimethylaminophenyl)di-tert-butylphosphine": 'CC(P(C1=CC=C(N(C)C)C=C1)C(C)(C)C)(C)C',
  "(4-Nitrophenyl)(2,4,6-trimethylphenyl)iodonium triflate": 'CC1=C([I+]C2=CC=C([N+]([O-])=O)C=C2)C(C)=CC(C)=C1.O=S([O-])(C(F)(F)F)=O',
  "(4-Nitrophenyl)phenyliodonium triflate": 'O=[N+](C1=CC=C([I+]C2=CC=CC=C2)C=C1)[O-].O=S([O-])(C(F)(F)F)=O',
  "(4R)-(+)-4-Isopropyl-5,5-diphenyl-2-oxazolidinone": 'O=C1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@@H](C(C)C)N1',
  "(4R,5R)-1,3-Dimethyl-4,5-diphenyl-2-[(S)-1-benzyl-2-hydroxyethylimino]imidazolidine": 'OC[C@H](CC1=CC=CC=C1)N=C2N(C)[C@H](C3=CC=CC=C3)[C@@H](C4=CC=CC=C4)N2C',
  "(4S)-(-)-4-Isopropyl-5,5-diphenyl-2-oxazolidinone": 'O=C1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@H](C(C)C)N1',
  "(4S,4'S)-2,2'-(Pentane-3,3'-diyl)bis(4-benzyl-4,5-dihydrooxazole)": 'CCC(C1=N[C@H](CO1)CC2=CC=CC=C2)(C3=N[C@H](CO3)CC4=CC=CC=C4)CC',
  "(4S,5S)-1,3-Dimethyl-4,5-diphenyl-2-[(R)-1-benzyl-2-hydroxyethylimino]imidazolidine": 'OC[C@@H](CC1=CC=CC=C1)N=C2N(C)[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N2C',
  "(6-Chloro-1H-benzotriazol-1-yloxy)tripyrrolidinophosphonium Hexafluorophosphate": 'ClC1=CC=C2N=NN(O[P+](N3CCCC3)(N4CCCC4)N5CCCC5)C2=C1.F[P-](F)(F)(F)(F)F',
  "(7-Azabenzotriazol-1-yloxy)tris(dimethylamino)phosphonium Hexafluorophosphate": 'CN([P+](N(C)C)(ON1N=NC2=CC=CN=C21)N(C)C)C.F[P-](F)(F)(F)(F)F',
  "(Bromodifluoromethyl)trimethylsilane": 'C[Si](C)(C(F)(Br)F)C',
  "(Bromomethyl)trimethylsilane": 'C[Si](C)(CBr)C',
  "(Chloromethyl)isopropoxydimethylsilane": 'C[Si](OC(C)C)(CCl)C',
  "(Chloromethyl)trimethylsilane": 'C[Si](C)(CCl)C',
  "(Diacetoxyiodo)benzene": 'O=C(OI(C1=CC=CC=C1)OC(C)=O)C',
  "(Diethylamino)sulfur Trifluoride": 'CCN(S(F)(F)F)CC',
  "(Difluoromethyl)trimethylsilane": 'C[Si](C)(C(F)F)C',
  "(E)-Trimethyl(3,3,3-trifluoro-1-propenyl)silane": 'FC(F)(F)/C=C/[Si](C)(C)C',
  "(Hexamethylbenzene)ruthenium(II) Dichloride": 'CC1=C(C)C(C)=C(C)C(C)=C1C.[Ru+2].[Cl-].[Cl-]',
  "(Indenyl)titanium(IV) Trichloride": '[Ti+3]C1C=CC2=C1C=CC=C2.[Cl-].[Cl-].[Cl-]',
  "(Iodomethyl)trimethylsilane": 'C[Si](C)(CI)C',
  "(m-Terphenyl-5'-yl)trimethylsilane": 'C[Si](C)(C1=CC(C2=CC=CC=C2)=CC(C3=CC=CC=C3)=C1)C',
  "(Pentafluorophenyl)diphenylphosphine": 'FC1=C(F)C(F)=C(F)C(F)=C1P(C2=CC=CC=C2)C3=CC=CC=C3',
  "(Pentamethylcyclopentadienyl)rhodium(III) Dichloride": '[Rh+2]C1=C(C)C(C)=C(C)C1(C)C.[Cl-].[Cl-]',
  "(Pentamethylcyclopentadienyl)titanium(IV) Trichloride": '[Ti+3]C1=C(C)C(C)=C(C)C1(C)C.[Cl-].[Cl-].[Cl-]',
  "(Perfluorohexyl)phenyliodonium Trifluoromethanesulfonate": 'FC([I+]C1=CC=CC=C1)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F.O=S(C(F)(F)F)([O-])=O',
  "(Perfluoro-n-octyl)phenyliodonium Trifluoromethanesulfonate": 'FC([I+]C1=CC=CC=C1)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F.O=S(C(F)(F)F)([O-])=O',
  "(Perfluoropropyl)phenyliodonium Trifluoromethanesulfonate": 'FC([I+]C1=CC=CC=C1)(F)C(F)(F)C(F)(F)F.O=S(C(F)(F)F)([O-])=O',
  "(Phenylthio)trimethylsilane": 'C[Si](C)(SC1=CC=CC=C1)C',
  "(Phenylthiomethyl)trimethylsilane": 'C[Si](C)(CSC1=CC=CC=C1)C',
  "(Piperidinium-1-ylmethyl)trifluoroborate": 'F[B-2](F)(C[NH+]1CCCCC1)F',
  "(R)-(-)-1-(2-Pyrrolidinylmethyl)pyrrolidine": 'N1(C[C@@H]2NCCC2)CCCC1',
  "(R)-(-)-2-[Hydroxy(diphenyl)methyl]-1-methylpyrrolidine": 'CN1[C@@H](C(C2=CC=CC=C2)(O)C3=CC=CC=C3)CCC1',
  "(R)-(-)-4-Benzyl-3-propionyl-2-oxazolidinone": 'O=C1OC[C@@H](CC2=CC=CC=C2)N1C(CC)=O',
  "(R)-(-)-4-Isopropyl-3-propionyl-2-oxazolidinone": 'O=C1OC[C@@H](C(C)C)N1C(CC)=O',
  "(R)-(-)-4-Phenyl-2-oxazolidinone": 'O=C1OC[C@@H](C2=CC=CC=C2)N1',
  "(R)-(-)-p-Toluenesulfinamide": 'CC1=CC=C([S@](N)=O)C=C1',
  "(R)-(+)-2,2'-bis(diphenylphosphino)-1,1'-binaphthyl": 'C1(C2=C3C=CC=CC3=CC=C2P(C4=CC=CC=C4)C5=CC=CC=C5)=C6C=CC=CC6=CC=C1P(C7=CC=CC=C7)C8=CC=CC=C8',
  "(R)-(+)-2-Diphenylphosphino-2'-methoxy-1,1'-binaphthyl": 'COC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3P(C5=CC=CC=C5)C6=CC=CC=C6',
  "(R)-(+)-Indoline-2-carboxylic Acid": 'O=C([C@@H]1NC2=C(C=CC=C2)C1)O',
  "(R)-(+)-N,N-Dimethyl-1-ferrocenylethylamine": 'C[C@H]([C]1[CH][CH][CH][CH]1)N(C)C.[Fe].[CH]2[CH][CH][CH][CH]2',
  "(R)-(+)-tert-Butylsulfinamide": 'CC(C)([S@](N)=O)C',
  "(R)-(+)-α,α-Diphenyl-2-pyrrolidinemethanol": 'OC(C1=CC=CC=C1)(C2=CC=CC=C2)[C@@H]3NCCC3',
  "(R)-(+)-α,α-Diphenyl-2-pyrrolidinemethanol Trimethylsilyl Ether": 'C[Si](OC(C1=CC=CC=C1)(C2=CC=CC=C2)[C@@H]3NCCC3)(C)C',
  "(R)-2-(Methoxymethyl)pyrrolidine": 'COC[C@@H]1NCCC1',
  "(R)-3,3'-Bis[[[(1R,2R)-2-(isoindolin-2-yl)-1,2-diphenylethyl]imino]methyl]-1,1'-bi-2-naphthol": 'OC1=C(/C=N/[C@H](C2=CC=CC=C2)[C@H](N3CC4=C(C=CC=C4)C3)C5=CC=CC=C5)C=C6C=CC=CC6=C1C7=C8C=CC=CC8=CC(/C=N/[C@H](C9=CC=CC=C9)[C@H](N%10CC%11=C(C=CC=C%11)C%10)C%12=CC=CC=C%12)=C7O',
  "(R)-4-Benzyl-2-oxazolidinone": 'O=C1OC[C@@H](CC2=CC=CC=C2)N1',
  "(R)-4-Isopropyl-2-oxazolidinone": 'O=C1OC[C@@H](C(C)C)N1',
  "(R)-4-Phenylthiazolidine-2-thione": 'S=C1SC[C@@H](C2=CC=CC=C2)N1',
  "(R)-5,5-Diphenyl-2-methyl-3,4-propano-1,3,2-oxazaborolidine": 'CB1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@]4([H])N1CCC4',
  "(R)-N-(2-Benzoylphenyl)-1-benzylpyrrolidine-2-carboxamide": 'O=C([C@@H]1N(CC2=CC=CC=C2)CCC1)NC3=CC=CC=C3C(C4=CC=CC=C4)=O',
  "(R,R)-(-)-2,3-Bis(tert-butylmethylphosphino)quinoxaline": 'C[P@@](C1=NC2=CC=CC=C2N=C1[P@@](C(C)(C)C)C)C(C)(C)C',
  "(R,R)-(-)-2,3-Dimethoxy-1,4-bis(dimethylamino)butane": 'CN(C[C@@H](OC)[C@H](OC)CN(C)C)C',
  "(R,R)-(-)-N,N'-Bis(3,5-di-tert-butylsalicylidene)-1,2-cyclohexanediamine": 'OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=N/[C@H]2[C@H](/N=C/C3=CC(C(C)(C)C)=CC(C(C)(C)C)=C3O)CCCC2',
  "(R,R)-(-)-N,N'-Bis(3,5-di-tert-butylsalicylidene)-1,2-cyclohexanediaminomanganese(III) Chloride": 'OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=[N]([Mn+]/[N]2=C\\C3=CC(C(C)(C)C)=CC(C(C)(C)C)=C3O)\\[C@@]4([H])[C@@]2([H])CCCC4.[Cl-]',
  "(R,R)-(+)-2,2'-Isopropylidenebis(4-tert-butyl-2-oxazoline)": 'CC(C1=N[C@H](C(C)(C)C)CO1)(C2=N[C@H](C(C)(C)C)CO2)C',
  "(R,R)-1,2-Bis[(2-methoxyphenyl)phenylphosphino]ethane": 'COC1=CC=CC=C1[P@](CC[P@@](C2=CC=CC=C2OC)C3=CC=CC=C3)C4=CC=CC=C4',
  "(R,R)-2,2'-Isopropylidenebis(4-phenyl-2-oxazoline)": 'CC(C1=N[C@H](C2=CC=CC=C2)CO1)(C3=N[C@H](C4=CC=CC=C4)CO3)C',
  "(R,R)-2,6-Bis(4-isopropyl-2-oxazolin-2-yl)pyridine": 'CC([C@H]1N=C(C2=CC=CC(C3=N[C@H](C(C)C)CO3)=N2)OC1)C',
  "(R,R)-2,6-Bis(4-phenyl-2-oxazolin-2-yl)pyridine": 'C1(C2=N[C@H](C3=CC=CC=C3)CO2)=NC(C4=N[C@H](C5=CC=CC=C5)CO4)=CC=C1',
  "(R,R)-2-Iodo-1,3-bis[1-(mesitylcarbamoyl)ethoxy]benzene": 'C[C@H](C(NC1=C(C)C=C(C)C=C1C)=O)OC2=C(I)C(O[C@@H](C(NC3=C(C)C=C(C)C=C3C)=O)C)=CC=C2',
  "(R,R)-N-(2-Amino-1,2-diphenylethyl)-p-toluenesulfonamide": 'CC1=CC=C(S(=O)(N[C@H](C2=CC=CC=C2)[C@H](N)C3=CC=CC=C3)=O)C=C1',
  "(R,R,R)-(3,5-Dioxa-4-phosphacyclohepta[2,1-a:3,4-a']dinaphthalen-4-yl)bis(1-phenylethyl)amine": 'C[C@@H](N(P1OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)[C@@H](C6=CC=CC=C6)C)C7=CC=CC=C7',
  "(S)-(-)-1,1'-Binaphthyl-2,2'-diamine": 'NC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3N',
  "(S)-(-)-1-Methyl-2-(1-piperidinomethyl)pyrrolidine": 'CN1[C@H](CN2CCCCC2)CCC1',
  "(S)-(-)-2-(Diphenylmethyl)pyrrolidine": '[C@H]1(C(C2=CC=CC=C2)C3=CC=CC=C3)NCCC1',
  "(S)-(-)-2-Amino-2'-hydroxy-1,1'-binaphthyl": 'OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3N',
  "(S)-(-)-2-Aminomethyl-1-ethylpyrrolidine": 'CCN1[C@H](CN)CCC1',
  "(S)-(-)-3-(tert-Butoxycarbonyl)-4-formyl-2,2-dimethyl-1,3-oxazolidine": 'CC1(C)OC[C@@H](C=O)N1C(OC(C)(C)C)=O',
  "(S)-(-)-3,3'-Dibromo-5,5',6,6',7,7',8,8'-octahydro-1,1'-bi-2-naphthol": 'OC1=C(Br)C=C2CCCCC2=C1C3=C4CCCCC4=CC(Br)=C3O',
  "(S)-(-)-3-tert-Butoxycarbonyl-4-methoxycarbonyl-2,2-dimethyl-1,3-oxazolidine": 'CC1(C)OC[C@@H](C(OC)=O)N1C(OC(C)(C)C)=O',
  "(S)-(-)-5,5',6,6',7,7',8,8'-Octahydro-1,1'-bi-2-naphthol": 'OC1=CC=C2CCCCC2=C1C3=C4CCCCC4=CC=C3O',
  "(S)-(-)-BINAP": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=C(C(C=CC=C4)=C4C=C3)C(C(C=CC=C5)=C5C=C6)=C6P(C7=CC=CC=C7)C8=CC=CC=C8',
  "(S)-(-)-Indoline-2-carboxylic Acid": 'O=C([C@H]1NC2=C(C=CC=C2)C1)O',
  "(S)-(-)-tert-Butylsulfinamide": 'CC(C)([S@@](N)=O)C',
  "(S)-(-)-α,α-Diphenyl-2-pyrrolidinemethanol": 'OC(C1=CC=CC=C1)(C2=CC=CC=C2)[C@@H]3CCCN3',
  "(S)-(-)-α,α-Diphenyl-2-pyrrolidinemethanol Trimethylsilyl Ether": 'C[Si](OC(C1=CC=CC=C1)(C2=CC=CC=C2)[C@H]3NCCC3)(C)C',
  "(S)-(+)-(3,5-Dioxa-4-phosphacyclohepta[2,1-a": 'CN(P1OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)C',
  "3,4-a']dinaphthalen-4-yl)dimethylamine": 'CN(P1OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)C',
  "(S)-(+)-1-(2-Pyrrolidinylmethyl)pyrrolidine": 'N1(C[C@H]2NCCC2)CCCC1',
  "(S)-(+)-1,1'-Binaphthyl-2,2'-diyl Bis(trifluoromethanesulfonate)": 'O=S(C(F)(F)F)(OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3OS(C(F)(F)F)(=O)=O)=O',
  "(S)-(+)-1,1'-Binaphthyl-2,2'-diyl Hydrogen Phosphate": 'O=P1(OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)O',
  "(S)-(+)-2-(Anilinomethyl)pyrrolidine": '[C@H]1(CNC2=CC=CC=C2)NCCC1',
  "(S)-(+)-2-[Hydroxy(diphenyl)methyl]-1-methylpyrrolidine": 'CN1[C@H](C(C2=CC=CC=C2)(O)C3=CC=CC=C3)CCC1',
  "(S)-(+)-4-Benzyl-3-propionyl-2-oxazolidinone": 'O=C1OC[C@H](CC2=CC=CC=C2)N1C(CC)=O',
  "(S)-(+)-4-Isopropyl-3-propionyl-2-oxazolidinone": 'O=C1OC[C@H](C(C)C)N1C(CC)=O',
  "(S)-(+)-4-Phenyl-2-oxazolidinone": 'O=C1OC[C@H](C2=CC=CC=C2)N1',
  "(S)-(+)-6,6'-Dibromo-1,1'-bi-2-naphthol": 'OC1=CC=C2C=C(Br)C=CC2=C1C3=C4C=CC(Br)=CC4=CC=C3O',
  "(S)-(+)-p-Toluenesulfinamide": 'CC1=CC=C([S@@](N)=O)C=C1',
  "(S)-[4-(Pyridin-4-yl)-4,5-dihydro-3H-dinaphtho[2,1-c:1',2'-e]azepine-2,6-diyl]bis[bis[4-(tert-butyl)phenyl]methanol]": 'OC(C1=C2CN(C3=CC=NC=C3)CC4=C(C(C5=CC=C(C(C)(C)C)C=C5)(C6=CC=C(C(C)(C)C)C=C6)O)C=C7C=CC=CC7=C4C2=C8C=CC=CC8=C1)(C9=CC=C(C(C)(C)C)C=C9)C%10=CC=C(C(C)(C)C)C=C%10',
  "(S)-2-(Aminomethyl)pyrrolidine": 'NC[C@H]1NCCC1',
  "(S)-2-(Methoxymethyl)pyrrolidine": 'COC[C@H]1NCCC1',
  "(S)-2,2'-Dimethoxy-1,1'-binaphthyl": 'COC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3OC',
  "(S)-2,4-Diphenyl-4,5-dihydrooxazole": 'C1(C2=CC=CC=C2)=N[C@@H](C3=CC=CC=C3)CO1',
  "(S)-2-[(1-Methyl-2-pyrrolidinyl)methyl]isoindoline Dihydrochloride": 'CN1[C@H](CN2CC3=C(C=CC=C3)C2)CCC1.[H]Cl.[H]Cl',
  "(S)-3,3'-Dibromo-1,1'-bi-2-naphthol": 'OC1=C(Br)C=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC(Br)=C3O',
  "(S)-3,3'-Dibromo-2,2'-dimethoxy-1,1'-binaphthyl": 'COC1=C(Br)C=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC(Br)=C3OC',
  "(S)-3-[1-(4-Methoxybenzenesulfonyl)-(4S,5S)-4,5-diphenyl-4,5-dihydro-1H-imidazol-2-yl]-1,1'-binaphthalene-2,2'-diyl Hydrogen Phosphate": 'O=P1(OC2=C(C3=N[C@@H](C4=CC=CC=C4)[C@H](C5=CC=CC=C5)N3S(=O)(C6=CC=C(OC)C=C6)=O)C=C7C=CC=CC7=C2C8=C9C=CC=CC9=CC=C8O1)O',
  "(S)-4-(4-Aminobenzyl)-2-oxazolidinone": 'O=C1OC[C@H](CC2=CC=C(N)C=C2)N1',
  "(S)-4-Benzyl-2-oxazolidinone": 'O=C1OC[C@H](CC2=CC=CC=C2)N1',
  "(S)-4-Isopropyl-2-oxazolidinone": 'O=C1OC[C@H](C(C)C)N1',
  "(S)-4-Isopropyl-3-(1-naphthylmethyl)-2,5,5-triphenyl-1,3,2-oxazaborolidine": 'CC([C@@H]1N(CC2=C3C=CC=CC3=CC=C2)B(C4=CC=CC=C4)OC1(C5=CC=CC=C5)C6=CC=CC=C6)C',
  "(S)-4-Isopropylthiazolidine-2-thione": 'S=C1SC[C@H](C(C)C)N1',
  "(S)-4-Phenylthiazolidine-2-thione": 'S=C1SC[C@H](C2=CC=CC=C2)N1',
  "(S)-4-tert-Butyl-2-(2-pyridyl)oxazoline": 'CC([C@@H]1N=C(C2=NC=CC=C2)OC1)(C)C',
  "(S)-5-(Pyrrolidin-2-yl)-1H-tetrazole": 'C1([C@H]2NCCC2)=NN=NN1',
  "(S)-5,5-Diphenyl-2-methyl-3,4-propano-1,3,2-oxazaborolidine": 'CB1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@@]4([H])N1CCC4',
  "(S)-Benzyl-2-[4-(trifluoromethyl)phenyl]-6,7-dihydro-5H-pyrrolo[2,1-c][1,2,4]triazolium Tetrafluoroborate": 'FC(C1=CC=C([N+]2=C(CC3=CC=CC=C3)N4C(CCC4)=N2)C=C1)(F)F.F[B-](F)(F)F',
  "(S,S)-(-)-2,2'-Isopropylidenebis(4-tert-butyl-2-oxazoline)": 'CC(C1=N[C@@H](C(C)(C)C)CO1)(C2=N[C@@H](C(C)(C)C)CO2)C',
  "(S,S)-(+)-2,3-Bis(tert-butylmethylphosphino)quinoxaline": 'C[P@](C1=NC2=CC=CC=C2N=C1[P@](C(C)(C)C)C)C(C)(C)C',
  "(S,S)-(+)-2,3-Dimethoxy-1,4-bis(dimethylamino)butane": 'CN(C[C@H](OC)[C@@H](OC)CN(C)C)C',
  "(S,S)-1,2-Bis[(2-methoxyphenyl)phenylphosphino]ethane": 'COC1=CC=CC=C1[P@@](CC[P@](C2=CC=CC=C2OC)C3=CC=CC=C3)C4=CC=CC=C4',
  "(S,S)-1,2-Bis[(tert-butyl)methylphosphino]ethane Bis(borane)": 'C[P@@](CC[P@@](C(C)(C)C)C)C(C)(C)C.B.B',
  "(S,S)-2,2'-Isopropylidenebis(4-phenyl-2-oxazoline)": 'CC(C1=N[C@@H](C2=CC=CC=C2)CO1)(C3=N[C@@H](C4=CC=CC=C4)CO3)C',
  "(S,S)-2,6-Bis(4-isopropyl-2-oxazolin-2-yl)pyridine": 'CC([C@@H]1N=C(C2=CC=CC(C3=N[C@@H](C(C)C)CO3)=N2)OC1)C',
  "(S,S)-2,6-Bis(4-phenyl-2-oxazolin-2-yl)pyridine": 'C1(C2=N[C@@H](C3=CC=CC=C3)CO2)=NC(C4=N[C@@H](C5=CC=CC=C5)CO4)=CC=C1',
  "(S,S)-4,6-Bis(4-isopropyl-2-oxazolin-2-yl)-m-xylene": 'CC1=C(C2=N[C@@H](C(C)C)CO2)C=C(C3=N[C@@H](C(C)C)CO3)C(C)=C1',
  "(S,S)-N-(2-Amino-1,2-diphenylethyl)-p-toluenesulfonamide": 'CC1=CC=C(S(=O)(N[C@@H](C2=CC=CC=C2)[C@@H](N)C3=CC=CC=C3)=O)C=C1',
  "(S,S,S)-(3,5-Dioxa-4-phosphacyclohepta[2,1-a:3,4-a']dinaphthalen-4-yl)bis(1-phenylethyl)amine": 'C[C@H](N(P1OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)[C@H](C6=CC=CC=C6)C)C7=CC=CC=C7',
  "(tert-Butyldimethylsilyl)acetylene": 'C#C[Si](C)(C(C)(C)C)C',
  "(tert-Butyldimethylsilyloxy)malononitrile": 'N#CC(O[Si](C)(C(C)(C)C)C)C#N',
  "(Trifluoroacetyl)benzotriazole": 'O=C(C1=C2N=NNC2=CC=C1)C(F)(F)F',
  "(Trifluoromethyl)trimethylsilane": 'C[Si](C)(C(F)(F)F)C',
  "(Trifluoromethyl)tris(triphenylphosphine)copper(I)": '[Cu]C(F)(F)F.P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9',
  "(Triphenylphosphine)gold(I) Chloride": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.[Au+].[Cl-]',
  "[(4-Bromobenzyl)oxy](tert-butyl)dimethylsilane": 'C[Si](C(C)(C)C)(OCC1=CC=C(Br)C=C1)C',
  "[(4-Methoxyphenyl)ethynyl]trimethylsilane": 'C[Si](C)(C#CC1=CC=C(OC)C=C1)C',
  "[(R)-2,2'-Bis(diphenylphosphino)-1,1'-binaphthyl]ruthenium(II) Dichloride": 'C1(C2=C3C=CC=CC3=CC=C2P(C4=CC=CC=C4)C5=CC=CC=C5)=C6C=CC=CC6=CC=C1P(C7=CC=CC=C7)C8=CC=CC=C8.[Ru+2].[Cl-].[Cl-]',
  "[(S)-2,2'-Bis(diphenylphosphino)-1,1'-binaphthyl]ruthenium(II) Dichloride": 'C1(C2=C3C=CC=CC3=CC=C2P(C4=CC=CC=C4)C5=CC=CC=C5)=C6C=CC=CC6=CC=C1P(C7=CC=CC=C7)C8=CC=CC=C8.[Ru+2].[Cl-].[Cl-]',
  "[1,1′-Bis(diphenylphosphino)ferrocene]dichloropalladium(II)": 'C1=CC=C(C=C1)P(C2=CC=CC=C2)[C]3[CH][CH][CH][CH]3.C1=CC=C(C=C1)P(C2=CC=CC=C2)[C]3[CH][CH][CH][CH]3.Cl[Pd]Cl.[Fe]',
  "PdCl2(dppf)": 'C1=CC=C(C=C1)P(C2=CC=CC=C2)[C]3[CH][CH][CH][CH]3.C1=CC=C(C=C1)P(C2=CC=CC=C2)[C]3[CH][CH][CH][CH]3.Cl[Pd]Cl.[Fe]',
  "[1,2-Bis(diphenylphosphino)ethane]nickel(II) Dichloride": 'P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.[Ni+2].[Cl-].[Cl-]',
  "[1,2-Bis(diphenylphosphino)ethane]palladium(II) Dichloride": 'P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.[Pd+2].[Cl-].[Cl-]',
  "[1,3-Bis(2,6-diisopropylphenyl)imidazol-2-ylidene]triphenylphosphine Nickel(II) Dichloride": 'CC(C1=C(N(C=CN2C3=C(C(C)C)C=CC=C3C(C)C)C2=P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)C(C(C)C)=CC=C1)C.[Ni+2].[Cl-].[Cl-]',
  "[1,3-Bis(diphenylphosphino)propane]nickel(II) Dichloride": 'P(CCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.[Ni+2].[Cl-].[Cl-]',
  "[3-(6-Aminohexylamino)propyl]trimethoxysilane": 'CO[Si](OC)(CCCNCCCCCCN)OC',
  "[3-(Diethylamino)propyl]trimethoxysilane": 'CO[Si](OC)(CCCN(CC)CC)OC',
  "[3-(N,N-Dimethylamino)propyl]trimethoxysilane": 'CO[Si](OC)(CCCN(C)C)OC',
  "[3-(Trimethoxysilyl)propyl]succinic Anhydride": 'O=C(O1)C(CCC[Si](OC)(OC)OC)CC1=O',
  "[Bis(trifluoroacetoxy)iodo]benzene": 'O=C(OI(C1=CC=CC=C1)OC(C(F)(F)F)=O)C(F)(F)F',
  "[Bis(trifluoroacetoxy)iodo]pentafluorobenzene": 'FC1=C(I(OC(C(F)(F)F)=O)OC(C(F)(F)F)=O)C(F)=C(F)C(F)=C1F',
  "[Di-tert-butyl(chloro)phosphine]palladium(II) Dichloride": 'ClP(C(C)(C)C)C(C)(C)C.[Pd+2].[Cl-].[Cl-]',
  "[Hydroxy(tosyloxy)iodo]benzene": 'O=S(OI(C1=CC=CC=C1)O)(C2=CC=C(C)C=C2)=O',
  "1-(2,4,6-Triisopropylbenzenesulfonyl)-1,2,4-triazole": 'O=S(N1N=CN=C1)(C2=C(C(C)C)C=C(C(C)C)C=C2C(C)C)=O',
  "1-(2,4,6-Triisopropylbenzenesulfonyl)imidazole": 'O=S(N1C=CN=C1)(C2=C(C(C)C)C=C(C(C)C)C=C2C(C)C)=O',
  "1-(2-Hydroxyethyl)-3-methylimidazolium Chloride": 'C[N+]1=CN(CCO)C=C1.[Cl-]',
  "1-(2-Hydroxyethyl)-3-methylimidazolium Tetrafluoroborate": 'C[N+]1=CN(CCO)C=C1.F[B-](F)(F)F',
  "1-(2-Mesitylene)-1,3-butanedione": 'CC(CC(C1=C(C)C=C(C)C=C1C)=O)=O',
  "1-(2-Mesitylenesulfonyl)imidazole": 'O=S(N1C=CN=C1)(C(C(C)=CC(C)=C2)=C2C)=O',
  "1-(3-Dimethylaminopropyl)-3-ethylcarbodiimide": 'CCN=C=NCCCN(C)C',
  "EDC": 'CCN=C=NCCCN(C)C',
  "1-(3-Dimethylaminopropyl)-3-ethylcarbodiimide Hydrochloride": 'CCN=C=NCCCN(C)C.[H]Cl',
  "EDC HCl": 'CCN=C=NCCCN(C)C.[H]Cl',
  "1-(4-Bromophenyl)-1,3-butanedione": 'CC(CC(C1=CC=C(Br)C=C1)=O)=O',
  "1-(4-Chlorophenyl)-4,4,4-trifluoro-1,3-butanedione": 'O=C(C1=CC=C(Cl)C=C1)CC(C(F)(F)F)=O',
  "1-(4-Fluorophenyl)-1,3-butanedione": 'CC(CC(C1=CC=C(F)C=C1)=O)=O',
  "1-(4-tert-Butylphenyl)-3-(4-methoxyphenyl)-1,3-propanedione": 'O=C(C1=CC=C(C(C)(C)C)C=C1)CC(C2=CC=C(OC)C=C2)=O',
  "1-(Chloro-1-pyrrolidinylmethylene)pyrrolidinium Hexafluorophosphate": 'ClC(N1CCCC1)=[N+]2CCCC2.F[P-](F)(F)(F)(F)F',
  "1-(Chloro-1-pyrrolidinylmethylene)pyrrolidinium Tetrafluoroborate": 'ClC(N1CCCC1)=[N+]2CCCC2.F[B-](F)(F)F',
  "1-(Cyanomethyl)piperidinium Tetrafluoroborate": 'N#CC[NH+]1CCCCC1.F[B-](F)(F)F',
  "1-(Heptafluorobutyryl)imidazole": 'O=C(N1C=CN=C1)C(F)(F)C(F)(F)C(F)(F)F',
  "1-(p-Toluenesulfonyl)imidazole": 'O=S(N1C=CN=C1)(C2=CC=C(C)C=C2)=O',
  "1-(Trifluoroacetyl)-4-(dimethylamino)pyridinium Trifluoroacetate": 'CN(C1=CC=[N+](C(C(F)(F)F)=O)C=C1)C.O=C([O-])C(F)(F)F',
  "1-(Trifluoroacetyl)imidazole": 'O=C(N1C=CN=C1)C(F)(F)F',
  "1-(Trifluoroacetyl)piperidine": 'O=C(N1CCCCC1)C(F)(F)F',
  "1-(Trifluoromethanesulfonyl)-1H-benzotriazole": 'O=S(N1N=NC2=CC=CC=C21)(C(F)(F)F)=O',
  "1-(Trifluoromethanesulfonyl)imidazole": 'O=S(N1C=CN=C1)(C(F)(F)F)=O',
  "1-(Triisopropylsilyl)-1-propyne": 'CC#C[Si](C(C)C)(C(C)C)C(C)C',
  "1-(Trimethoxysilyl)naphthalene": 'CO[Si](C1=C2C=CC=CC2=CC=C1)(OC)OC',
  "1-(Trimethylsilyl)-1-pentyne": 'CCCC#C[Si](C)(C)C',
  "1-(Trimethylsilyl)-1-propyne": 'CC#C[Si](C)(C)C',
  "1,1'-(2,6-Pyridinediyl)bis(3-methylimidazolium) Dibromide": 'C[N+]1=CN(C2=NC(N3C=C[N+](C)=C3)=CC=C2)C=C1.[Br-].[Br-]',
  "1,1,1,3,3,3-Hexafluoroisopropyl Trifluoromethanesulfonate": 'O=S(C(F)(F)F)(OC(C(F)(F)F)C(F)(F)F)=O',
  "1,1,1,3,3-Pentamethyldisiloxane": 'C[SiH](C)O[Si](C)(C)C',
  "1,1,1,3,5,5,5-Heptamethyl-3-[(trimethylsilyl)oxy]trisiloxane": 'C[Si](O[Si](O[Si](C)(C)C)(C)O[Si](C)(C)C)(C)C',
  "1,1,1,3,5,5,5-Heptamethyltrisiloxane": 'C[Si](C)(C)O[SiH](C)O[Si](C)(C)C',
  "1,1,1,3,5,7,7,7-Octamethyltetrasiloxane": 'C[Si](C)(C)O[SiH](C)O[SiH](C)O[Si](C)(C)C',
  "1,1,1,5,5,5-Hexamethyl-3-[(trimethylsilyl)oxy]-3-vinyltrisiloxane": 'C=C[Si](O[Si](C)(C)C)(O[Si](C)(C)C)O[Si](C)(C)C',
  "1,1,1,5,5,5-Hexamethyl-3-phenyl-3-(trimethylsilyloxy)trisiloxane": 'C[Si](O[Si](O[Si](C)(C)C)(C1=CC=CC=C1)O[Si](C)(C)C)(C)C',
  "1,1,2,2-Tetrachloro-1,2-dimethyldisilane": 'C[Si](Cl)(Cl)[Si](Cl)(Cl)C',
  "1,1,2,2-Tetraphenyldisilane": '[SiH](C1=CC=CC=C1)(C2=CC=CC=C2)[SiH](C3=CC=CC=C3)C4=CC=CC=C4',
  "1,1,2,3,4,5-Hexaphenylsilole": 'C1(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C(C5=CC=CC=C5)[Si]1(C6=CC=CC=C6)C7=CC=CC=C7',
  "1,1,3,3,5,5,7,7,9,9,11,11,13,13-Tetradecamethylheptasiloxane": 'C[SiH](C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[SiH](C)C',
  "1,1,3,3,5,5,7,7,9,9,11,11-Dodecamethylhexasiloxane": 'C[SiH](C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[SiH](C)C',
  "1,1,3,3,5,5-Hexaethoxy-1,3,5-trisilacyclohexane": 'CCO[Si]1(OCC)C[Si](OCC)(OCC)C[Si](OCC)(OCC)C1',
  "1,1,3,3,5,5-Hexamethyltrisiloxane": 'C[SiH](C)O[Si](C)(C)O[SiH](C)C',
  "1,1,3,3-Tetramethyl-1,3-diphenyldisiloxane": 'C[Si](C1=CC=CC=C1)(C)O[Si](C)(C)C2=CC=CC=C2',
  "1,1,3,3-Tetramethyldisiloxane": 'C[SiH](C)O[SiH](C)C',
  "1,1,3,3-Tetramethylguanidine": 'N=C(N(C)C)N(C)C',
  "1,1,4,7,10,10-Hexamethyltriethylenetetramine": 'CN(CCN(C)C)CCN(C)CCN(C)C',
  "1,1,5,5-Tetrafluoro-2,4-pentanedione": 'O=C(CC(C(F)F)=O)C(F)F',
  "1,10-Phenanthroline Monohydrate": '[H]O[H].C12=CC=C3C=CC=NC3=C1N=CC=C2',
  "1,10-Phenanthroline-5,6-dione": 'O=C1C2=C(N=CC=C2)C3=C(C=CC=N3)C1=O',
  "1,1'-Bi-2-naphthol": 'OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3O',
  "1,1'-Carbonyldi(1,2,4-triazole)": 'O=C(N1N=CN=C1)N2N=CN=C2',
  "1,1'-Carbonyldiimidazole": 'O=C(N1C=CN=C1)N2C=CN=C2',
  "1,1'-Difluoro-2,2'-bipyridinium Bis(tetrafluoroborate)": 'F[N+]1=CC=CC=C1C2=CC=CC=[N+]2F.F[B-](F)(F)F.F[B-](F)(F)F',
  "1,1-Dimethyl-1,3-dihydrobenzo[c][1,2]oxasilole": 'C[Si]1(C)OCC2=CC=CC=C12',
  "1,1-Dimethylpropylmagnesium chloride": 'Cl[Mg]C(C)(C)CC',
  "1,1'-Oxalyldiimidazole": 'O=C(N1C=CN=C1)C(N2C=CN=C2)=O',
  "1,2,3,4,5-Pentamethylcyclopentadiene": 'CC1C(C)=C(C)C(C)=C1C',
  "1,2,3,4,5-Pentaphenyl-1,3-cyclopentadiene": 'C1(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C(C5=CC=CC=C5)C1C6=CC=CC=C6',
  "1,2,3,4-Tetrahydroquinoline": 'C12=C(NCCC2)C=CC=C1',
  "1,2,3,4-Tetraphenyl-1,3-cyclopentadiene": 'C1(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C(C5=CC=CC=C5)C1',
  "1,2,4-Triazole": 'N1N=CN=C1',
  "1,2-Benzenedisulfonic Imide": 'O=S(C1=CC=CC=C1S2(=O)=O)(N2)=O',
  "1,2-Bis(3-indenyl)ethane": 'C1(CCC2=CCC3=C2C=CC=C3)=CCC4=C1C=CC=C4',
  "1,2-Bis(4'-methyl-2,2'-bipyridin-4-yl)ethane": 'CC1=CC(C2=NC=CC(CCC3=CC(C4=NC=CC(C)=C4)=NC=C3)=C2)=NC=C1',
  "1,2-Bis(dimethylphosphino)ethane": 'CP(CCP(C)C)C',
  "1,2-Bis(diphenylphosphino)benzene": 'C1(P(C2=CC=CC=C2)C3=CC=CC=C3)=CC=CC=C1P(C4=CC=CC=C4)C5=CC=CC=C5',
  "1,2-Bis(diphenylphosphino)ethane": 'P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4',
  "1,2-Bis(phenylsulfinyl)ethane Palladium(II) Diacetate": 'O=S(CCS(C1=CC=CC=C1)=O)C2=CC=CC=C2.CC([O-])=O.CC([O-])=O.[Pd+2]',
  "1,2-Bis(tert-butylthio)ethane Borane": 'CC(SCCSC(C)(C)C)(C)C.B',
  "1,2-Bis(trichlorosilyl)ethane": 'Cl[Si](CC[Si](Cl)(Cl)Cl)(Cl)Cl',
  "1,2-Bis(triethoxysilyl)ethane": 'CCO[Si](CC[Si](OCC)(OCC)OCC)(OCC)OCC',
  "1,2-Bis(trimethylsilyl)benzene": 'C[Si](C1=CC=CC=C1[Si](C)(C)C)(C)C',
  "1,2-Bis[bis(pentafluorophenyl)phosphino]ethane": 'FC1=C(F)C(F)=C(F)C(F)=C1P(CCP(C2=C(F)C(F)=C(F)C(F)=C2F)C3=C(F)C(F)=C(F)C(F)=C3F)C4=C(F)C(F)=C(F)C(F)=C4F',
  "1,2-Dibromo-1,1,2,2-tetrachloroethane": 'ClC(Cl)(Br)C(Cl)(Br)Cl',
  "1,2-Dibromo-1,1,2,2-tetrafluoroethane": 'FC(F)(Br)C(F)(Br)F',
  "1,2-Dichlorotetramethyldisilane": 'Cl[Si](C)(C)[Si](C)(C)Cl',
  "1,2-Dimethyl-3-propylimidazolium Iodide": 'CCC[N+]1=C(C)N(C)C=C1.[I-]',
  "1,2-Phenylenediamine": 'NC1=CC=CC=C1N',
  "1,2-xylene": 'CC1=CC=CC=C1C',
  "1,3,5,7-Cyclooctatetraene": 'C1=C\\C=C/C=C\\C=C/1',
  "1,3,5-Cycloheptatriene": 'C1=CC=CC=CC1',
  "1,3,5-Trimethylbenzene": 'CC1=CC(C)=CC(C)=C1',
  "1,3,5-Tris(3,3,3-trifluoropropyl)-1,3,5-trimethylcyclotrisiloxane": 'C[Si]1(CCC(F)(F)F)O[Si](C)(CCC(F)(F)F)O[Si](C)(CCC(F)(F)F)O1',
  "1,3-Bis(2,4,6-trimethylphenyl)imidazolinium Chloride": 'CC1=C([N+]2=CN(C3=C(C)C=C(C)C=C3C)CC2)C(C)=CC(C)=C1.[Cl-]',
  "1,3-Bis(2,6-diisopropylphenyl)-1,3,2-diazaphospholidine 2-Oxide": 'CC(C1=C(N2P(N(C3=C(C(C)C)C=CC=C3C(C)C)CC2)=O)C(C(C)C)=CC=C1)C',
  "1,3-Bis(2,6-diisopropylphenyl)imidazol-2-ylidene": 'CC(C1=C(N2[C]N(C3=C(C(C)C)C=CC=C3C(C)C)C=C2)C(C(C)C)=CC=C1)C',
  "1,3-Bis(2,6-diisopropylphenyl)imidazolidin-2-ylidene": 'CC(C1=C(N2[C]N(C3=C(C(C)C)C=CC=C3C(C)C)CC2)C(C(C)C)=CC=C1)C',
  "1,3-Bis(2,6-diisopropylphenyl)imidazolinium Chloride": 'CC(C1=C([N+]2=CN(C3=C(C(C)C)C=CC=C3C(C)C)CC2)C(C(C)C)=CC=C1)C.[Cl-]',
  "1,3-Bis(2,6-diisopropylphenyl)imidazolium Chloride": 'CC(C1=C([N+]2=CN(C3=C(C(C)C)C=CC=C3C(C)C)C=C2)C(C(C)C)=CC=C1)C.[Cl-]',
  "1,3-Bis(4-hydroxybutyl)tetramethyldisiloxane": 'OCCCC[Si](C)(C)O[Si](C)(C)CCCCO',
  "1,3-Bis(4-methoxyphenyl)-1,3-propanedione": 'O=C(C1=CC=C(OC)C=C1)CC(C2=CC=C(OC)C=C2)=O',
  "1,3-Bis(chloromethyl)tetramethyldisiloxane": 'ClC[Si](C)(C)O[Si](C)(C)CCl',
  "1,3-Bis(diphenylphosphino)propane": 'P(CCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4',
  "1,3-Bis[(di-tert-butylphosphino)oxy]benzene": 'CC(P(OC1=CC(OP(C(C)(C)C)C(C)(C)C)=CC=C1)C(C)(C)C)(C)C',
  "1,3-Bis[(trimethylsilyl)ethynyl]benzene": 'C[Si](C#CC1=CC(C#C[Si](C)(C)C)=CC=C1)(C)C',
  "1,3-Di(1-adamantyl)imidazolinium Tetrafluoroborate": 'F[B-](F)(F)F.[N+]1(C23CC4CC(C3)CC(C4)C2)=CN(C56CC7CC(C6)CC(C7)C5)CC1',
  "1,3-Di(1-adamantyl)imidazolium Tetrafluoroborate": 'F[B-](F)(F)F.[N+]1(C23CC4CC(C3)CC(C4)C2)=CN(C56CC7CC(C6)CC(C7)C5)C=C1',
  "1,3-Di(2-pyridyl)-1,3-propanedione": 'O=C(C1=NC=CC=C1)CC(C2=NC=CC=C2)=O',
  "1,3-Dibromo-5,5-dimethylhydantoin": 'O=C(N1Br)N(Br)C(C)(C)C1=O',
  "1,3-Dicyclohexylimidazolium Tetrafluoroborate": 'F[B-](F)(F)F.[N+]1(C2CCCCC2)=CN(C3CCCCC3)C=C1',
  "1,3-Diiodo-5,5-dimethylhydantoin": 'O=C(N1I)N(I)C(C)(C)C1=O',
  "1,3-Diisopropylimidazolinium Tetrafluoroborate": 'CC([N+]1=CN(C(C)C)CC1)C.F[B-](F)(F)F',
  "1,3-Diisopropylimidazolium Tetrafluoroborate": 'CC([N+]1=CN(C(C)C)C=C1)C.F[B-](F)(F)F',
  "1,3-Dimesitylimidazol-2-ylidene": 'CC1=CC(C)=CC(C)=C1N2[C]N(C3=C(C)C=C(C)C=C3C)C=C2',
  "1,3-Dimesitylimidazolium Chloride": 'CC1=CC(C)=CC(C)=C1[N+]2=CN(C3=C(C)C=C(C)C=C3C)C=C2.[Cl-]',
  "1,3-Dimethoxy-1,1,3,3-tetraphenyldisiloxane": 'CO[Si](C1=CC=CC=C1)(C2=CC=CC=C2)O[Si](C3=CC=CC=C3)(OC)C4=CC=CC=C4',
  "1,3-Dimethylimidazolium Chloride": 'C[N+]1=CN(C)C=C1.[Cl-]',
  "1,3-Dimethylimidazolium Dimethyl Phosphate": 'C[N+]1=CN(C)C=C1.O=P(OC)([O-])OC',
  "1,3-Dimethylimidazolium Iodide": 'C[N+]1=CN(C)C=C1.[I-]',
  "1,3-Dimethylimidazolium Methyl Sulfate": 'C[N+]1=CN(C)C=C1.O=S(OC)([O-])=O',
  "1,3-Diphenyl-1,3-propanedione": 'O=C(C1=CC=CC=C1)CC(C2=CC=CC=C2)=O',
  "1,3-Di-tert-butylimidazol-2-ylidene": 'CC(N1[C]N(C(C)(C)C)C=C1)(C)C',
  "1,3-Di-tert-butylimidazolium Tetrafluoroborate": 'CC([N+]1=CN(C(C)(C)C)C=C1)(C)C.F[B-](F)(F)F',
  "1,3-Divinyltetramethyldisiloxane": 'C=C[Si](C)(C)O[Si](C)(C)C=C',
  "1,3-xylene": 'CC1=CC=CC(C)=C1',
  "1,4,7,10,13,16-Hexaazacyclooctadecane": 'N1CCNCCNCCNCCNCCNCC1',
  "1,4,7,10,13,16-Hexaazacyclooctadecane Hexahydrochloride": '[H]Cl.[H]Cl.[H]Cl.[H]Cl.[H]Cl.[H]Cl.N1CCNCCNCCNCCNCCNCC1',
  "1,4,7,10-Tetraazacyclododecane": 'N1CCNCCNCCNCC1',
  "1,4,7,10-Tetraazacyclododecane Tetrahydrochloride": '[H]Cl.[H]Cl.[H]Cl.[H]Cl.N1CCNCCNCCNCC1',
  "1,4,7,10-Tetraazacyclododecane-1,4,7,10-tetraacetic Acid": 'O=C(O)CN1CCN(CC(O)=O)CCN(CC(O)=O)CCN(CC(O)=O)CC1',
  "1,4,7,10-Tetrabenzyl-1,4,7,10-tetraazacyclododecane": 'N1(CC2=CC=CC=C2)CCN(CC3=CC=CC=C3)CCN(CC4=CC=CC=C4)CCN(CC5=CC=CC=C5)CC1',
  "1,4,7-Triazacyclononane": 'N1CCNCCNCC1',
  "1,4,7-Trimethyl-1,4,7-triazacyclononane (stabilized with NaHCO3)": 'CN1CCN(C)CCN(C)CC1',
  "1,4,8,11-Tetramethyl-1,4,8,11-tetraazacyclotetradecane": 'CN1CCN(C)CCCN(C)CCN(C)CCC1',
  "1,4-Bis(dimethylsilyl)benzene": 'C[SiH](C1=CC=C([SiH](C)C)C=C1)C',
  "1,4-Bis(diphenylphosphino)butane": 'P(CCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4',
  "1,4-Bis(trimethylsilyl)-1,3-butadiyne": 'C[Si](C#CC#C[Si](C)(C)C)(C)C',
  "1,4-Cyclohexadiene": 'C1=CCC=CC1',
  "1,4-Cyclohexadiene (stabilized with BHT)": 'C1=CCC=CC1',
  "1,4-Di[[2,2':6',2''-terpyridin]-4'-yl]benzene": 'C1(C2=CC(C3=NC=CC=C3)=NC(C4=NC=CC=C4)=C2)=CC=C(C5=CC(C6=NC=CC=C6)=NC(C7=NC=CC=C7)=C5)C=C1',
  "1,4-Diazabicyclo[2.2.2]octane": 'N1(CC2)CCN2CC1',
  "1,4-Dimethyl-1,2,4-triazolium Iodide": 'CN1C=N[N+](C)=C1.[I-]',
  "1,4-dioxane": 'C1COCCO1',
  "1,4-xylene": 'CC1=CC=C(C)C=C1',
  "1,5,7-Triazabicyclo[4.4.0]dec-5-ene": 'N12CCCN=C1NCCC2',
  "1,5,9-Triazacyclododecane": 'N1CCCNCCCNCCC1',
  "1,5-Bis(diphenylphosphino)pentane": 'P(CCCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4',
  "1,5-Cyclooctadiene": 'C1=C\\CC/C=C\\CC/1',
  "1,5-Cyclooctadiene [stabilized with Octadecyl 3-(3',5'-Di-tert-butyl-4'-hydroxyphenyl)propionate]": 'C1=C\\CC/C=C\\CC/1',
  "1,5-Diazabicyclo[4.3.0]-5-nonene": 'N12CCCN=C1CCC2',
  "1,5-Diazabicyclo[4.3.0]non-5-ene": 'N12CCCN=C1CCC2',
  "1,5-Dichloro-1,1,3,3,5,5-hexamethyltrisiloxane": 'C[Si](C)(Cl)O[Si](C)(C)O[Si](C)(Cl)C',
  "1,5-Dimethyl-2,4-bis[2-(trimethylsilyl)ethynyl]benzene": 'C[Si](C#CC1=C(C)C=C(C)C(C#C[Si](C)(C)C)=C1)(C)C',
  "1,5-Hexadiene": 'C=CCCC=C',
  "1,6-Bis(diphenylphosphino)hexane": 'P(CCCCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4',
  "1,7-Dichloro-1,1,3,3,5,5,7,7-octamethyltetrasiloxane": 'C[Si](C)(Cl)O[Si](C)(C)O[Si](C)(C)O[Si](C)(Cl)C',
  "1,8-Bis(dimethylamino)naphthalene": 'CN(C1=CC=CC2=CC=CC(N(C)C)=C12)C',
  "1,8-Bis(diphenylphosphino)naphthalene": 'C1(P(C2=CC=CC=C2)C3=CC=CC=C3)=C4C(P(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC4=CC=C1',
  "1,8-Diazabicyclo[5.4.0]-7-undecene": 'N12CCCCCC1=NCCC2',
  "1,8-Diazabicyclo[5.4.0]-7-undecene Hydrogen Tribromide": 'N12CCCCCC1=NCCC2.[H+].[Br-].[Br-].[Br-]',
  "1,8-Diazabicyclo[5.4.0]undec-7-ene": 'N12CCCCCC1=NCCC2',
  "1-[2-(Di-tert-butylphosphino)phenyl]-3,5-diphenyl-1H-pyrazole": 'CC(P(C1=CC=CC=C1N2N=C(C3=CC=CC=C3)C=C2C4=CC=CC=C4)C(C)(C)C)(C)C',
  "1-[3-(Trimethoxysilyl)propyl]urea": 'O=C(N)NCCC[Si](OC)(OC)OC',
  "1-[Bis(trifluoromethanesulfonyl)methyl]-2,3,4,5,6-pentafluorobenzene": 'FC1=C(C(S(=O)(C(F)(F)F)=O)S(=O)(C(F)(F)F)=O)C(F)=C(F)C(F)=C1F',
  "10-Methyl-9-phenylacridinium Perchlorate": 'C[N+]1=C2C=CC=CC2=C(C3=CC=CC=C3)C4=C1C=CC=C4.O=Cl(=O)([O-])=O',
  "10-Methylacridinium Perchlorate": 'C[N+]1=C2C=CC=CC2=CC3=C1C=CC=C3.O=Cl(=O)([O-])=O',
  "1-Acetoxy-5-bromo-1,2-benziodoxol-3(1H)-one": 'O=C1OI(OC(C)=O)C2=CC=C(Br)C=C12',
  "1-Allyl-3-methylimidazolium Chloride": 'C[N+]1=CN(CC=C)C=C1.[Cl-]',
  "1-Bromo-3-(trimethylsilyl)benzene": 'C[Si](C1=CC(Br)=CC=C1)(C)C',
  "1-Bromo-4-(trimethylsilyl)benzene": 'C[Si](C1=CC=C(Br)C=C1)(C)C',
  "1-Butyl-1-methylpiperidinium Bis(trifluoromethanesulfonyl)imide": 'C[N+]1(CCCC)CCCCC1.O=S([N-]S(=O)(C(F)(F)F)=O)(C(F)(F)F)=O',
  "1-Butyl-1-methylpiperidinium Bromide": 'C[N+]1(CCCC)CCCCC1.[Br-]',
  "1-Butyl-1-methylpyrrolidinium Bis(trifluoromethanesulfonyl)imide": 'C[N+]1(CCCC)CCCC1.O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O',
  "1-Butyl-1-methylpyrrolidinium Bromide": 'C[N+]1(CCCC)CCCC1.[Br-]',
  "1-Butyl-1-methylpyrrolidinium Chloride": 'C[N+]1(CCCC)CCCC1.[Cl-]',
  "1-Butyl-1-methylpyrrolidinium Dicyanamide": 'C[N+]1(CCCC)CCCC1.N#C[N-]C#N',
  "1-Butyl-1-methylpyrrolidinium Trifluoromethanesulfonate": 'C[N+]1(CCCC)CCCC1.O=S(C(F)(F)F)([O-])=O',
  "1-Butyl-2,3-dimethylimidazolium Bis(trifluoromethanesulfonyl)imide": 'O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O.CC1=[N+](C)C=CN1CCCC',
  "1-Butyl-2,3-dimethylimidazolium Chloride": 'C[N+]1=C(C)N(CCCC)C=C1.[Cl-]',
  "1-Butyl-2,3-dimethylimidazolium Hexafluorophosphate": 'C[N+]1=C(C)N(CCCC)C=C1.F[P-](F)(F)(F)(F)F',
  "1-Butyl-2,3-dimethylimidazolium Tetrafluoroborate": 'C[N+]1=C(C)N(CCCC)C=C1.F[B-](F)(F)F',
  "1-Butyl-2,3-dimethylimidazolium Trifluoromethanesulfonate": 'C[N+]1=C(C)N(CCCC)C=C1.O=S(C(F)(F)F)([O-])=O',
  "1-Butyl-3-methylimidazolium Bis(trifluoromethanesulfonyl)imide": 'C[N+]1=CN(CCCC)C=C1.O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O',
  "1-Butyl-3-methylimidazolium Bromide": 'C[N+]1=CN(CCCC)C=C1.[Br-]',
  "1-Butyl-3-methylimidazolium Chloride": 'C[N+]1=CN(CCCC)C=C1.[Cl-]',
  "1-Butyl-3-methylimidazolium Dicyanamide": 'C[N+]1=CN(CCCC)C=C1.N#C[N-]C#N',
  "1-Butyl-3-methylimidazolium Hexafluorophosphate": 'C[N+]1=CN(CCCC)C=C1.F[P-](F)(F)(F)(F)F',
  "1-Butyl-3-methylimidazolium Iodide": 'C[N+]1=CN(CCCC)C=C1.[I-]',
  "1-Butyl-3-methylimidazolium Methanesulfonate": 'C[N+]1=CN(CCCC)C=C1.CS(=O)([O-])=O',
  "1-Butyl-3-methylimidazolium Methyl Sulfate": 'C[N+]1=CN(CCCC)C=C1.O=S(OC)([O-])=O',
  "1-Butyl-3-methylimidazolium Tetrachloroferrate": 'C[N+]1=CN(CCCC)C=C1.Cl[Fe-](Cl)(Cl)Cl',
  "1-Butyl-3-methylimidazolium Tetrafluoroborate": 'C[N+]1=CN(CCCC)C=C1.F[B-](F)(F)F',
  "1-Butyl-3-methylimidazolium Thiocyanate": 'C[N+]1=CN(CCCC)C=C1.[S-]C#N',
  "1-Butyl-3-methylimidazolium Tribromide": 'C[N+]1=CN(CCCC)C=C1.[Br-].[Br-].[Br-]',
  "1-Butyl-3-methylimidazolium Tricyanomethanide": 'C[N+]1=CN(CCCC)C=C1.N#C[C-](C#N)C#N',
  "1-Butyl-3-methylimidazolium Trifluoro(trifluoromethyl)borate": 'C[N+]1=CN(CCCC)C=C1.FC([B-](F)(F)F)(F)F',
  "1-Butyl-3-methylimidazolium Trifluoroacetate": 'C[N+]1=CN(CCCC)C=C1.O=C([O-])C(F)(F)F',
  "1-Butyl-3-methylimidazolium Trifluoromethanesulfonate": 'C[N+]1=CN(CCCC)C=C1.O=S(C(F)(F)F)([O-])=O',
  "1-Butyl-3-methylpyridinium Bromide": 'CC1=C[N+](CCCC)=CC=C1.[Br-]',
  "1-Butyl-3-methylpyridinium Chloride": 'CC1=C[N+](CCCC)=CC=C1.[Cl-]',
  "1-Butyl-4-methylpyridinium Bromide": 'CC1=CC=[N+](CCCC)C=C1.[Br-]',
  "1-Butyl-4-methylpyridinium Chloride": 'CC1=CC=[N+](CCCC)C=C1.[Cl-]',
  "1-Butyl-4-methylpyridinium Hexafluorophosphate": 'CC1=CC=[N+](CCCC)C=C1.F[P-](F)(F)(F)(F)F',
  "1-Butylpyridinium Bis(trifluoromethanesulfonyl)imide": 'O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O.CCCC[N+]1=CC=CC=C1',
  "1-Butylpyridinium Bromide": 'CCCC[N+]1=CC=CC=C1.[Br-]',
  "1-Butylpyridinium Chloride": 'CCCC[N+]1=CC=CC=C1.[Cl-]',
  "1-Butylpyridinium Hexafluorophosphate": 'CCCC[N+]1=CC=CC=C1.F[P-](F)(F)(F)(F)F',
  "1-Butylpyridinium Tetrafluoroborate": 'CCCC[N+]1=CC=CC=C1.F[B-](F)(F)F',
  "1-Chloro-2-iodoethane": 'ICCCl',
  "1-Cyclohexyl-3-(2-morpholinoethyl)carbodiimide Metho-p-toluenesulfonate": 'C[N+]1(CCN=C=NC2CCCCC2)CCOCC1.O=S(C3=CC=C(C)C=C3)([O-])=O',
  "1-Cyclopentenylboronic Acid": 'OB(C1=CCCC1)O',
  "1-Decyl-3-methylimidazolium Bis(trifluoromethanesulfonyl)imide": 'C[N+]1=CN(CCCCCCCCCC)C=C1.O=S(C(F)(F)F)([N-]S(C(F)(F)F)(=O)=O)=O',
  "1-Decyl-3-methylimidazolium Bromide": 'C[N+]1=CN(CCCCCCCCCC)C=C1.[Br-]',
  "1-Decyl-3-methylimidazolium Chloride": 'C[N+]1=CN(CCCCCCCCCC)C=C1.[Cl-]',
  "1-Ethoxycarbonyl-2-ethoxy-1,2-dihydroquinoline": 'CCOC1N(C(OCC)=O)C2=C(C=CC=C2)C=C1',
  "1-Ethyl-1-methylpyrrolidinium Bromide": 'C[N+]1(CC)CCCC1.[Br-]',
  "1-Ethyl-1-methylpyrrolidinium Tetrafluoroborate": 'C[N+]1(CC)CCCC1.F[B-](F)(F)F',
  "1-Ethyl-2,3-dimethylimidazolium Bis(trifluoromethanesulfonyl)imide": 'O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O.CC1=[N+](C)C=CN1CC',
  "1-Ethyl-2-methylpyridinium Bromide": 'CC1=CC=CC=[N+]1CC.[Br-]',
  "1-Ethyl-3-(hydroxymethyl)pyridinium Ethyl Sulfate": 'OCC1=C[N+](CC)=CC=C1.O=S(OCC)([O-])=O',
  "1-Ethyl-3-methylimidazolium Acetate": 'C[N+]1=CN(CC)C=C1.CC([O-])=O',
  "1-Ethyl-3-methylimidazolium Bis(trifluoromethanesulfonyl)imide": 'C[N+]1=CN(CC)C=C1.O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O',
  "1-Ethyl-3-methylimidazolium Bromide": 'C[N+]1=CN(CC)C=C1.[Br-]',
  "1-Ethyl-3-methylimidazolium Chloride": 'C[N+]1=CN(CC)C=C1.[Cl-]',
  "1-Ethyl-3-methylimidazolium Dicyanamide": 'CCN1C=[N+](C)C=C1.N#C[N-]C#N',
  "1-Ethyl-3-methylimidazolium Diethyl Phosphate": 'C[N+]1=CN(CC)C=C1.O=P(OCC)([O-])OCC',
  "1-Ethyl-3-methylimidazolium Ethyl Sulfate": 'C[N+]1=CN(CC)C=C1.O=S(OCC)([O-])=O',
  "1-Ethyl-3-methylimidazolium Hexafluorophosphate": 'C[N+]1=CN(CC)C=C1.F[P-](F)(F)(F)(F)F',
  "1-Ethyl-3-methylimidazolium Hydrogen Sulfate": 'C[N+]1=CN(CC)C=C1.O=S(O)([O-])=O',
  "1-Ethyl-3-methylimidazolium Iodide": 'C[N+]1=CN(CC)C=C1.[I-]',
  "1-Ethyl-3-methylimidazolium Methanesulfonate": 'C[N+]1=CN(CC)C=C1.CS(=O)([O-])=O',
  "1-Ethyl-3-methylimidazolium Methyl Sulfate": 'C[N+]1=CN(CC)C=C1.O=S(OC)([O-])=O',
  "1-Ethyl-3-methylimidazolium Nitrate": 'C[N+]1=CN(CC)C=C1.[O-][N+]([O-])=O',
  "1-Ethyl-3-methylimidazolium Tetrachloroferrate": 'C[N+]1=CN(CC)C=C1.Cl[Fe-](Cl)(Cl)Cl',
  "1-Ethyl-3-methylimidazolium Tetrafluoroborate": 'C[N+]1=CN(CC)C=C1.F[B-](F)(F)F',
  "1-Ethyl-3-methylimidazolium Thiocyanate": 'C[N+]1=CN(CC)C=C1.[S-]C#N',
  "1-Ethyl-3-methylimidazolium Tricyanomethanide": 'C[N+]1=CN(CC)C=C1.N#C[C-](C#N)C#N',
  "1-Ethyl-3-methylimidazolium Trifluoro(trifluoromethyl)borate": 'C[N+]1=CN(CC)C=C1.FC([B-](F)(F)F)(F)F',
  "1-Ethyl-3-methylimidazolium Trifluoroacetate": 'C[N+]1=CN(CC)C=C1.O=C([O-])C(F)(F)F',
  "1-Ethyl-3-methylimidazolium Trifluoromethanesulfonate": 'C[N+]1=CN(CC)C=C1.O=S(C(F)(F)F)([O-])=O',
  "1-Ethyl-3-methylimidazolium p-Toluenesulfonate": 'C[N+]1=CN(CC)C=C1.CC2=CC=C(S(=O)([O-])=O)C=C2',
  "1-Ethyl-3-methylpyridinium Bis(trifluoromethanesulfonyl)imide": 'CC1=CC=C[N+](CC)=C1.O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O',
  "1-Ethyl-3-methylpyridinium Ethyl Sulfate": 'CC1=C[N+](CC)=CC=C1.O=S(OCC)([O-])=O',
  "1-Ethyl-4-methylpyridinium Bromide": 'CC1=CC=[N+](CC)C=C1.[Br-]',
  "1-Ethylpyridinium Bromide": 'CC[N+]1=CC=CC=C1.[Br-]',
  "1-Ethylpyridinium Chloride": 'CC[N+]1=CC=CC=C1.[Cl-]',
  "1-Fluoro-2,4,6-trimethylpyridinium Tetrafluoroborate": 'CC1=[N+](F)C(C)=CC(C)=C1.F[B-](F)(F)F',
  "1-Fluoro-2,4,6-trimethylpyridinium Trifluoromethanesulfonate": 'CC1=[N+](F)C(C)=CC(C)=C1.O=S(C(F)(F)F)([O-])=O',
  "1-Fluoro-2,6-dichloropyridinium Tetrafluoroborate": 'ClC1=[N+](F)C(Cl)=CC=C1.F[B-](F)(F)F',
  "1-Fluoro-3,3-dimethyl-1,2-benziodoxole": 'CC1(C)OI(F)C2=CC=CC=C12',
  "1-Fluoropyridinium Tetrafluoroborate": 'F[N+]1=CC=CC=C1.F[B-](F)(F)F',
  "1-Fluoropyridinium Trifluoromethanesulfonate": 'F[N+]1=CC=CC=C1.O=S(C(F)(F)F)([O-])=O',
  "1H-Benzotriazol-1-yloxytripyrrolidinophosphonium Hexafluorophosphate": 'F[P-](F)(F)(F)(F)F.N1([P+](N2CCCC2)(ON3N=NC4=CC=CC=C43)N5CCCC5)CCCC1',
  "1H-Benzotriazol-1-yloxytris(dimethylamino)phosphonium Hexafluorophosphate": 'CN([P+](N(C)C)(ON1N=NC2=CC=CC=C21)N(C)C)C.F[P-](F)(F)(F)(F)F',
  "1H-Cyclopenta[l]phenanthrene": 'C12=CC=CC=C1C(CC=C3)=C3C4=C2C=CC=C4',
  "1-Hexyl-2,3-dimethylimidazolium Iodide": 'C[N+]1=C(C)N(CCCCCC)C=C1.[I-]',
  "1-Hexyl-3-methylimidazolium Bis(trifluoromethanesulfonyl)imide": 'C[N+]1=CN(CCCCCC)C=C1.O=S([N-]S(=O)(C(F)(F)F)=O)(C(F)(F)F)=O',
  "1-Hexyl-3-methylimidazolium Bromide": 'C[N+]1=CN(CCCCCC)C=C1.[Br-]',
  "1-Hexyl-3-methylimidazolium Chloride": 'C[N+]1=CN(CCCCCC)C=C1.[Cl-]',
  "1-Hexyl-3-methylimidazolium Hexafluorophosphate": 'C[N+]1=CN(CCCCCC)C=C1.F[P-](F)(F)(F)(F)F',
  "1-Hexyl-3-methylimidazolium Iodide": 'C[N+]1=CN(CCCCCC)C=C1.[I-]',
  "1-Hexyl-3-methylimidazolium Tetrafluoroborate": 'C[N+]1=CN(CCCCCC)C=C1.F[B-](F)(F)F',
  "1-Hexyl-3-methylimidazolium Trifluoromethanesulfonate": 'C[N+]1=CN(CCCCCC)C=C1.O=S(C(F)(F)F)([O-])=O',
  "1-Hexylpyridinium Hexafluorophosphate": 'CCCCCC[N+]1=CC=CC=C1.F[P-](F)(F)(F)(F)F',
  "1-Hydroxy-6-(trifluoromethyl)benzotriazole": 'FC(C1=CC=C2N=NN(O)C2=C1)(F)F',
  "1-Methoxy-3-(trimethylsilyloxy)-1,3-butadiene": 'C=C(O[Si](C)(C)C)/C=C/OC',
  "1-Methyl-1-[4-(diphenylphosphino)benzyl]pyrrolidinium Bromide": 'C[N+]1(CC2=CC=C(P(C3=CC=CC=C3)C4=CC=CC=C4)C=C2)CCCC1.[Br-]',
  "1-Methyl-1-propylpiperidinium Bis(fluorosulfonyl)imide": 'O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O.C[N+]1(CCC)CCCCC1',
  "1-Methyl-1-propylpiperidinium Bromide": 'CCC[N+]1(C)CCCCC1.[Br-]',
  "1-Methyl-1-propylpyrrolidinium Bis(trifluoromethanesulfonyl)imide": 'O=S(NS(C(F)(F)F)(=O)=O)(C(F)(F)F)=O.C[N+]1(CCC)CCCC1',
  "1-Methyl-2-pyrrolidinone": 'O=C1CCCN1C',
  "1-Methyl-3-n-octylimidazolium Bromide": 'CCCCCCCC[N+]1=CN(C)C=C1.[Br-]',
  "1-Methyl-3-n-octylimidazolium Chloride": 'CCCCCCCC[N+]1=CN(C)C=C1.[Cl-]',
  "1-Methyl-3-n-octylimidazolium Hexafluorophosphate": 'CCCCCCCC[N+]1=CN(C)C=C1.F[P-](F)(F)(F)(F)F',
  "1-Methyl-3-n-octylimidazolium Tetrafluoroborate": 'CCCCCCCC[N+]1=CN(C)C=C1.F[B-](F)(F)F',
  "1-Methyl-3-n-octylimidazolium Trifluoromethanesulfonate": 'CCCCCCCC[N+]1=CN(C)C=C1.O=S(C(F)(F)F)([O-])=O',
  "1-Methyl-3-pentylimidazolium Bromide": 'CCCCC[N+]1=CN(C)C=C1.[Br-]',
  "1-Methyl-3-pentylimidazolium Tetrafluoroborate": 'CCCCC[N+]1=CN(C)C=C1.F[B-](F)(F)F',
  "1-Methyl-3-propylimidazolium Bromide": 'CCC[N+]1=CN(C)C=C1.[Br-]',
  "1-Methyl-3-propylimidazolium Chloride": 'CCC[N+]1=CN(C)C=C1.[Cl-]',
  "1-Methyl-3-propylimidazolium Iodide": 'CCC[N+]1=CN(C)C=C1.[I-]',
  "1-Methyl-3-propylimidazolium Tetrafluoroborate": 'CCC[N+]1=CN(C)C=C1.F[B-](F)(F)F',
  "1-Phenyl-1,3-butanedione": 'CC(CC(C1=CC=CC=C1)=O)=O',
  "1-Phenyl-2-(trimethylsilyl)acetylene": 'C[Si](C#CC1=CC=CC=C1)(C)C',
  "1-Propylpyridinium Chloride": 'CCC[N+]1=CC=CC=C1.[Cl-]',
  "1-Trifluoromethyl-1,2-benziodoxol-3(1H)-one": 'O=C1OI(C(F)(F)F)C2=CC=CC=C12',
  "1-Trifluoromethyl-3,3-dimethyl-1,2-benziodoxole": 'CC1(C)OI(C(F)(F)F)C2=CC=CC=C12',
  "2-(1H-1,2,4-Triazol-3-yl)pyridine": 'C1(C2=NNC=N2)=NC=CC=C1',
  "2-(2-Bromophenyl)-2,3-dihydro-1H-naphtho[1,8-de][1,3,2]diazaborine": 'BrC(C=CC=C1)=C1B(N2)NC3=CC=CC4=C3C2=CC=C4',
  "2-(2-Chloro-6-fluorophenyl)ethylamine hydrochloride": 'NCCC1=C(F)C=CC=C1Cl.[H]Cl',
  "2-(2-Hydroxyphenyl)benzothiazole": 'OC1=CC=CC=C1C2=NC3=CC=CC=C3S2',
  "2-(2-Hydroxyphenyl)benzoxazole": 'OC1=CC=CC=C1C2=NC3=CC=CC=C3O2',
  "2-(2-Pyridyl)benzoxazole": 'C1(C2=NC=CC=C2)=NC3=CC=CC=C3O1',
  "2-(2-Pyridyl)indole": 'C(N1)(C2=NC=CC=C2)=CC3=C1C=CC=C3',
  "2-(3,4-Epoxycyclohexyl)ethyltrimethoxysilane": 'CO[Si](OC)(CCC1CC(O2)C2CC1)OC',
  "2-(3-Bromophenyl)-2,3-dihydro-1H-naphtho[1,8-de][1,3,2]diazaborine": 'BrC1=CC=CC(B(N2)NC3=CC=CC4=C3C2=CC=C4)=C1',
  "2-(4-Bromophenyl)-2,3-dihydro-1H-naphtho[1,8-de][1,3,2]diazaborine": 'BrC1=CC=C(C=C1)B(N2)NC3=CC=CC4=CC=CC2=C43',
  "2-(4-Thiazolyl)benzimidazole": 'C1(C2=CSC=N2)=NC3=CC=CC=C3N1',
  "2-(5-Norbornene-2,3-dicarboximido)-1,1,3,3-tetramethyluronium Tetrafluoroborate": 'CN(C)C(N(C)C)=[O+]N(C1=O)C(C2C1C3C=CC2C3)=O.F[B-](F)(F)F',
  "2-(Dicyclohexylphosphino)-2'-(dimethylamino)biphenyl": 'CN(C1=CC=CC=C1C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C',
  "2-(Dicyclohexylphosphino)biphenyl": 'C1(C2=CC=CC=C2)=CC=CC=C1P(C3CCCCC3)C4CCCCC4',
  "2-(Dimethylvinylsilyl)pyridine": 'C=C[Si](C1=NC=CC=C1)(C)C',
  "2-(Diphenylphosphino)benzaldehyde": 'O=CC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3',
  "2-(Diphenylphosphino)benzoic Acid": 'O=C(O)C1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3',
  "2-(Diphenylphosphino)benzonitrile": 'N#CC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3',
  "2-(Diphenylphosphino)biphenyl": 'C1(C2=CC=CC=C2)=CC=CC=C1P(C3=CC=CC=C3)C4=CC=CC=C4',
  "2-(Di-tert-butylphosphino)biphenyl": 'CC(P(C1=CC=CC=C1C2=CC=CC=C2)C(C)(C)C)(C)C',
  "2-(Trimethylsilylethynyl)thiophene": 'C[Si](C#CC1=CC=CS1)(C)C',
  "2,2'-(Methylimino)bis(N,N-di-n-octylacetamide)": 'CN(CC(N(CCCCCCCC)CCCCCCCC)=O)CC(N(CCCCCCCC)CCCCCCCC)=O',
  "2,2,2-Trifluoro-1,1-ethanediol": 'OC(O)C(F)(F)F',
  "2,2,2-Trifluoroethyl Methanesulfonate": 'CS(=O)(OCC(F)(F)F)=O',
  "2,2,2-Trifluoroethyl Trifluoromethanesulfonate": 'O=S(C(F)(F)F)(OCC(F)(F)F)=O',
  "2,2,2-Trifluoroethyl p-Toluenesulfonate": 'CC1=CC=C(S(=O)(OCC(F)(F)F)=O)C=C1',
  "2,2,2-Trifluoro-N,N-dimethylacetamide": 'O=C(N(C)C)C(F)(F)F',
  "2,2,3,3,3-Pentafluoropropyl p-Toluenesulfonate": 'CC1=CC=C(S(=O)(OCC(F)(F)C(F)(F)F)=O)C=C1',
  "2,2,6,6-Tetramethylpiperidine": 'CC1(C)CCCC(C)(C)N1',
  "2,2,6,6-Tetramethylpiperidine 1-Oxyl": 'CC1(C)CCCC(C)(C)N1[O]',
  "2,2,6,6-Tetramethylpiperidine 1-Oxyl Free Radical": 'CC1(C)CCCC(C)(C)N1[O]',
  "2,2':6',2''-Terpyridine": 'C1(C2=NC(C3=NC=CC=C3)=CC=C2)=NC=CC=C1',
  "2,2':6',2''-Terpyridine-4'-carboxylic Acid": 'O=C(C1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1)O',
  "2,2′-Bipyridinium chlorochromate": '[O-][Cr](=O)(Cl)=O.[O-][Cr](=O)(Cl)=O.C1(C2=CC=CC=[NH+]2)=CC=CC=[NH+]1',
  "2,2'-Bi(1,8-naphthyridine)": 'C1(C2=NC3=NC=CC=C3C=C2)=NC4=NC=CC=C4C=C1',
  "2,2'-Bi-4-lepidine": 'CC1=CC(C2=NC3=CC=CC=C3C(C)=C2)=NC4=C1C=CC=C4',
  "2,2'-Bicinchoninic Acid Dipotassium Salt Hydrate": 'O=C([O-])C1=CC(C2=NC3=CC=CC=C3C(C([O-])=O)=C2)=NC4=CC=CC=C14.[H]O[H].[K+].[K+]',
  "2,2'-Biimidazole": 'C1(C2=NC=CN2)=NC=CN1',
  "2,2'-Bipyrazine": 'C1(C2=NC=CN=C2)=NC=CN=C1',
  "2,2'-Bipyridine-3,3'-dicarboxylic Acid": 'O=C(C1=CC=CN=C1C2=NC=CC=C2C(O)=O)O',
  "2,2'-Bipyridine-3,3'-diol": 'OC1=CC=CN=C1C2=NC=CC=C2O',
  "2,2'-Bipyridine-4,4'-dicarboxylic Acid": 'O=C(C1=CC(C2=NC=CC(C(O)=O)=C2)=NC=C1)O',
  "2,2'-Bipyridine-5,5'-dicarboxylic Acid": 'O=C(C1=CN=C(C2=NC=C(C(O)=O)C=C2)C=C1)O',
  "2,2'-Bipyridine-6,6'-dicarboxylic Acid": 'O=C(C1=CC=CC(C2=NC(C(O)=O)=CC=C2)=N1)O',
  "2,2'-Bipyridine-6,6'-diol": 'OC1=CC=CC(C2=NC(O)=CC=C2)=N1',
  "2,2'-Bipyridine-6-carbonitrile": 'N#CC1=CC=CC(C2=NC=CC=C2)=N1',
  "2,2'-Bipyridyl": 'C1(C2=NC=CC=C2)=NC=CC=C1',
  "2,2'-Bipyridyl 1,1'-Dioxide": '[O-][N+]1=CC=CC=C1C2=CC=CC=[N+]2[O-]',
  "2,2'-Bipyrimidyl": 'C1(C2=NC=CC=N2)=NC=CC=N1',
  "2,2'-Biquinoline": 'C1(C2=NC3=CC=CC=C3C=C2)=NC4=CC=CC=C4C=C1',
  "2,2'-Bis(diphenylphosphino)biphenyl": 'C1(C2=CC=CC=C2P(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1P(C5=CC=CC=C5)C6=CC=CC=C6',
  "2,2'-Carbonylbis(3,5-dioxo-4-methyl-1,2,4-oxadiazolidine)": 'O=C(N(C(N1C)=O)OC1=O)N(C(N2C)=O)OC2=O',
  "2,2'-Diamino-4,4'-bithiazole": 'NC1=NC(C2=CSC(N)=N2)=CS1',
  "2,2-Difluoro-2-(fluorosulfonyl)acetic Acid": 'O=C(O)C(F)(F)S(=O)(F)=O',
  "2,2-Difluoroethyl Trifluoromethanesulfonate": 'O=S(C(F)(F)F)(OCC(F)F)=O',
  "2,2-Difluoroethylamine": 'NCC(F)F',
  "2,2-Dimethyl-6,6,7,7,8,8,8-heptafluoro-3,5-octanedione": 'CC(C)(C)C(CC(C(F)(F)C(F)(F)C(F)(F)F)=O)=O',
  "2,2-Dimethylpropylmagnesium chloride": 'Cl[Mg]CC(C)(C)C',
  "2,2'-Dipyridyl Disulfide": 'C1(SSC2=NC=CC=C2)=NC=CC=C1',
  "2,2'-Methylenebisbenzothiazole": 'C1(CC2=NC3=CC=CC=C3S2)=NC4=CC=CC=C4S1',
  "2,3,7,8,12,13,17,18-Octaethylporphyrin": 'CCC1=C(CC)C2=NC1=CC3=C(CC)C(CC)=C(N3)C=C4C(CC)=C(CC)C(C=C5C(CC)=C(CC)C(N5)=C2)=N4',
  "2,3,7,8,12,13,17,18-Octafluoro-5,10,15,20-tetrakis(pentafluorophenyl)porphyrin": 'FC1=C(F)C(F)=C(F)C(F)=C1C2=C3C(F)=C(F)C(C(C4=C(F)C(F)=C(F)C(F)=C4F)=C(N5)C(F)=C(F)C5=C(C6=C(F)C(F)=C(F)C(F)=C6F)C7=NC(C(F)=C7F)=C(C8=C(F)C(F)=C(F)C(F)=C8F)C9=C(F)C(F)=C2N9)=N3',
  "2,3-Dimethyl-1-propylimidazolium Bis(trifluoromethanesulfonyl)imide": 'CN1C(C)=[N+](CCC)C=C1.O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O',
  "2,3-Lutidine": 'CC1=NC=CC=C1C',
  "2,4,4,6-Tetrabromo-2,5-cyclohexadienone": 'BrC1=CC(Br)(Br)C=C(Br)C1=O',
  "2,4,5-Trichlorophenol": 'OC1=CC(Cl)=C(Cl)C=C1Cl',
  "2,4,6,8-Tetramethyl-2,4,6,8-tetravinylcyclotetrasiloxane": 'C=CO1(C)[SiH2]O(C=C)(C)[SiH2]O(C=C)(C)[SiH2]O(C=C)(C)[SiH2]1',
  "2,4,6,8-Tetramethylcyclotetrasiloxane": 'CO1[SiH2]O(C)[SiH2]O(C)[SiH2]O(C)[SiH2]1',
  "2,4,6-Tri(2-pyridyl)-1,3,5-triazine": 'C1(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=NC(C4=NC=CC=C4)=N1',
  "2,4,6-Tri(4-pyridyl)-1,3,5-triazine": 'C1(C2=CC=NC=C2)=NC(C3=CC=NC=C3)=NC(C4=CC=NC=C4)=N1',
  "2,4,6-Trichlorobenzoyl Chloride": 'O=C(Cl)C1=C(Cl)C=C(Cl)C=C1Cl',
  "2,4,6-Triisopropylbenzenesulfonyl Chloride": 'O=S(C1=C(C(C)C)C=C(C(C)C)C=C1C(C)C)(Cl)=O',
  "2,4,6-Trimethylpyridinium p-Toluenesulfonate": 'CC1=[NH+]C(C)=CC(C)=C1.CC2=CC=C(S(=O)([O-])=O)C=C2',
  "2,4,6-Tris(3,4,5-trifluorophenyl)boroxin": 'FC1=C(F)C(F)=CC(B2OB(C3=CC(F)=C(F)C(F)=C3)OB(C4=CC(F)=C(F)C(F)=C4)O2)=C1',
  "2,4-Dibromo-6-[(E)-[[(1R,2R)-2-(isoindolin-2-yl)-1,2-diphenylethyl]imino]methyl]phenol": 'OC1=C(/C=N/[C@H](C2=CC=CC=C2)[C@H](N3CC4=C(C=CC=C4)C3)C5=CC=CC=C5)C=C(Br)C=C1Br',
  "2,4-Dibromo-6-[[[[(4S,5S)-4,5-dihydro-4,5-diphenyl-1-tosyl-1H-imidazol-2-yl]methyl][(S)-1-phenylethyl]amino]methyl]phenol": 'OC1=C(CN(CC2=N[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N2S(=O)(C5=CC=C(C)C=C5)=O)[C@H](C6=CC=CC=C6)C)C=C(Br)C=C1Br',
  "2,4-Lutidine": 'CC1=NC=CC(C)=C1',
  "2,4-Mesitylenedisulfonyl Dichloride": 'CC1=C(S(=O)(Cl)=O)C(C)=CC(C)=C1S(=O)(Cl)=O',
  "2,5-Bis(4-bromophenyl)-1,1-dimethyl-3,4-diphenylsilole": 'C[Si]1(C)C(C2=CC=C(Br)C=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C1C5=CC=C(Br)C=C5',
  "2,5-Bis[(trimethylsilyl)ethynyl]thiophene": 'C[Si](C#CC1=CC=C(C#C[Si](C)(C)C)S1)(C)C',
  "2,5-Dibromo-1,1,3,4-tetraphenylsilole": 'BrC1=C(C2=CC=CC=C2)C(C3=CC=CC=C3)=C(Br)[Si]1(C4=CC=CC=C4)C5=CC=CC=C5',
  "2,5-Dibromo-1,1-dimethyl-3,4-diphenylsilole": 'C[Si]1(C)C(Br)=C(C2=CC=CC=C2)C(C3=CC=CC=C3)=C1Br',
  "2,5-Diphenylpyridine": 'C1(C2=CC=CC=C2)=NC=C(C3=CC=CC=C3)C=C1',
  "2,5-Lutidine": 'CC1=NC=C(C)C=C1',
  "2,5-Norbornadiene": 'C12C=CC(C2)C=C1',
  "2,6-Bis(2-benzimidazolyl)pyridine": 'C1(C2=NC3=CC=CC=C3N2)=NC(C4=NC5=CC=CC=C5N4)=CC=C1',
  "2,6-Bis(2-pyridyl)-4(1H)-pyridone": 'O=C1C=C(C2=NC=CC=C2)NC(C3=NC=CC=C3)=C1',
  "2,6-Bis[(2,2,6,6-tetramethyl-1-piperidinyl)methyl]phenylboronic Acid": 'OB(C1=C(CN2C(C)(C)CCCC2(C)C)C=CC=C1CN3C(C)(C)CCCC3(C)C)O',
  "2,6-Bis[(2R,4S,5S)-1-benzyl-4,5-diphenylimidazolidin-2-yl]pyridine": 'C1([C@@H]2N[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N2CC5=CC=CC=C5)=NC([C@@H]6N[C@@H](C7=CC=CC=C7)[C@H](C8=CC=CC=C8)N6CC9=CC=CC=C9)=CC=C1',
  "2,6-Bis[(2S,4S)-4-methyl-5,5-diphenyloxazolidin-2-yl]pyridine": 'C[C@@H]1N[C@H](C2=CC=CC([C@@H]3OC(C4=CC=CC=C4)(C5=CC=CC=C5)[C@H](C)N3)=N2)OC1(C6=CC=CC=C6)C7=CC=CC=C7',
  "2,6-Bis[(2S,5S)-4,4-diphenyl-1-aza-3-oxabicyclo[3.3.0]octan-2-yl]pyridine": '[H][C@]12C(C3=CC=CC=C3)(C4=CC=CC=C4)O[C@@H](C5=CC=CC([C@H]6N7CCC[C@@]7([H])C(C8=CC=CC=C8)(C9=CC=CC=C9)O6)=N5)N1CCC2',
  "2,6-Di(1-pyrazolyl)pyridine": 'C1(N2N=CC=C2)=NC(N3N=CC=C3)=CC=C1',
  "2,6-Di(2H-1,2,3-triazol-4-yl)pyridine": 'C1(C2=NNN=C2)=NC(C3=NNN=C3)=CC=C1',
  "2,6-Dimethyl-3,5-heptanedione": 'CC(C)C(CC(C(C)C)=O)=O',
  "2,6-Lutidine": 'CC1=NC(C)=CC=C1',
  "2,8,9-Triisobutyl-2,5,8,9-tetraaza-1-phosphabicyclo[3.3.3]undecane": 'CC(C)CN1CCN2CCN(CC(C)C)P1N(CC(C)C)CC2',
  "2,8,9-Triisopropyl-2,5,8,9-tetraaza-1-phosphabicyclo[3,3,3]undecane": 'CC(N1CCN2CCN(C(C)C)P1N(C(C)C)CC2)C',
  "2,8-Difluoro-5-(trifluoromethyl)-5H-dibenzo[b,d]thiophen-5-ium Trifluoromethanesulfonate": 'FC([S+]1C2=CC=C(F)C=C2C3=CC(F)=CC=C31)(F)F.O=S(C(F)(F)F)([O-])=O',
  "2,9-Dibutyl-1,10-phenanthroline": 'CCCCC1=NC2=C3N=C(CCCC)C=CC3=CC=C2C=C1',
  "2,9-Dichloro-1,10-phenanthroline": 'ClC1=NC2=C3N=C(Cl)C=CC3=CC=C2C=C1',
  "2,9-Diphenyl-1,10-phenanthroline": 'C1(C2=CC=CC=C2)=NC3=C4N=C(C5=CC=CC=C5)C=CC4=CC=C3C=C1',
  "2-[(2,2,6,6-Tetramethyl-1-piperidyl)methyl]phenylboronic Acid": 'OB(C1=CC=CC=C1CN2C(C)(C)CCCC2(C)C)O',
  "2-[N,N-Bis(trifluoromethanesulfonyl)amino]-5-chloropyridine": 'ClC1=CN=C(N(S(=O)(C(F)(F)F)=O)S(=O)(C(F)(F)F)=O)C=C1',
  "2-Acetylcyclohexanone": 'O=C1C(C(C)=O)CCCC1',
  "2-Acetylcyclopentanone": 'O=C1C(C(C)=O)CCC1',
  "2-Bromo-1,10-phenanthroline": 'BrC1=NC2=C3N=CC=CC3=CC=C2C=C1',
  "2-Bromo-1-ethylpyridinium Tetrafluoroborate": 'CC[N+]1=CC=CC=C1Br.F[B-](F)(F)F',
  "2-Bromo-2-cyano-N,N-dimethylacetamide": 'O=C(N(C)C)C(Br)C#N',
  "2-Chloro-1,10-phenanthroline": 'ClC1=NC2=C3N=CC=CC3=CC=C2C=C1',
  "2-Chloro-1,3,2-dioxaphospholane": 'ClP1OCCO1',
  "2-Chloro-1,3-bis(2,6-diisopropylphenyl)-1H-imidazolium Chloride": 'CC(C1=C([N+]2=C(Cl)N(C3=C(C(C)C)C=CC=C3C(C)C)C=C2)C(C(C)C)=CC=C1)C.[Cl-]',
  "2-Chloro-1,3-dimethylimidazolinium Chloride": 'C[N+]1=C(Cl)N(C)CC1.[Cl-]',
  "2-Chloro-1,3-dimethylimidazolinium Hexafluorophosphate": 'C[N+]1=C(Cl)N(C)CC1.F[P-](F)(F)(F)(F)F',
  "2-Chloro-1,3-dimethylimidazolinium Tetrafluoroborate": 'C[N+]1=C(Cl)N(C)CC1.F[B-](F)(F)F',
  "2-Chloro-1-methylpyridinium Iodide": 'C[N+]1=CC=CC=C1Cl.[I-]',
  "2-Chloro-1-methylpyridinium p-Toluenesulfonate": 'C[N+]1=CC=CC=C1Cl.CC2=CC=C(S(=O)([O-])=O)C=C2',
  "2-Chloro-4H-1,3,2-benzodioxaphosphorin-4-one": 'O=C1OP(Cl)OC2=CC=CC=C12',
  "2-Cyanoethyltriethoxysilane": 'CCO[Si](OCC)(CCC#N)OCC',
  "2-Dicyclohexylphosphino-2',4',6'-triisopropylbiphenyl": 'CC(C1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(C(C)C)=CC(C(C)C)=C1)C',
  "2-Dicyclohexylphosphino-2',6'-diisopropoxybiphenyl": 'CC(OC1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(OC(C)C)=CC=C1)C',
  "2-Dicyclohexylphosphino-2',6'-dimethoxybiphenyl": 'COC1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(OC)=CC=C1',
  "2-Di-tert-butylphosphino-2',4',6'-triisopropylbiphenyl": 'CC(C1=C(C2=CC=CC=C2P(C(C)(C)C)C(C)(C)C)C(C(C)C)=CC(C(C)C)=C1)C',
  "2-Fluoro-1-methylpyridinium p-Toluenesulfonate": 'C[N+]1=CC=CC=C1F.CC2=CC=C(S(=O)([O-])=O)C=C2',
  "2-Furoyltrifluoroacetone": 'O=C(CC(C1=CC=CO1)=O)C(F)(F)F',
  "2-Hydroxy-2-azaadamantane": 'ON1C2CC3CC(C2)CC1C3',
  "2-Hydroxybenzaldehyde Phenylhydrazone": 'OC1=CC=CC=C1/C=N/NC2=CC=CC=C2',
  "2-Hydroxybenzoic Acid": 'O=C(O)C1=CC=CC=C1O',
  "2-Hydroxypyridine N-Oxide": 'OC1=CC=CC=[N+]1[O-]',
  "2-Iodosobenzoic Acid": 'O=C(O)C1=CC=CC=C1I=O',
  "2-Iodoxybenzoic acid": 'O=C(O)C1=CC=CC=C1I(=O)=O',
  "2-methoxy ethanol": 'OCCOC',
  "2-Methyl-1,10-phenanthroline": 'CC1=NC2=C3N=CC=CC3=CC=C2C=C1',
  "2-Methyl-6-nitrobenzoic Anhydride": 'O=C(OC(C1=C([N+]([O-])=O)C=CC=C1C)=O)C2=C([N+]([O-])=O)C=CC=C2C',
  "2-Methylbenzo[e]indene": 'N/A',
  "2-Methylcyclopenta[l]phenanthrene": 'CC1=CC2=C3CC=CC=C3C4=C(C2=C1)C=CC=C4',
  "2-Nitrophenylarsonic Acid": 'O=[As](C1=CC=CC=C1[N+]([O-])=O)(O)O',
  "2-Norbornene": 'C12C=CC(C2)CC1',
  "2-Phenylethylboronic Acid": 'OB(CCC1=CC=CC=C1)O',
  "2-Salicylideneaminophenol": 'OC1=CC=CC=C1/N=C/C2=CC=CC=C2O',
  "2-tert-Butyl-1,1,3,3-tetramethylguanidine": 'CN(C)C(N(C)C)=NC(C)(C)C',
  "2-Trimethylsilyloxy-1,3-butadiene": 'C=C(O[Si](C)(C)C)C=C',
  "2-Trimethylsilylthiophene": 'C[Si](C1=CC=CS1)(C)C',
  "3-(2-Aminoethylamino)propyltrimethoxysilane": 'CO[Si](OC)(CCCNCCN)OC',
  "3-(Diethoxyphosphoryloxy)-1,2,3-benzotriazin-4(3H)-one": 'O=C1N(OP(OCC)(OCC)=O)N=NC2=CC=CC=C12',
  "3-(Dimethylsilyloxy)-1,1,5,5-tetramethyl-3-phenyltrisiloxane": 'C[SiH](C)O[Si](C1=CC=CC=C1)(O[SiH](C)C)O[SiH](C)C',
  "3-(Diphenylphosphino)-1-propylamine": 'NCCCP(C1=CC=CC=C1)C2=CC=CC=C2',
  "3-(Triallylsilyl)propyl Acrylate": 'C=CC(OCCC[Si](CC=C)(CC=C)CC=C)=O',
  "3-(Triallylsilyl)propyl Methacrylate": 'CC(C(OCCC[Si](CC=C)(CC=C)CC=C)=O)=C',
  "3-(Triethoxysilyl)propyl Isocyanate": 'CCO[Si](CCCN=C=O)(OCC)OCC',
  "3-(Trimethoxysilyl)propyl Acrylate": 'C=CC(OCCC[Si](OC)(OC)OC)=O',
  "3-(Trimethoxysilyl)propyl Methacrylate": 'CC(C(OCCC[Si](OC)(OC)OC)=O)=C',
  "3-(Trimethylsilylethynyl)thiophene": 'C[Si](C#CC1=CSC=C1)(C)C',
  "3,3'-Iminobis(N,N-dimethylpropylamine)": 'CN(C)CCCNCCCN(C)C',
  "3,3'-Methylenebis(1-tert-butyl-3-imidazolium Bromide)": 'CC(N1C=C[N+](C[N+]2=CN(C(C)(C)C)C=C2)=C1)(C)C.[Br-].[Br-]',
  "3,3'-Methylenebis[1-(2,6-diisopropylphenyl)-3-imidazolium Bromide]": 'CC(C1=C(N2C=C[N+](C[N+]3=CN(C4=C(C(C)C)C=CC=C4C(C)C)C=C3)=C2)C(C(C)C)=CC=C1)C.[Br-].[Br-]',
  "3,4,7,8-Tetramethyl-1,10-phenanthroline": 'CC1=C(C)C2=CC=C3C(C)=C(C)C=NC3=C2N=C1',
  "3,4-Diacetyl-2,5-hexanedione": 'CC(C(C(C)=O)C(C(C)=O)C(C)=O)=O',
  "3,4-Dihydro-2H-pyrido[1,2-a]pyrimidin-2-one": 'O=C1N=C(C=CC=C2)N2CC1',
  "3,4-Dihydro-3-hydroxy-4-oxo-1,2,3-benzotriazine": 'O=C1N(O)N=NC2=CC=CC=C12',
  "3,4-Lutidine": 'CC1=C(C)C=CN=C1',
  "3,5,6,8-Tetrabromo-1,10-phenanthroline": 'BrC1=CC2=C(Br)C(Br)=C3C=C(Br)C=NC3=C2N=C1',
  "3,5-Bis(trifluoromethyl)phenylboronic Acid": 'OB(C1=CC(C(F)(F)F)=CC(C(F)(F)F)=C1)O',
  "3,5-Di(2-pyridyl)pyrazole": 'C1(C2=NC=CC=C2)=NNC(C3=NC=CC=C3)=C1',
  "3,5-Heptanedione": 'CCC(CC(CC)=O)=O',
  "3,5-Lutidine": 'CC1=CC(C)=CN=C1',
  "3,6-Di(2-pyridyl)-1,2,4,5-tetrazine": 'C1(C2=NC=CC=C2)=NN=C(C3=NC=CC=C3)N=N1',
  "3,8-Dibromo-1,10-phenanthroline": 'BrC1=CC2=CC=C3C=C(Br)C=NC3=C2N=C1',
  "3-[(Dimethylsilyl)oxy]-1,1,3,5,5-pentamethyltrisiloxane": 'C[SiH](C)O[Si](C)(O[SiH](C)C)O[SiH](C)C',
  "3-[[Dimethyl(vinyl)silyl]oxy]-1,1,5,5-tetramethyl-3-phenyl-1,5-divinyltrisiloxane": 'C=C[Si](C)(C)O[Si](C1=CC=CC=C1)(O[Si](C)(C)C=C)O[Si](C)(C)C=C',
  "3-[[tert-Butyl(dimethyl)silyl]oxy]-1-propanol": 'OCCCO[Si](C)(C(C)(C)C)C',
  "3-Aminopropyltriethoxysilane": 'CCO[Si](OCC)(CCCN)OCC',
  "3-Aminopropyltrimethoxysilane": 'CO[Si](OC)(CCCN)OC',
  "3-Bromo-1-(trimethylsilyl)-1-propyne": 'C[Si](C#CCBr)(C)C',
  "3-Bromo-1,10-phenanthroline": 'BrC1=CC2=CC=C3C=CC=NC3=C2N=C1',
  "3-Chloroacetylacetone": 'CC(C(Cl)C(C)=O)=O',
  "3-Chloroperoxybenzoic acid": 'C1=CC(=CC(=C1)Cl)C(=O)OO',
  "3-Chloropropyltriethoxysilane": 'CCO[Si](OCC)(CCCCl)OCC',
  "3-Ethyl-2,4-pentanedione": 'CC(C(CC)C(C)=O)=O',
  "3-Glycidyloxypropyltrimethoxysilane": 'CO[Si](OC)(CCCOCC1CO1)OC',
  "3-Mercaptopropyl(dimethoxy)methylsilane": 'C[Si](OC)(CCCS)OC',
  "3-Methyl-1-phenyl-2-phospholene 1-Oxide": 'CC(CC1)=CP1(C2=CC=CC=C2)=O',
  "3-Methyl-2,4-pentanedione": 'CC(C(C)C(C)=O)=O',
  "3-Nitro-1,2,4-triazole": 'O=[N+](C1=NNC=N1)[O-]',
  "3-Phenyl-2,4-pentanedione": 'CC(C(C1=CC=CC=C1)C(C)=O)=O',
  "3-Pyridinecarboxylic Anhydride": 'O=C(OC(C1=CC=CN=C1)=O)C2=CC=CN=C2',
  "3-Quinolineboronic acid": 'OB(C1=CC2=CC=CC=C2N=C1)O',
  "3-Tolylboronic acid": 'CC1=CC=CC(B(O)O)=C1',
  "3-Trimethoxysilylpropyl Chloride": 'CO[Si](CCCCl)(OC)OC',
  "3-Trimethylsilyl-2-propyn-1-ol": 'OCC#C[Si](C)(C)C',
  "4-([2,2':6',2''-Terpyridin]-4'-yl)benzoic Acid": 'O=C(O)C1=CC=C(C2=CC(C3=NC=CC=C3)=NC(C4=NC=CC=C4)=C2)C=C1',
  "4-(4,6-Dimethoxy-1,3,5-triazin-2-yl)-4-methylmorpholinium Chloride": 'C[N+]1(C2=NC(OC)=NC(OC)=N2)CCOCC1.[Cl-]',
  "4'-(4-Bromophenyl)-2,2':6',2''-terpyridine": 'BrC1=CC=C(C2=CC(C3=NC=CC=C3)=NC(C4=NC=CC=C4)=C2)C=C1',
  "4-(4-Dimethylaminophenylazo)phenylarsonic Acid Hydrochloride": 'O=[As](C1=CC=C(/N=N/C2=CC=C(N(C)C)C=C2)C=C1)(O)O.[H]Cl',
  "4'-(4-Pyridyl)-2,2':6',2''-terpyridine": 'C1(C2=NC(C3=NC=CC=C3)=CC(C4=CC=NC=C4)=C2)=NC=CC=C1',
  "4-(Bromomethyl)-4'-methyl-2,2'-bipyridyl": 'CC1=CC(C2=NC=CC(CBr)=C2)=NC=C1',
  "4-(Chloromethyl)-4'-methyl-2,2'-bipyridyl": 'CC1=CC(C2=NC=CC(CCl)=C2)=NC=C1',
  "4-(Dimethylamino)phenyldiphenylphosphine": 'CN(C1=CC=C(P(C2=CC=CC=C2)C3=CC=CC=C3)C=C1)C',
  "4-(Dimethylamino)pyridine": 'CN(C1=CC=NC=C1)C',
  "4-(Diphenylphosphino)benzoic Acid": 'O=C(O)C1=CC=C(P(C2=CC=CC=C2)C3=CC=CC=C3)C=C1',
  "4-(N,N-Dimethylamino)phenylboronic acid": 'CN(C)C1=CC=C(B(O)O)C=C1',
  "4-(Trimethylsilyl)-3-butyn-2-one": 'CC(C#C[Si](C)(C)C)=O',
  "4-(Trimethylsilyl)diphenylacetylene": 'C[Si](C1=CC=C(C#CC2=CC=CC=C2)C=C1)(C)C',
  "4,4,4-Trifluoro-1-(2-naphthyl)-1,3-butanedione": 'O=C(C1=CC=C2C=CC=CC2=C1)CC(C(F)(F)F)=O',
  "4,4,4-Trifluoro-1-(2-thienyl)-1,3-butanedione": 'O=C(C1=CC=CS1)CC(C(F)(F)F)=O',
  "4,4,4-Trifluoro-1-(4-methoxyphenyl)-1,3-butanedione": 'O=C(C1=CC=C(OC)C=C1)CC(C(F)(F)F)=O',
  "4,4,4-Trifluoro-1-(p-tolyl)-1,3-butanedione": 'O=C(C1=CC=C(C)C=C1)CC(C(F)(F)F)=O',
  "4,4,4-Trifluoro-1-phenyl-1,3-butanedione": 'O=C(C1=CC=CC=C1)CC(C(F)(F)F)=O',
  "4,4,5,5-Tetramethyl-1,3,2-dioxaphospholane 2-Oxide": 'CC1(C)C(C)(C)OP(O1)=O',
  "4,4'-Bis(5-hexyl-2-thienyl)-2,2'-bipyridyl": 'CCCCCCC1=CC=C(C2=CC(C3=NC=CC(C4=CC=C(CCCCCC)S4)=C3)=NC=C2)S1',
  "4,4'-Bis(chloromethyl)-2,2'-bipyridyl": 'ClCC1=CC(C2=NC=CC(CCl)=C2)=NC=C1',
  "4,4'-Bis(hydroxymethyl)-2,2'-bipyridine": 'OCC1=CC(C2=NC=CC(CO)=C2)=NC=C1',
  "4,4'-Diamino-2,2'-bipyridyl": 'NC1=CC(C2=NC=CC(N)=C2)=NC=C1',
  "4,4'-Dibromo-2,2'-bipyridyl": 'BrC1=CC(C2=NC=CC(Br)=C2)=NC=C1',
  "4,4'-Dimethoxy-2,2'-bipyridyl": 'COC1=CC(C2=NC=CC(OC)=C2)=NC=C1',
  "4,4'-Dimethyl-2,2'-bipyridyl": 'CC1=CC(C2=NC=CC(C)=C2)=NC=C1',
  "4,4'-Dimethyl-2,2'-bipyridyl 1-Oxide": 'CC1=CC(C2=CC(C)=CC=[N+]2[O-])=NC=C1',
  "4,4'-Dinonyl-2,2'-bipyridyl": 'CCCCCCCCCC1=CC(C2=NC=CC(CCCCCCCCC)=C2)=NC=C1',
  "4,4'-Di-tert-butyl-2,2'-bipyridyl": 'CC(C1=CC(C2=NC=CC(C(C)(C)C)=C2)=NC=C1)(C)C',
  "4,5-Bis(diphenylphosphino)-9,9-dimethylxanthene": 'CC1(C)C2=C(OC3=C1C=CC=C3P(C4=CC=CC=C4)C5=CC=CC=C5)C(P(C6=CC=CC=C6)C7=CC=CC=C7)=CC=C2',
  "4,5-Diazafluoren-9-one": 'O=C1C2=CC=CN=C2C3=C1C=CC=N3',
  "4,5-Diazafluorene-9-one O-(p-Toluenesulfonyl)oxime": 'O=S(ON=C1C2=CC=CN=C2C3=C1C=CC=N3)(C4=CC=C(C)C=C4)=O',
  "4,6-Bis(diphenylphosphino)phenoxazine": 'C(C=CC=C1N2)(P(C3=CC=CC=C3)C4=CC=CC=C4)=C1OC5=C2C=CC=C5P(C6=CC=CC=C6)C7=CC=CC=C7',
  "4,6-Diphenylthieno[3,4-d]-1,3-dioxol-2-one 5,5-Dioxide": 'O=C(OC1=C2C3=CC=CC=C3)OC1=C(C4=CC=CC=C4)S2(=O)=O',
  "4,7-Dibromo-1,10-phenanthroline Hydrate": 'BrC1=CC=NC2=C3N=CC=C(Br)C3=CC=C12.[H]O[H]',
  "4,7-Dichloro-1,10-phenanthroline Hydrate": 'ClC1=CC=NC2=C3N=CC=C(Cl)C3=CC=C12.[H]O[H]',
  "4,7-Dihydroxy-1,10-phenanthroline": 'OC1=CC=NC2=C3N=CC=C(O)C3=CC=C12',
  "4,7-Dimethyl-1,10-phenanthroline": 'CC1=CC=NC2=C3N=CC=C(C)C3=CC=C12',
  "4'-[(tert-Butyldimethylsilyloxy)methyl]-2,2,2-trifluoroacetophenone": 'O=C(C1=CC=C(CO[Si](C)(C(C)(C)C)C)C=C1)C(F)(F)F',
  "4-[2,2,2-Trifluoro-1-[(trimethylsilyl)oxy]ethyl]morpholine": 'C[Si](OC(N1CCOCC1)C(F)(F)F)(C)C',
  "4-[2-[2-(4-Iodophenoxy)ethoxy]carbonyl]benzoyloxy-2,2,6,6-tetramethylpiperidin-1-oxyl": '[O]N1C(C)(C)CC(OC(C2=CC=CC=C2C(OCCOC3=CC=C(I)C=C3)=O)=O)CC1(C)C',
  "4-Acetamido-2,2,6,6-tetramethyl-1-oxopiperidinium Tetrafluoroborate": 'O=[N+]1C(C)(C)CC(NC(C)=O)CC1(C)C.F[B-](F)(F)F',
  "4-Acetamido-2,2,6,6-tetramethylpiperidine 1-Oxyl": 'CC1(C)CC(NC(C)=O)CC(C)(C)N1[O]',
  "4'-Bromo-2,2':6',2''-terpyridine": 'BrC1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1',
  "4-Bromotetraphenylsilane": 'BrC1=CC=C([Si](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)C=C1',
  "4'-Chloro-2,2':6',2''-terpyridine": 'ClC1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1',
  "4-Chlorophenyl Phosphorodichloridate": 'O=P(Cl)(Cl)OC1=CC=C(Cl)C=C1',
  "4-Dimethylamino-1-neopentylpyridinium Chloride": 'CC(C)(C)C[N+]1=CC=C(N(C)C)C=C1.[Cl-]',
  "4-Dimethylaminopyridine": 'CN(C1=CC=NC=C1)C',
  "4-Dimethylaminopyridinium Bromide Perbromide": 'CN(C)C1=CC=[NH+]C=C1.Br[Br-]Br',
  "4-Ethyl-4-methylmorpholinium Bromide": 'C[N+]1(CC)CCOCC1.[Br-]',
  "4-Fluoro-N-salicylideneaniline": 'OC1=CC=CC=C1/C=N/C2=CC=C(F)C=C2',
  "4-Fluorophenylmagnesium Bromide": 'Br[Mg]C1=CC=C(F)C=C1',
  "4-Hydroxy-2,2,6,6-tetramethylpiperidine 1-Oxyl Benzoate": 'CC1(C)CC(OC(C2=CC=CC=C2)=O)CC(C)(C)N1[O]',
  "4-Hydroxy-3-nitrophenylarsonic Acid": 'O=[As](C1=CC=C(O)C([N+]([O-])=O)=C1)(O)O',
  "4-Hydroxymethyl-4'-methyl-2,2'-bipyridyl": 'CC1=CC(C2=NC=CC(CO)=C2)=NC=C1',
  "4-Hydroxyphenylarsonic Acid": 'O=[As](C1=CC=C(O)C=C1)(O)O',
  "4-Methoxy-2,2,6,6-tetramethylpiperidine 1-Oxyl": 'CC1(C)CC(OC)CC(C)(C)N1[O]',
  "4-Methoxycarbonylphenylboronic acid": 'B(C1=CC=C(C=C1)C(=O)OC)(O)O',
  "4-Methylmorpholine N-Oxide": 'C[N+]1([O-])CCOCC1',
  "4-Nitrophenol": 'OC1=CC=C([N+]([O-])=O)C=C1',
  "4-Nitrophenyl Trifluoromethanesulfonate": 'O=S(C(F)(F)F)(OC1=CC=C([N+]([O-])=O)C=C1)=O',
  "4-Pyrrolidinopyridine": 'C1(N2CCCC2)=CC=NC=C1',
  "4-tert-Butyl-2,6-dimethylphenylsulfur Trifluoride": 'CC1=C(S(F)(F)F)C(C)=CC(C(C)(C)C)=C1',
  "4-Trifluoromethylbenzoic Anhydride": 'O=C(OC(C1=CC=C(C(F)(F)F)C=C1)=O)C2=CC=C(C(F)(F)F)C=C2',
  "4-Trimethylsilyl-3-butyn-2-ol": 'CC(O)C#C[Si](C)(C)C',
  "5-(Trimethylsilyl)-1,2,3,4,5-pentamethyl-1,3-cyclopentadiene": 'CC1([Si](C)(C)C)C(C)=C(C)C(C)=C1C',
  "5,10,15,20-Tetra(4-pyridyl)porphyrin": 'C1(C2=CC=NC=C2)=C3C=CC(C(C4=CC=NC=C4)=C5C=CC(N5)=C(C6=CC=NC=C6)C(C=C7)=NC7=C(C8=CC=NC=C8)C9=CC=C1N9)=N3',
  "5,10,15,20-Tetrakis(2,4,6-trimethylphenyl)porphyrin": 'CC1=C(C2=C3C=CC(C(C4=C(C)C=C(C)C=C4C)=C(N5)C=CC5=C(C6=C(C)C=C(C)C=C6C)C7=NC(C=C7)=C(C8=C(C)C=C(C)C=C8C)C9=CC=C2N9)=N3)C(C)=CC(C)=C1',
  "5,10,15,20-Tetrakis(2,6-dichlorophenyl)porphyrin": 'ClC1=C(C2=C3C=CC(C(C4=C(Cl)C=CC=C4Cl)=C(N5)C=CC5=C(C6=C(Cl)C=CC=C6Cl)C7=NC(C=C7)=C(C8=C(Cl)C=CC=C8Cl)C9=CC=C2N9)=N3)C(Cl)=CC=C1',
  "5,10,15,20-Tetrakis(3,5-dihydroxyphenyl)porphyrin": 'OC1=CC(O)=CC(C2=C3C=CC(C(C4=CC(O)=CC(O)=C4)=C(N5)C=CC5=C(C6=CC(O)=CC(O)=C6)C7=NC(C=C7)=C(C8=CC(O)=CC(O)=C8)C9=CC=C2N9)=N3)=C1',
  "5,10,15,20-Tetrakis(3,5-dimethoxyphenyl)porphyrin": 'COC1=CC(OC)=CC(C2=C3C=CC(C(C4=CC(OC)=CC(OC)=C4)=C(N5)C=CC5=C(C6=CC(OC)=CC(OC)=C6)C7=NC(C=C7)=C(C8=CC(OC)=CC(OC)=C8)C9=CC=C2N9)=N3)=C1',
  "5,10,15,20-Tetrakis(4-carboxymethyloxyphenyl)porphyrin": 'O=C(COC1=CC=C(C2=C3C=CC(C(C4=CC=C(OCC(O)=O)C=C4)=C(N5)C=CC5=C(C6=CC=C(OCC(O)=O)C=C6)C7=NC(C=C7)=C(C8=CC=C(OCC(O)=O)C=C8)C9=CC=C2N9)=N3)C=C1)O',
  "5,10,15,20-Tetrakis(4-hydroxyphenyl)porphyrin": 'OC1=CC=C(C2=C3C=CC(C(C4=CC=C(O)C=C4)=C(N5)C=CC5=C(C6=CC=C(O)C=C6)C7=NC(C=C7)=C(C8=CC=C(O)C=C8)C9=CC=C2N9)=N3)C=C1',
  "5,10,15,20-Tetrakis(4-methoxyphenyl)porphyrin": 'COC1=CC=C(C2=C3C=CC(C(C4=CC=C(OC)C=C4)=C(N5)C=CC5=C(C6=CC=C(OC)C=C6)C7=NC(C=C7)=C(C8=CC=C(OC)C=C8)C9=CC=C2N9)=N3)C=C1',
  "5,10,15,20-Tetrakis(pentafluorophenyl)porphyrin": 'FC1=C(F)C(F)=C(F)C(F)=C1C2=C3C=CC(C(C4=C(F)C(F)=C(F)C(F)=C4F)=C(N5)C=CC5=C(C6=C(F)C(F)=C(F)C(F)=C6F)C7=NC(C=C7)=C(C8=C(F)C(F)=C(F)C(F)=C8F)C9=CC=C2N9)=N3',
  "5,10,15,20-Tetrakis(p-tolyl)porphyrin": 'CC1=CC=C(C2=C3C=CC(C(C4=CC=C(C)C=C4)=C(N5)C=CC5=C(C6=CC=C(C)C=C6)C7=NC(C=C7)=C(C8=CC=C(C)C=C8)C9=CC=C2N9)=N3)C=C1',
  "5,5'-Bis(trifluoromethyl)-2,2'-bipyridyl": 'FC(C1=CN=C(C2=NC=C(C(F)(F)F)C=C2)C=C1)(F)F',
  "5,5''-Bis(trimethylstannyl)-2,2':5',2''-terthiophene": 'C[Sn](C1=CC=C(C2=CC=C(C3=CC=C([Sn](C)(C)C)S3)S2)S1)(C)C',
  "5,5'-Dibromo-2,2'-bipyridyl": 'BrC1=CN=C(C2=NC=C(Br)C=C2)C=C1',
  "5,5-Dibromomeldrum's Acid": 'O=C(OC(C)(C)O1)C(Br)(Br)C1=O',
  "5,5'-Dimethyl-2,2'-bipyridyl": 'CC1=CN=C(C2=NC=C(C)C=C2)C=C1',
  "5,6-Dimethyl-1,10-phenanthroline": 'CC1=C2C=CC=NC2=C3N=CC=CC3=C1C',
  "5-Bromo-1,10-phenanthroline": 'BrC1=C2C=CC=NC2=C3N=CC=CC3=C1',
  "5-Chloro-1,10-phenanthroline": 'ClC1=C2C=CC=NC2=C3N=CC=CC3=C1',
  "5-Methyl-1,10-phenanthroline Hydrate": 'CC1=C2C=CC=NC2=C3N=CC=CC3=C1.[H]O[H]',
  "5-Nitro-1,10-phenanthroline": 'O=[N+](C1=C2C=CC=NC2=C3N=CC=CC3=C1)[O-]',
  "5-Sulfosalicylic Acid Dihydrate": 'OC1=CC=C(S(=O)(O)=O)C=C1C(O)=O.[H]O[H].[H]O[H]',
  "6,10-Dibenzyl-N,N'-dimethyl-N,N,N',N'-tetrakis(4-methylbenzyl)-1,4-dioxaspiro[4.5]decane-(2R,3R)-diylbis(methylammonium) Bis(tetrafluoroborate)": 'C[N+](CC1=CC=C(C)C=C1)(CC2=CC=C(C)C=C2)(C)[C@@H]3OC4(C(CC5=CC=CC=C5)CCCC4(CC6=CC=CC=C6)[N+](CC7=CC=C(C)C=C7)(CC8=CC=C(C)C=C8)(C)C)OC3.F[B-](F)(F)F.F[B-](F)(F)F',
  "6,10-Dibenzyl-N,N'-dimethyl-N,N,N',N'-tetrakis(4-methylbenzyl)-1,4-dioxaspiro[4.5]decane-(2S,3S)-diylbis(methylammonium) Tetrafluoroborate": 'C[N+](CC1=CC=C(C)C=C1)(CC2=CC=C(C)C=C2)(C)[C@H]3OC4(C(CC5=CC=CC=C5)CCCC4(CC6=CC=CC=C6)[N+](CC7=CC=C(C)C=C7)(CC8=CC=C(C)C=C8)(C)C)OC3.F[B-](F)(F)F.F[B-](F)(F)F',
  "6,6'-Bis(chloromethyl)-2,2'-bipyridyl": 'ClCC1=CC=CC(C2=NC(CCl)=CC=C2)=N1',
  "6,6'-Diamino-2,2'-bipyridyl": 'NC1=CC=CC(C2=NC(N)=CC=C2)=N1',
  "6,6'-Dibromo-2,2'-bipyridyl": 'BrC1=CC=CC(C2=NC(Br)=CC=C2)=N1',
  "6,6'-Dicyano-2,2'-bipyridyl": 'N#CC1=CC=CC(C2=NC(C#N)=CC=C2)=N1',
  "6,6'-Dimethyl-2,2'-bipyridyl": 'CC1=CC=CC(C2=NC(C)=CC=C2)=N1',
  "6,7-Dihydro-2-(2,4,6-trimethylphenyl)-5H-pyrrolo[2,1-c]-1,2,4-triazolium Perchlorate": 'CC1=C([N+]2=CN3C(CCC3)=N2)C(C)=CC(C)=C1.O=Cl(=O)([O-])=O',
  "6,7-Dihydro-2-pentafluorophenyl-5H-pyrrolo[2,1-c][1,2,4]triazolium Tetrafluoroborate": 'FC1=C(F)C(F)=C(F)C(F)=C1[N+]2=CN3C(CCC3)=N2.F[B-](F)(F)F',
  "6,8-Bis(benzylthio)octanoic Acid": 'O=C(O)CCCCC(SCC1=CC=CC=C1)CCSCC2=CC=CC=C2',
  "6-Bromo-2,2'-bipyridyl": 'BrC1=CC=CC(C2=NC=CC=C2)=N1',
  "6-Bromo-4,4'-dimethyl-2,2'-bipyridyl": 'CC1=CC(C2=NC(Br)=CC(C)=C2)=NC=C1',
  "6-Methyl-2,4-heptanedione": 'CC(CC(CC(C)C)=O)=O',
  "7-Methyl-1,5,7-triazabicyclo[4.4.0]dec-5-ene": 'CN1C2=NCCCN2CCC1',
  "8-Ethylquinoline N-oxide": 'CCC1=CC=CC2=CC=C[N+]([O-])=C12',
  "8-Quinolineboronic acid": 'OB(C1=C(N=CC=C2)C2=CC=C1)O',
  "9-(2,5-Dimethylphenyl)-10-methylacridinium Perchlorate": 'C[N+]1=C2C=CC=CC2=C(C3=CC(C)=CC=C3C)C4=C1C=CC=C4.O=Cl(=O)([O-])=O',
  "9-(2,6-Dimethylphenyl)-10-methylacridinium Perchlorate": 'C[N+]1=C2C=CC=CC2=C(C3=C(C)C=CC=C3C)C4=C1C=CC=C4.O=Cl(=O)([O-])=O',
  "9-(2-Biphenylyl)-10-methylacridinium Perchlorate": 'C[N+]1=C2C=CC=CC2=C(C3=CC=CC=C3C4=CC=CC=C4)C5=C1C=CC=C5.O=Cl(=O)([O-])=O',
  "9-Azajulolidine": 'N1(CCC2)CCCC3=CN=CC2=C31',
  "9H,9H-Triacontafluoro-8,10-heptadecanedione": 'O=C(CC(C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F)=O)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F',
  "9-Mesityl-10-methylacridinium Perchlorate": 'C[N+]1=C2C=CC=CC2=C(C3=C(C)C=C(C)C=C3C)C4=C1C=CC=C4.O=Cl(=O)([O-])=O',
  "9-Mesityl-2,7,10-trimethylacridinium Perchlorate": 'C[N+]1=C2C=CC(C)=CC2=C(C3=C(C)C=C(C)C=C3C)C4=C1C=CC(C)=C4.O=Cl(=O)([O-])=O',
  "9-Methyl-3,4-dihydro-2H-pyrido[1,2-a]pyrimidin-2-one": 'O=C1N=C(C(C)=CC=C2)N2CC1',
  "Acenaphthylene": 'C12=CC=CC3=C1C(C=C2)=CC=C3',
  "acetic acid": 'CC(O)=O',
  "Acetic Acid (1R,2S)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenylpropyl Ester": 'CC(O[C@H](C1=CC=CC=C1)[C@@H](N(CC2=CC=CC=C2)S(=O)(C(C(C)=CC(C)=C3)=C3C)=O)C)=O',
  "Acetic Acid (1S,2R)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenylpropyl Ester": 'CC(O[C@@H](C1=CC=CC=C1)[C@H](N(CC2=CC=CC=C2)S(=O)(C(C(C)=CC(C)=C3)=C3C)=O)C)=O',
  "acetone": 'CC(C)=O',
  "acetonitrile": 'CC#N',
  "Acetylacetone": 'CC(CC(C)=O)=O',
  "AlkylFluor": 'FC(N(C=C1)C2=C(C(C)C)C=CC=C2C(C)C)=[N+]1C(C(C(C)C)=CC=C3)=C3C(C)C.F[B-](F)(F)F',
  "Allyl(chloromethyl)dimethylsilane": 'C[Si](CCl)(CC=C)C',
  "Allyl(tert-butyl)dimethylsilane": 'C[Si](C(C)(C)C)(CC=C)C',
  "Allylchlorodimethylsilane": 'C[Si](Cl)(CC=C)C',
  "Allylmagnesium Bromide": 'Br[Mg]CC=C',
  "Allylmagnesium Chloride": 'Cl[Mg]CC=C',
  "Allyloxytrimethylsilane": 'C[Si](C)(OCC=C)C',
  "Allylpalladium(II) Chloride": '[Pd+]CC=C.[Cl-]',
  "Allyltriethoxysilane": 'CCO[Si](OCC)(CC=C)OCC',
  "Allyltriethylsilane": 'CC[Si](CC)(CC=C)CC',
  "Allyltriisopropylsilane": 'CC([Si](C(C)C)(CC=C)C(C)C)C',
  "Allyltrimethoxysilane": 'CO[Si](OC)(CC=C)OC',
  "Allyltrimethylsilane": 'C[Si](C)(CC=C)C',
  "Allyltriphenyltin": 'C=CC[Sn](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Allyltris(trimethylsilyloxy)silane": 'C[Si](O[Si](O[Si](C)(C)C)(CC=C)O[Si](C)(C)C)(C)C',
  "aluminium bromide": '[Al+3].[Br-].[Br-].[Br-]',
  "Aluminium chloride": 'Cl[Al](Cl)Cl',
  "Aluminium hydroxide": '[OH-].[Al+3].[OH-].[OH-]',
  "aluminium iodide": '[Al+3].[I-].[I-].[I-]',
  "Aluminum Bromide Anhydrous": '[Al+3].[Br-].[Br-].[Br-]',
  "Aluminum Glycinate": 'NCC([O-])=O.NCC([O-])=O.NCC([O-])=O.[Al+3]',
  "Aluminum Isopropoxide": 'CC([O-])C.CC([O-])C.CC([O-])C.[Al+3]',
  "Aluminum(III) Bromide": '[Al+3].[Br-].[Br-].[Br-]',
  "Aluminum(III) Chloride": '[Al+3].[Cl-].[Cl-].[Cl-]',
  "Aminomethanesulfonic Acid": 'O=S(CN)(O)=O',
  "Ammonia": 'N',
  "ammonium actetate": 'CC([O-])=O.[NH4+]',
  "ammonium bromide": '[NH4+].[Br-]',
  "ammonium carbamate": 'NC([O-])=O.[NH4+]',
  "Ammonium Cerium(IV) Nitrate": '[O-][N+]([O-])=O.[O-][N+]([O-])=O.[O-][N+]([O-])=O.[O-][N+]([O-])=O.[O-][N+]([O-])=O.[NH4+].[Ce+4]',
  "ammonium chloride": '[NH4+].[Cl-]',
  "Ammonium dichromate": 'O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O.[NH4+].[NH4+]',
  "ammonium fluoride": '[NH4+].[F-]',
  "ammonium formate": 'O=C[O-].[NH4+]',
  "ammonium hydrogensulfate": '[O-]S(=O)(O)=O.[NH4+]',
  "Ammonium hydroxide": '[NH4+].[OH-]',
  "Ammonium phosphomolybdate hydrate": 'O=[Mo](OP(O)(O)=O)([O-])=O.[H]O[H].[NH4+]',
  "Ammonium sulfate": 'O=S([O-])([O-])=O.[NH4+].[NH4+]',
  "ammonium tetrafluoroborate": 'F[B-](F)(F)F.[NH4+]',
  "ammoniumthiosulfate": 'O=S([O-])([O-])=S.[NH4+].[NH4+]',
  "Amyltriethylammonium Bis(trifluoromethanesulfonyl)imide": 'CCC[N+](CCC)(CCC)CCC.FC(F)(F)S([N-]S(C(F)(F)F)(=O)=O)(=O)=O',
  "B(CH3)3": 'CB(C)C',
  "B(OH)3": 'OB(O)O',
  "Barium hydroxide": '[Ba+2].[OH-].[OH-]',
  "Barium hydroxide octahydrate": '[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Ba+2].[OH-].[OH-]',
  "Barium tert-butoxide": 'CC(C)([O-])C.CC(C)([O-])C.[Ba+2]',
  "Bathophenanthroline": 'C1(C2=CC=CC=C2)=CC=NC3=C4N=CC=C(C5=CC=CC=C5)C4=CC=C13',
  "Bathophenanthrolinedisulfonic Acid Disodium Salt Hydrate": 'O=S(C1=NC2=C3N=CC=C(C4=CC=CC=C4)C3=CC=C2C(C5=CC=CC=C5)=C1S(=O)([O-])=O)([O-])=O.[H]O[H].[Na+].[Na+]',
  "benzene": 'C1=CC=CC=C1',
  "Benzeneruthenium(II) Chloride": '[Ru+2].C1=CC=CC=C1.[Cl-].[Cl-]',
  "Benzenesulfonic Acid Anhydrous": 'O=S(C1=CC=CC=C1)(O)=O',
  "Benzenesulfonic Acid Monohydrate": 'O=S(C1=CC=CC=C1)(O)=O.[H]O[H]',
  "Benzoic Acid": 'O=C(O)C1=CC=CC=C1',
  "Benzoyl peroxide": 'O=C(OOC(C1=CC=CC=C1)=O)C2=CC=CC=C2',
  "Benzoyl(phenyliodonio)(trifluoromethanesulfonyl)methanide": 'O=S([C-](C(C1=CC=CC=C1)=O)[I+]C2=CC=CC=C2)(C(F)(F)F)=O',
  "Benzylcetyldimethylammonium Chloride Hydrate": 'C[N+](CCCCCCCCCCCCCCCC)(CC1=CC=CC=C1)C.[H]O[H].[Cl-]',
  "Benzyldimethyltetradecylammonium Chloride Hydrate": 'CCCCCCCCCCCCCC[N+](C)(CC1=CC=CC=C1)C.[H]O[H].[Cl-]',
  "Benzyldodecyldimethylammonium Bromide": 'C[N+](CCCCCCCCCCCC)(CC1=CC=CC=C1)C.[Br-]',
  "Benzyldodecyldimethylammonium Chloride Dihydrate": 'C[N+](CCCCCCCCCCCC)(CC1=CC=CC=C1)C.[H]O[H].[H]O[H].[Cl-]',
  "Benzylmagnesium Bromide": 'Br[Mg]CC1=CC=CC=C1',
  "Benzylmagnesium Chloride": 'Cl[Mg]CC1=CC=CC=C1',
  "Benzyltributylammonium Bromide": 'CCCC[N+](CCCC)(CC1=CC=CC=C1)CCCC.[Br-]',
  "Benzyltributylammonium Chloride": 'CCCC[N+](CCCC)(CC1=CC=CC=C1)CCCC.[Cl-]',
  "Benzyltriethylammonium Borohydride": 'CC[N+](CC)(CC1=CC=CC=C1)CC.[BH4-]',
  "Benzyltriethylammonium Bromide": 'CC[N+](CC)(CC1=CC=CC=C1)CC.[Br-]',
  "Benzyltriethylammonium Chloride": 'CC[N+](CC)(CC1=CC=CC=C1)CC.[Cl-]',
  "Benzyltriethylammonium Iodide": 'CC[N+](CC)(CC1=CC=CC=C1)CC.[I-]',
  "Benzyltrimethylammonium Bromide": 'C[N+](C)(CC1=CC=CC=C1)C.[Br-]',
  "Benzyltrimethylammonium Chloride": 'C[N+](C)(CC1=CC=CC=C1)C.[Cl-]',
  "Benzyltrimethylammonium Dichloroiodate": 'C[N+](C)(CC1=CC=CC=C1)C.Cl[I-]Cl',
  "Benzyltrimethylammonium Hydroxide": 'C[N+](C)(CC1=CC=CC=C1)C.[OH-]',
  "Benzyltrimethylammonium Tetrachloroiodate": 'C[N+](C)(CC1=CC=CC=C1)C.Cl[I-](Cl)(Cl)Cl',
  "Benzyltrimethylammonium Tribromide": 'C[N+](C)(CC1=CC=CC=C1)C.[Br-].[Br-].[Br-]',
  "Benzyltrimethylsilane": 'C[Si](C)(CC1=CC=CC=C1)C',
  "BERYLLIUM": '[Be]',
  "BERYLLIUM HYDROXIDE": '[Be+2].[OH-].[OH-]',
  "BF3": 'FB(F)F',
  "Bicinchoninic Acid Disodium Salt Hydrate": 'O=C([O-])C1=C(C2=C(C([O-])=O)C3=CC=CC=C3N=C2)C=NC4=CC=CC=C14.[H]O[H].[Na+].[Na+]',
  "Bis(1,3-propanediamine) Copper(II) Dichloride": 'NCCCN.NCCCN.[Cu+2].[Cl-].[Cl-]',
  "Bis(1,5-cyclooctadiene)nickel(0)": '[Ni].C1=C\\CC/C=C\\CC/1.C2=C\\CC/C=C\\CC/2',
  "Bis(1,5-cyclooctadiene)rhodium(I) Tetrafluoroborate": 'F[B-](F)(F)F.[Rh+].C1=C\\CC/C=C\\CC/1.C2=C\\CC/C=C\\CC/2',
  "Bis(2,4,6-trimethylpyridine)bromonium Hexafluorophosphate": 'CC1=CC(C)=C([BrH+])C(C)=N1.CC2=CC(C)=CC(C)=N2.F[P-](F)(F)(F)(F)F',
  "Bis(2,4,6-trimethylpyridine)iodonium Hexafluorophosphate": 'CC1=CC(C)=C([IH+])C(C)=N1.CC2=CC(C)=CC(C)=N2.F[P-](F)(F)(F)(F)F',
  "Bis(2,4-pentanedionato)bis(2-propanolato)titanium(IV)": 'CC(/C=C(C)\\[O-])=O.CC(/C=C(C)\\[O-])=O.[O-]C(C)C.[O-]C(C)C.[Ti+4]',
  "Bis(2,4-pentanedionato)cobalt(II)": 'C/C([O-])=C/C(C)=O.C/C([O-])=C/C(C)=O.[Co+2].O',
  "Bis(2,4-pentanedionato)cobalt(II) Dihydrate": 'C/C([O-])=C/C(C)=O.C/C([O-])=C/C(C)=O.[Co+2].O.O',
  "Bis(2,4-pentanedionato)manganese(II) Dihydrate": 'CC(/C=C([O-])/C)=O.CC(/C=C([O-])/C)=O.[Mn+2].O.O',
  "Bis(2,4-pentanedionato)molybdenum(VI) Dioxide": 'CC(/C=C([O-])/C)=O.C/C([O-])=C/C(C)=O.[Mo+6].[O-2].[O-2]',
  "Bis(2,4-pentanedionato)nickel(II) Hydrate": 'O=C(/C=C([O-])/C)C.O=C(/C=C([O-])/C)C.[Ni+2].O',
  "Bis(2,4-pentanedionato)vanadium(IV) Oxide": 'CC(/C=C([O-])/C)=O.[O-]/C(C)=C\\C(C)=O.[V+4].[O-2]',
  "Bis(2-hydroxyethyl)dimethylammonium Chloride": 'C[N+](CCO)(CCO)C.[Cl-]',
  "Bis(4-bromophenyl)iodonium triflate": 'BrC1=CC=C([I+]C2=CC=C(Br)C=C2)C=C1.O=S([O-])(C(F)(F)F)=O',
  "Bis(4-fluorophenyl)iodonium triflate": 'FC1=CC=C([I+]C2=CC=C(F)C=C2)C=C1.O=S([O-])(C(F)(F)F)=O',
  "Bis(4-methoxyphenyl) Selenoxide": 'O=[Se](C1=CC=C(OC)C=C1)C2=CC=C(OC)C=C2',
  "Bis(4-methylphenyl)iodonium triflate": 'CC1=CC=C([I+]C2=CC=C(C)C=C2)C=C1.O=S([O-])(C(F)(F)F)=O',
  "Bis(4-nitrophenyl) Carbonate": 'O=C(OC1=CC=C([N+]([O-])=O)C=C1)OC2=CC=C([N+]([O-])=O)C=C2',
  "Bis(acetato)aqua[(S,S)-4,6-bis(4-isopropyl-2-oxazolin-2-yl)-m-xylene]rhodium": 'CC1=CC(C)=C(C2=N[C@H](CO2)C(C)C)C=C1C3=N[C@@H](C(C)C)CO3.CC([O-])=O.[O-]C(C)=O.[Rh+2].O',
  "Bis(acetonitrile)palladium(II) Dichloride": 'CC#N.CC#N.[Pd+2].[Cl-].[Cl-]',
  "Bis(benzonitrile)palladium(II) Dichloride": 'N#CC1=CC=CC=C1.N#CC2=CC=CC=C2.[Pd+2].[Cl-].[Cl-]',
  "Bis(chloromethyl)dimethylsilane": 'C[Si](CCl)(CCl)C',
  "Bis(dibenzylideneacetone)palladium(0)": 'O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2.O=C(/C=C/C3=CC=CC=C3)/C=C/C4=CC=CC=C4.[Pd]',
  "Pd(dba)2": 'O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2.O=C(/C=C/C3=CC=CC=C3)/C=C/C4=CC=CC=C4.[Pd]',
  "Bis(diphenylphosphino)methane": 'P(CP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4',
  "Bis(hexafluoroacetylacetonato)nickel(II) Hydrate": 'O=C(/C=C(C(F)(F)F)\\[O-])C(F)(F)F.[O-]/C(C(F)(F)F)=C\\C(C(F)(F)F)=O.[Ni+2]',
  "Bis(pentafluorophenyl) Carbonate": 'O=C(OC1=C(F)C(F)=C(F)C(F)=C1F)OC2=C(F)C(F)=C(F)C(F)=C2F',
  "Bis(pentafluorophenyl)dimethylsilane": 'C[Si](C1=C(F)C(F)=C(F)C(F)=C1F)(C2=C(F)C(F)=C(F)C(F)=C2F)C',
  "Bis(pentafluorophenyl)phenylphosphine": 'FC1=C(F)C(F)=C(F)C(F)=C1P(C2=C(F)C(F)=C(F)C(F)=C2F)C3=CC=CC=C3',
  "Bis(pinacolato)diboron": 'B1(OC(C(O1)(C)C)(C)C)B2OC(C(O2)(C)C)(C)C',
  "BPin": 'B1(OC(C(O1)(C)C)(C)C)B2OC(C(O2)(C)C)(C)C',
  "Bis(pyridine)iodonium tetrafluoroborate": '[IH+]C1=NC=CC=C1.F[B-](F)(F)F.C2=NC=CC=C2',
  "Bis(tert-butylcarbonyloxy)iodobenzene": 'IC1=CC=CC(OC(C(C)(C)C)=O)=C1OC(C(C)(C)C)=O',
  "Bis(tetrabutylammonium) Dichromate": 'CCCC[N+](CCCC)(CCCC)CCCC.CCCC[N+](CCCC)(CCCC)CCCC.O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O',
  "Bis(trichlorosilyl)acetylene": 'Cl[Si](C#C[Si](Cl)(Cl)Cl)(Cl)Cl',
  "Bis(tricyclohexylphosphine)nickel(II) Dichloride": 'P(C1CCCCC1)(C2CCCCC2)C3CCCCC3.P(C4CCCCC4)(C5CCCCC5)C6CCCCC6.[Ni+2].[Cl-].[Cl-]',
  "Bis(tricyclohexylphosphine)palladium(II) Dichloride": 'P(C1CCCCC1)(C2CCCCC2)C3CCCCC3.P(C4CCCCC4)(C5CCCCC5)C6CCCCC6.[Pd+2].[Cl-].[Cl-]',
  "Bis(trifluoro-2,4-pentanedionato)cobalt(II) Hydrate": 'C/C([O-])=C/C(C(F)(F)F)=O.C/C([O-])=C/C(C(F)(F)F)=O.[Co+2]',
  "Bis(trifluoromethanesulfonyl)imide": 'O=S(C(F)(F)F)(NS(=O)(C(F)(F)F)=O)=O',
  "Bis(trimethylsilyl) Sulfide": 'C[Si](S[Si](C)(C)C)(C)C',
  "Bis(trimethylsilyl)acetylene": 'C[Si](C#C[Si](C)(C)C)(C)C',
  "Bis(trimethylsilyl)bromomethane": 'BrC([Si](C)(C)C)[Si](C)(C)C',
  "Bis(trimethylsilyl)methane": 'C[Si](C[Si](C)(C)C)(C)C',
  "Bis(trimethylsilyl)methylamine": 'CN([Si](C)(C)C)[Si](C)(C)C',
  "Bis(tri-o-tolylphosphine)palladium(II) Dichloride": 'CC1=CC=CC=C1P(C2=CC=CC=C2C)C3=CC=CC=C3C.CC4=CC=CC=C4P(C5=CC=CC=C5C)C6=CC=CC=C6C.[Pd+2].[Cl-].[Cl-]',
  "Bis(triphenylphosphine)nickel(II) Dichloride": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.[Ni+2].[Cl-].[Cl-]',
  "Bis(triphenylphosphine)palladium(II) Diacetate": 'CC([O-])=O.CC([O-])=O.P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.[Pd+2]',
  "Bis(triphenylphosphine)palladium(II) Dichloride": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.[Pd+2].[Cl-].[Cl-]',
  "Bis(triphenylsilyl) chromate": 'O=[Cr](O[Si](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3)(O[Si](C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)=O',
  "Bis(tri-tert-butylphosphine)palladium(0)": 'CC(P(C(C)(C)C)C(C)(C)C)(C)C.CC(P(C(C)(C)C)C(C)(C)C)(C)C.[Pd]',
  "Bis[1,2-bis(diphenylphosphino)ethane]palladium(0)": 'P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.P(CCP(C5=CC=CC=C5)C6=CC=CC=C6)(C7=CC=CC=C7)C8=CC=CC=C8.[Pd]',
  "Bis[2-(diphenylphosphino)phenyl] Ether": 'C1(OC2=CC=CC=C2P(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1P(C5=CC=CC=C5)C6=CC=CC=C6',
  "Bis[2-(trimethylsilyloxy)ethyl] Ether": 'C[Si](OCCOCCO[Si](C)(C)C)(C)C',
  "Bis[2-carboxyethylgermanium(IV)] Sesquioxide": 'O=[Ge](CCC(O)=O)O[Ge](CCC(O)=O)=O',
  "Bis[3-(trimethoxysilyl)propyl]amine": 'CO[Si](CCCNCCC[Si](OC)(OC)OC)(OC)OC',
  "Bis[di-tert-butyl(4-dimethylaminophenyl)phosphine]palladium(0)": 'CN(C1=CC=C(P(C(C)(C)C)C(C)(C)C)C=C1)C.CN(C2=CC=C(P(C(C)(C)C)C(C)(C)C)C=C2)C.[Pd]',
  "Bis[tris(2,4-pentanedionato)titanium(IV)] Hexachlorotitanate(IV)": 'Cl[Ti-2](Cl)(Cl)(Cl)(Cl)Cl.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.[Ti+4].[Ti+4]',
  "BISMUTH HYDROXIDE": '[Bi+3].[OH-].[OH-].[OH-]',
  "Bismuth Tripotassium Dicitrate": 'OC(C([O-])=O)(CC([O-])=O)CC([O-])=O.OC(C([O-])=O)(CC([O-])=O)CC([O-])=O.[Bi+5].[K+].[K+].[K+]',
  "Bistrifluoroacetamide": 'O=C(C(F)(F)F)NC(C(F)(F)F)=O',
  "Borane - 2-Methylpyridine Complex": 'CC1=NC=CC=C1.B',
  "Borane - 5-Ethyl-2-methylpyridine Complex": 'CC1=NC=C(CC)C=C1.B',
  "Borane - Ammonia Complex": 'B.N',
  "Borane - Diisopropylamine Complex": 'CC(C)NC(C)C.B',
  "Borane - Morpholine Complex": 'O1CCNCC1.B',
  "Borane - Pyridine Complex": 'C1=CN=CC=C1.B',
  "Borane - Tetrahydrofuran Complex": 'B.C1COCC1',
  "Borane - tert-Butylamine Complex": 'NC(C)(C)C.B',
  "Boron Trichloride": 'ClB(Cl)Cl',
  "Boron Trifluoride": 'FB(F)F',
  "Boron Trifluoride - Methanol Reagent": 'FB(F)F.CO',
  "Boron Trifluoride - Propanol Reagent": 'FB(F)F.CCCO',
  "Bromine": 'BrBr',
  "Bromine - 1,4-Dioxane Complex": 'BrBr.O1CCOCC1',
  "Bromo[(2,6-pyridinediyl)bis(3-methyl-1-imidazolyl-2-ylidene)]nickel Bromide": 'CN(C=CN12)C1=[Ni-3](Br)=C3N(C)C=CN3C4=CC=CC2=N4.[Br-]',
  "Bromo[[1,3-bis[(4S,5S)-1-benzoyl-4,5-diphenyl-2-imidazolin-2-yl]benzene]palladium(II)]": 'O=C(N1[C@@H](C2=CC=CC=C2)[C@H](C3=CC=CC=C3)N=C1C4=CC(C5=N[C@@H](C6=CC=CC=C6)[C@H](C7=CC=CC=C7)N5C(C8=CC=CC=C8)=O)=CC=C4)C9=CC=CC=C9.[Pd+]Br',
  "Bromoacetic Acid": 'O=C(O)CBr',
  "Bromocholine Bromide": 'BrCC[N+](C)(C)C.[Br-]',
  "Bromodicarbonyl[bis[2-(diisopropylphosphino)ethyl]amine]manganese(I)": '[C]=O.[C]=O.CC(P(CCNCCP(C(C)C)C(C)C)C(C)C)C.[Mn]Br',
  "Bromodimethylsulfonium Bromide": 'C[S+](Br)C.[Br-]',
  "Bromotrichloromethane": 'ClC(Cl)(Br)Cl',
  "Bromotrimethylsilane": 'Br[Si](C)(C)C',
  "Bromotripyrrolidinophosphonium Hexafluorophosphate": 'Br[P+](N1CCCC1)(N2CCCC2)N3CCCC3.F[P-](F)(F)(F)(F)F',
  "Bromotris(dimethylamino)phosphonium Hexafluorophosphate": 'CN([P+](N(C)C)(Br)N(C)C)C.F[P-](F)(F)(F)(F)F',
  "Butylboronic Acid": 'CCCCB(O)O',
  "Butyllithium": 'CCCC[Li]',
  "Butylmagnesium Bromide": 'Br[Mg]CCCC',
  "Butylmagnesium Chloride": 'Cl[Mg]CCCC',
  "Butyltin Trichloride": 'CCCC[Sn](Cl)(Cl)Cl',
  "Cacodylic Acid": 'O=[As](C)(C)O',
  "CADMIUM HYDROXIDE": '[Cd+2].[OH-].[OH-]',
  "CALCIUM BROMIDE HYDRATE": '[H]O[H].[Ca+2].[Br-].[Br-]',
  "calcium carbonate": '[O-]C([O-])=O.[Ca+2]',
  "calcium chloride": '[Ca+2].[Cl-].[Cl-]',
  "calcium hydride": '[Ca+2].[H-].[H-]',
  "Calcium peroxide": '[O-][O-].[Ca+2]',
  "calcium sulfate": 'O=S([O-])([O-])=O.[Ca+2]',
  "Calcium L-Threonate": 'O[C@H]([C@H](CO)O)C([O-])=O.O[C@H]([C@H](CO)O)C([O-])=O.[Ca+2]',
  "Carbamylcholine Chloride": 'C[N+](C)(C)CCOC(N)=O.[Cl-]',
  "Carbon Tetrabromide": 'BrC(Br)(Br)Br',
  "carbon tetrachloride": 'ClC(Cl)(Cl)Cl',
  "Carbon Tetraiodide": 'IC(I)(I)I',
  "carbon tribromide": 'BrC(Br)([H])Br',
  "Carbonyl(dihydrido)tris(triphenylphosphine)ruthenium(II)": '[C]=O.[H][Ru][H].P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9',
  "Carbonylchlorobis(triphenylphosphine)iridium(I)": '[C]=O.[Ir]Cl.P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6',
  "Carbonylhydridotris(triphenylphosphine)iridium(I)": '[C]=O.[Ir][H].P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9',
  "Cerium": '[Ce]',
  "Cerium tetrahydroxide": '[Ce+4].[OH-].[OH-].[OH-].[OH-]',
  "cerium trihydroxide": '[Ce+3].[OH-].[OH-].[OH-]',
  "Cerium(III) Acetate Hydrate": 'CC([O-])=O.CC([O-])=O.CC([O-])=O.[H]O[H].[Ce+3]',
  "CERIUM(III) CHLORIDE HEPTAHYDRATE": '[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Ce+3].[Cl-].[Cl-].[Cl-]',
  "Cerium(III) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Ce+3]',
  "Cesium Acetate": 'CC([O-])=O.[Cs+]',
  "cesium bromide": '[Cs+].[Br-]',
  "Cesium Carbonate": '[O-]C([O-])=O.[Cs+].[Cs+]',
  "Cesium Fluoride": '[Cs+].[F-]',
  "Cesium hydroxide": '[Cs+].[OH-]',
  "Cesium Pivalate": 'CC(C)(C)C([O-])=O.[Cs+]',
  "cesium sulfate": 'O=S([O-])([O-])=O.[Cs+].[Cs+]',
  "Chloramine B Hydrate": 'O=S([N-]Cl)(C1=CC=CC=C1)=O.O.[Na+]',
  "Chloramine T Trihydrate": 'CC1=CC=C(S(=O)([N-]Cl)=O)C=C1.[H]O[H].[H]O[H].[H]O[H].[Na+]',
  "Chloranil": 'O=C(C(Cl)=C1Cl)C(Cl)=C(Cl)C1=O',
  "Chloro(1,5-cyclooctadiene)iridium(I)": '[Ir]Cl.C1=C\\CC/C=C\\CC/1',
  "Chloro(1,5-cyclooctadiene)rhodium(I)": '[Rh]Cl.C1=C\\CC/C=C\\CC/1',
  "Chloro(1,5-hexadiene)rhodium(I)": 'C=CCCC=C.[Rh]Cl',
  "Chloro(2-dicyclohexylphosphino-2′,4′,6′-triisopropyl-1,1′-biphenyl)[2-(2′-amino-1,1′-biphenyl)]palladium(II)": 'Cl[Pd]1(C2=CC=CC=C2C3=CC=CC=C3N1)P(C4CCCCC4)(C5=CC=CC=C5C6=C(C(C)C)C=C(C(C)C)C=C6C(C)C)C7CCCCC7',
  "Chloro(3-chloropropyl)dimethylsilane": 'C[Si](CCCCl)(Cl)C',
  "Chloro(hexyl)dimethylsilane": 'C[Si](CCCCCC)(Cl)C',
  "Chloro(methyl)(phenyl)(vinyl)silane": 'C=C[Si](C)(Cl)C1=CC=CC=C1',
  "Chloro(pentamethylcyclopentadienyl)ruthenium(II)": 'CC1=C([Ru]Cl)C(C)(C)C(C)=C1C',
  "Chloro[(tri-tert-butylphosphine)-2-(2-aminobiphenyl)]palladium(II)": 'NC1([Pd+2])=CC=CC=C1C2=CC=CC=C2.CC(P(C(C)(C)C)(Cl)C(C)(C)C)(C)C',
  "Chloro[[1,3-bis(2,6-diisopropylphenyl)imidazol-2-ylidene](acetanilide)palladium(II)]": 'CC(NC1=CC=CC=C1)=O.CC(C2=C(N(C=CN3C4=C(C(C)C)C=CC=C4C(C)C)C3=[Pd-]Cl)C(C(C)C)=CC=C2)C',
  "Chloro[[1,3-bis(2,6-diisopropylphenyl)imidazol-2-ylidene](N,N-dimethyl-3,5-dimethoxybenzylamine)palladium(II)]": 'COC1=CC(OC)=CC(CN(C)C)=C1.CC(C2=C(N(C=CN3C4=C(C(C)C)C=CC=C4C(C)C)C3=[Pd-]Cl)C(C(C)C)=CC=C2)C',
  "Chloro[[1,3-bis(2,6-diisopropylphenyl)imidazol-2-ylidene](N,N-dimethylbenzylamine)palladium(II)]": 'CN(C)CC1=CC=CC=C1.CC(C2=C(N(C=CN3C4=C(C(C)C)C=CC=C4C(C)C)C3=[Pd-]Cl)C(C(C)C)=CC=C2)C',
  "Chloro[1,3-bis(2,6-diisopropylphenyl)imidazol-2-ylidene]copper(I)": 'CC(C1=C(N(C=CN2C3=C(C(C)C)C=CC=C3C(C)C)C2=[Cu-2]Cl)C(C(C)C)=CC=C1)C',
  "Chloroacetic Acid": 'O=C(O)CCl',
  "Chlorobis(cyclooctene)iridium(I)": '[Ir]Cl.C1=C\\CCCCCC/1.C2=C\\CCCCCC/2',
  "Chlorobis(cyclooctene)rhodium(I)": '[Rh]Cl.C1=C\\CCCCCC/1.C2=C\\CCCCCC/2',
  "Chlorobis(ethylene)iridium(I)": 'Cl[Ir-4]12(CC2)CC1',
  "Chlorobis(ethylene)rhodium(I)": 'Cl[Rh-4]12(CC2)CC1',
  "Chlorobis[dicyclohexyl(phenyl)phosphino](o-tolyl)nickel(II)": 'CC1=CC=CC=C1[Ni-2](P(C2CCCCC2)(C3CCCCC3)C4=CC=CC=C4)(Cl)P(C5CCCCC5)(C6CCCCC6)C7=CC=CC=C7',
  "Chlorocholine Chloride": 'ClCC[N+](C)(C)C.[Cl-]',
  "Chlorodicyclohexylphosphine": 'ClP(C1CCCCC1)C2CCCCC2',
  "Chlorodifluoroacetic Acid": 'O=C(O)C(F)(Cl)F',
  "Chlorodiisopropylphosphine": 'CC(P(Cl)C(C)C)C',
  "Chlorodimethylvinylsilane": 'C=C[Si](C)(Cl)C',
  "Chlorodiphenylphosphine": 'ClP(C1=CC=CC=C1)C2=CC=CC=C2',
  "Chloroform": 'ClC(Cl)Cl',
  "Chloromethyldimethylphenylsilane": 'C[Si](C)(CCl)C1=CC=CC=C1',
  "Chloro-N,N,N',N'-tetramethylformamidinium Hexafluorophosphate": 'C[N+](C)=C(Cl)N(C)C.F[P-](F)(F)(F)(F)F',
  "Chloronitrosyl[N,N'-bis(3,5-di-tert-butylsalicylidene)-1,1,2,2-tetramethylethylenediaminato]ruthenium(IV)": '[O-]C1=C(/C=N/C(C)(C)C(C)(C)/N=C/C2=C([O-])C(C(C)(C)C)=CC(C(C)(C)C)=C2)C=C(C(C)(C)C)C=C1C(C)(C)C.[N-]=O.[Ru+4].[Cl-]',
  "Chloropentamethyldisilane": 'C[Si](C)(C)[Si](C)(Cl)C',
  "Chlorotriethoxysilane": 'CCO[Si](OCC)(Cl)OCC',
  "Chlorotripyrrolidinophosphonium Hexafluorophosphate": 'Cl[P+](N1CCCC1)(N2CCCC2)N3CCCC3.F[P-](F)(F)(F)(F)F',
  "Choline": 'OCC[N+](C)(C)C',
  "Choline Chloride": 'OCC[N+](C)(C)C.[Cl-]',
  "Choline hydroxide": 'OCC[N+](C)(C)C.[OH-]',
  "Chromium": '[Cr]',
  "CHROMIUM (III) HYDROXIDE N-HYDRATE": '[OH-].[Cr+3].[OH-].[OH-]',
  "Chromium(VI) oxide": '[Cr+6].[O-2].[O-2].[O-2]',
  "Cinchonidine": 'O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=CC=C34',
  "Cinchonidine Dihydrochloride": 'O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=CC=C34.[H]Cl.[H]Cl',
  "Cinchonidine Sulfate Dihydrate": 'O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=CC=C34.O=S(O)(O)=O.[H]O[H].[H]O[H]',
  "Cinchonine": 'O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=CC=C34',
  "Cinchonine Hydrochloride Hydrate": 'O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=CC=C34.[H]Cl.[H]O[H]',
  "Citric Acid": 'OC(C(O)=O)(CC(O)=O)CC(O)=O',
  "Cobalt": '[Co]',
  "Cobalt hydroxide": '[Co+2].[OH-].[OH-]',
  "cobalt trihydroxide": '[Co+3].[OH-].[OH-].[OH-]',
  "Copper": '[Cu]',
  "copper (I) bromide": '[Cu+].[Br-]',
  "copper (I) chloride": '[Cu+].[Cl-]',
  "copper (II) bromide": '[Cu+2].[Br-].[Br-]',
  "copper (II) chloride": '[Cu+2].[Cl-].[Cl-]',
  "copper (II) iodide": '[Cu+2].[I-].[I-]',
  "copper fluoride": '[Cu+2].[F-].[F-]',
  "Copper sulfate": 'O=S([O-])([O-])=O.[Cu+2]',
  "Copper(I) 2-Thiophenecarboxylate": 'O=C(C1=CC=CS1)[O-].[Cu+]',
  "Copper(I) Acetate": 'CC([O-])=O.[Cu+]',
  "Copper(I) Cyanide": '[C-]#N.[Cu+]',
  "Copper(I) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.[Cu+]',
  "Copper(I) Trifluoromethanethiolate": '[S-]C(F)(F)F.[Cu+]',
  "copper(I)bromide DMS-complex": 'CSC.[Cu+].[Br-]',
  "Copper(I)iodide": '[Cu+].[I-]',
  "Copper(II) Acetate Monohydrate": 'CC([O-])=O.CC([O-])=O.[H]O[H].[Cu+2]',
  "Copper(II) Tetrafluoroborate": 'F[B-](F)(F)F.F[B-](F)(F)F.[Cu+2]',
  "Copper(II) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Cu+2]',
  "Croconic Acid": 'OC1=C(C(C(C1=O)=O)=O)O',
  "Cumene Hydroperoxide": 'CC(C1=CC=C(OO)C=C1)C',
  "Cupric hydroxide": '[Cu+2].[OH-].[OH-]',
  "Cyanomethylenetributylphosphorane": 'CCCCP(CCCC)(CCCC)=CC#N',
  "Cyanuric Chloride": 'ClC1=NC(Cl)=NC(Cl)=N1',
  "cyclohexane": 'C1CCCCC1',
  "Cyclohexyl(dimethoxy)methylsilane": 'C[Si](OC)(C1CCCCC1)OC',
  "Cyclohexylboronic Acid": 'OB(C1CCCCC1)O',
  "Cyclohexyldiphenylphosphine": 'P(C1=CC=CC=C1)(C2CCCCC2)C3=CC=CC=C3',
  "Cyclohexylmagnesium Bromide": 'Br[Mg]C1CCCCC1',
  "Cyclohexyltrimethylammonium Bis(trifluoromethanesulfonyl)imide": 'C[N+](C)(C)C1CCCCC1.O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O',
  "Cyclooctene": 'C1=C\\CCCCCC/1',
  "Cyclopentadienyl Thallium": '[Tl]C1=CC=CC1',
  "Cyclopentadienylbis(triphenylphosphine)ruthenium(II) Chloride": '[Ru+]C1=CC=CC1.P(C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.P(C5=CC=CC=C5)(C6=CC=CC=C6)C7=CC=CC=C7.[Cl-]',
  "Cyclopentadienyliron Dicarbonyl Dimer": '[C]=O.[C]=O.[Fe]C1=CC=CC1',
  "Cyclopentadienyltitanium(IV) Trichloride": '[Ti+3]C1=CC=CC1.[Cl-].[Cl-].[Cl-]',
  "cyclopentane": 'C1CCCC1',
  "Cyclopentylboronic Acid": 'OB(C1CCCC1)O',
  "Cyclopentylmagnesium Bromide": 'Br[Mg]C1CCCC1',
  "Cyclopentylmethylether": 'COC1CCCC1',
  "Cyclopropylmagnesium Bromide": 'Br[Mg]C1CC1',
  "D-(-)-Leucinol": 'N[C@H](CC(C)C)CO',
  "D-(+)-Threonine": 'N[C@H]([C@@H](O)C)C(O)=O',
  "D-Alanine": 'N[C@H](C)C(O)=O',
  "Decamethylcyclopentasiloxane": 'C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1',
  "Decamethyltetrasiloxane": 'C[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)C',
  "Decyltriethoxysilane": 'CCO[Si](OCC)(CCCCCCCCCC)OCC',
  "Decyltrimethoxysilane": 'CO[Si](OC)(CCCCCCCCCC)OC',
  "Decyltrimethylammonium Bromide": 'C[N+](C)(CCCCCCCCCC)C.[Br-]',
  "Decyltrimethylammonium Chloride": 'C[N+](C)(CCCCCCCCCC)C.[Cl-]',
  "Dehydroacetic Acid": 'CC(C(C(C=C(O1)C)=O)C1=O)=O',
  "Dess-Martin periodinane (DMP)": 'O=C(O1)C2=CC=CC=C2I1(OC(C)=O)(OC(C)=O)OC(C)=O',
  "Di(N-succinimidyl) Carbonate": 'O=C(ON1C(CCC1=O)=O)ON2C(CCC2=O)=O',
  "Di-1-adamantylphosphine": 'C12CC3(PC45CC6CC(C5)CC(C6)C4)CC(C2)CC(C3)C1',
  "Di-2-pyridyl Carbonate": 'O=C(OC1=NC=CC=C1)OC2=NC=CC=C2',
  "Diacetoxydimethylsilane": 'C[Si](OC(C)=O)(OC(C)=O)C',
  "Diallyldimethylammonium Chloride": 'C[N+](CC=C)(CC=C)C.[Cl-]',
  "Diallyldimethylsilane": 'C[Si](CC=C)(CC=C)C',
  "Dibenzo[a,e]cyclooctene": 'C12=CC=CC=C1/C=C\\C3=CC=CC=C3/C=C\\2',
  "Dibenzyl L-Tartrate": 'O[C@H]([C@H](C(OCC1=CC=CC=C1)=O)O)C(OCC2=CC=CC=C2)=O',
  "Dibromoisocyanuric Acid": 'O=C(N(Br)C(N1)=O)N(Br)C1=O',
  "Dibutyltin Bis(trifluoromethanesulfonate)": 'CCCC[Sn+2]CCCC.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O',
  "Dibutyltin Diacetate": 'CCCC[Sn+2]CCCC.CC([O-])=O.CC([O-])=O',
  "Dibutyltin Dichloride": 'CCCC[Sn](Cl)(Cl)CCCC',
  "Dibutyltin Dilaurate": 'CCCC[Sn+2]CCCC.CCCCCCCCCCCC([O-])=O.CCCCCCCCCCCC([O-])=O',
  "Dibutyltin Oxide": 'CCCC[Sn](CCCC)=O',
  "Dichloramine B": 'O=S(N(Cl)Cl)(C1=CC=CC=C1)=O',
  "Dichloramine T": 'CC1=CC=C(S(=O)([N-]Cl)=O)C=C1.[Na+]',
  "Dichloro(1,10-phenanthroline)copper(II)": 'Cl[Cu]Cl.C12=CC=C3C=CC=NC3=C1N=CC=C2',
  "Dichloro(diethylamino)phosphine": 'CCN(P(Cl)Cl)CC',
  "Dichloro(methyl)(3,3,3-trifluoropropyl)silane": 'FC(F)(F)CC[Si](Cl)(Cl)C',
  "Dichloro(methyl)-n-octylsilane": 'CCCCCCCC[Si](Cl)(Cl)C',
  "Dichloro(N,N,N',N'-tetramethylethylenediamine)zinc(II)": 'CN(C)CCN(C)C.Cl[Zn]Cl',
  "Dichloro(p-cymene)ruthenium(II)": 'CC1=CC=C(C(C)C)C=C1.Cl[Ru]Cl',
  "Dichloro[9,9-dimethyl-4,5-bis(diphenylphosphino)xanthene]palladium(II)": 'CC1(C)C2=C(OC3=C1C=CC=C3P(C4=CC=CC=C4)C5=CC=CC=C5)C(P(C6=CC=CC=C6)C7=CC=CC=C7)=CC=C2.Cl[Pd]Cl',
  "Dichloroacetic Acid": 'O=C(O)C(Cl)Cl',
  "Dichlorocyclohexylmethylsilane": 'C[Si](Cl)(Cl)C1CCCCC1',
  "Dichlorodecylmethylsilane": 'C[Si](Cl)(Cl)CCCCCCCCCC',
  "Dichlorododecylmethylsilane": 'C[Si](Cl)(Cl)CCCCCCCCCCCC',
  "Dichlorohexylmethylsilane": 'C[Si](Cl)(Cl)CCCCCC',
  "dichloromethane": 'ClCCl',
  "Dichloromethylvinylsilane": 'C=C[Si](Cl)(Cl)C',
  "Dichlorophenylphosphine": 'ClP(Cl)C1=CC=CC=C1',
  "Dichlorotitanium Diisopropoxide": 'Cl[Ti+2]Cl.CC([O-])C.CC([O-])C',
  "Dicobalt Octacarbonyl": '[CH-]=O.[CH-]=O.[C-]#[O+].[C-]#[O+].[C-]#[O+].[C-]#[O+].[C-]#[O+].[C-]#[O+].[Co].[Co+2]',
  "Dicumyl peroxide": 'CC(OOC(C)(C1=CC=CC=C1)C)(C2=CC=CC=C2)C',
  "Dicyclohexyl(2,4,6-triisopropylphenyl)phosphine": 'CC(C1=C(P(C2CCCCC2)C3CCCCC3)C(C(C)C)=CC(C(C)C)=C1)C',
  "Dicyclohexyl(trifluoromethanesulfonyloxy)borane": 'O=S(OB(C1CCCCC1)C2CCCCC2)(C(F)(F)F)=O',
  "Dicyclohexylamine Borane": 'C1(NC2CCCCC2)CCCCC1.B',
  "Dicyclohexylphenylphosphine": 'P(C1CCCCC1)(C2CCCCC2)C3=CC=CC=C3',
  "Dicyclopentadiene": 'C12C=CCC1C3C=CC2C3',
  "Dicyclopentyl(dimethoxy)silane": 'CO[Si](C1CCCC1)(C2CCCC2)OC',
  "Didecyldimethylammonium Bromide": 'C[N+](CCCCCCCCCC)(CCCCCCCCCC)C.[Br-]',
  "Didodecyldimethylammonium Chloride": 'C[N+](CCCCCCCCCCCC)(CCCCCCCCCCCC)C.[Cl-]',
  "Diethoxy(methyl)phenylsilane": 'C[Si](OCC)(OCC)C1=CC=CC=C1',
  "Diethoxydimethylsilane": 'C[Si](OCC)(OCC)C',
  "Diethoxydiphenylsilane": 'CCO[Si](C1=CC=CC=C1)(OCC)C2=CC=CC=C2',
  "Diethoxymethylsilane": 'C[SiH](OCC)OCC',
  "Diethoxymethylvinylsilane": 'C=C[Si](OCC)(OCC)C',
  "Diethyl (Bromodifluoromethyl)phosphonate": 'FC(Br)(P(OCC)(OCC)=O)F',
  "Diethyl 1,4-Dihydro-2,6-dimethyl-3,5-pyridinedicarboxylate": 'O=C(C1=C(C)NC(C)=C(C(OCC)=O)C1)OCC',
  "Diethyl 2,2':6',2''-Terpyridine-4'-phosphonate": 'O=P(C1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1)(OCC)OCC',
  "Diethyl Cyanophosphonate": 'N#CP(OCC)(OCC)=O',
  "diethyl ether": 'CCOCC',
  "Et2O": 'CCOCC',
  "Diethyl D-(-)-Tartrate": 'O[C@@H]([C@@H](C(OCC)=O)O)C(OCC)=O',
  "Diethyl L-(+)-Tartrate": 'O[C@H]([C@H](C(OCC)=O)O)C(OCC)=O',
  "Diethyl(2-methoxyethyl)methylammonium Bis(fluorosulfonyl)imide": 'FC(F)(F)S(=O)([N-]S(=O)(C(F)(F)F)=O)=O.C[N+](CC)(CC)CCOC',
  "Diethyl(isopropyl)silanol": 'O[Si](CC)(CC)C(C)C',
  "Diethylamine": 'CCNCC',
  "NEt2": 'CCNCC',
  "Diethyldimethylammonium hydroxide": 'C[N+](CC)(CC)C.[OH-]',
  "Diethylenetriamine": 'NCCNCCN',
  "Diethylphenylphosphine": 'CCP(CC)C1=CC=CC=C1',
  "Difluoroacetic Acid": 'O=C(O)C(F)F',
  "Difluoroacetic Anhydride": 'O=C(OC(C(F)F)=O)C(F)F',
  "Difluoromethyl 2-Pyridyl Sulfone": 'O=S(C1=NC=CC=C1)(C(F)F)=O',
  "Difluoromethyl Phenyl Sulfide": 'FC(SC1=CC=CC=C1)F',
  "Difluoromethyl Phenyl Sulfone": 'O=S(C1=CC=CC=C1)(C(F)F)=O',
  "Dihydrogen hexahydroxyplatinate": 'O[Pt-2](O)(O)(O)(O)O.[H+].[H+]',
  "Diisobutyldimethoxysilane": 'CO[Si](CC(C)C)(CC(C)C)OC',
  "Diisopropyl D-(-)-Tartrate": 'O[C@@H]([C@@H](C(OC(C)C)=O)O)C(OC(C)C)=O',
  "Diisopropyl L-(+)-Tartrate": 'O[C@H]([C@H](C(OC(C)C)=O)O)C(OC(C)C)=O',
  "Diisopropylamine": 'CC(NC(C)C)C',
  "DIPA": 'CC(NC(C)C)C',
  "Dilauryldimethylammonium Bromide": 'C[N+](CCCCCCCCCCCC)(CCCCCCCCCCCC)C.[Br-]',
  "Dilithium Tetrachlorocopper(II)": 'Cl[Cu-](Cl)(Cl)Cl.Cl[Cu-](Cl)(Cl)Cl.[Li+].[Li+]',
  "Dimesitylammonium Pentafluorobenzenesulfonate": 'CC1=CC(C)=CC(C)=C1[NH2+]C2=C(C)C=C(C)C=C2C.O=S(C3=C(F)C(F)=C(F)C(F)=C3F)([O-])=O',
  "Dimesitylfluoroborane": 'CC1=CC(C)=CC(C)=C1B(F)C2=C(C)C=C(C)C=C2C',
  "Dimethoxy(methyl)(3,3,3-trifluoropropyl)silane": 'FC(F)(F)CC[Si](OC)(OC)C',
  "Dimethoxy(methyl)silane": 'C[SiH](OC)OC',
  "Dimethoxydimethylsilane": 'C[Si](OC)(OC)C',
  "Dimethoxydiphenylsilane": 'CO[Si](C1=CC=CC=C1)(OC)C2=CC=CC=C2',
  "Dimethoxydi-p-tolylsilane": 'CC1=CC=C([Si](OC)(OC)C2=CC=C(C)C=C2)C=C1',
  "Dimethoxymethylvinylsilane": 'C=C[Si](OC)(OC)C',
  "Dimethyl 2,2'-Bipyridine-4,4'-dicarboxylate": 'O=C(C1=CC(C2=NC=CC(C(OC)=O)=C2)=NC=C1)OC',
  "Dimethyl 2,2'-Bipyridine-6,6'-dicarboxylate": 'O=C(C1=CC=CC(C2=NC(C(OC)=O)=CC=C2)=N1)OC',
  "Dimethyl Sulfide Borane": 'CSC.B',
  "Dimethyl D-(-)-Tartrate": 'O[C@@H]([C@@H](C(OC)=O)O)C(OC)=O',
  "Dimethyl L-(+)-Tartrate": 'O[C@H]([C@H](C(OC)=O)O)C(OC)=O',
  "Dimethylamin": 'CNC',
  "NMe2": 'CNC',
  "Dimethylamine Borane": 'CNC.B',
  "Dimethylbis(phenylethynyl)silane": 'C[Si](C#CC1=CC=CC=C1)(C)C#CC2=CC=CC=C2',
  "Dimethyldimyristylammonium Bromide": 'CCCCCCCCCCCCCC[N+](C)(C)CCCCCCCCCCCCCC.[Br-]',
  "Dimethyldioctadecylammonium Bromide": 'CCCCCCCCCCCCCCCCCC[N+](C)(C)CCCCCCCCCCCCCCCCCC.[Br-]',
  "Dimethyldioctadecylammonium Iodide": 'CCCCCCCCCCCCCCCCCC[N+](C)(C)CCCCCCCCCCCCCCCCCC.[I-]',
  "Dimethyldioctylammonium Bromide": 'CCCCCCCC[N+](C)(C)CCCCCCCC.[Br-]',
  "Dimethyldipalmitylammonium Bromide": 'CCCCCCCCCCCCCCCC[N+](C)(C)CCCCCCCCCCCCCCCC.[Br-]',
  "Dimethyldiphenylsilane": 'C[Si](C1=CC=CC=C1)(C)C2=CC=CC=C2',
  "Dimethyldivinylsilane": 'C=C[Si](C)(C)C=C',
  "Dimethylformamide": 'CN(C)C=O',
  "DMF": 'CN(C)C=O',
  "Dimethylphenylphosphine Borane": 'CP(C)C1=CC=CC=C1.B',
  "Dimethylphenylsilane": 'C[SiH](C)C1=CC=CC=C1',
  "Dimethylthiophosphinoyl Chloride": 'ClP(C)(C)=S',
  "Dimethyltin Dichloride": 'C[Sn](Cl)(Cl)C',
  "Dimethyltin Oxide": 'C[Sn](C)=O',
  "Dimethyltitanocene": 'C[Ti]C.c1cccc1.c2cccc2',
  "Di-n-octyltin Oxide": 'CCCCCCCC[Sn](CCCCCCCC)=O',
  "Diphenyl-2-pyridylphosphine": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=NC=CC=C3',
  "Diphenylammonium Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.C1([NH2+]C2=CC=CC=C2)=CC=CC=C1',
  "Diphenylbis(phenylethynyl)silane": 'C1(C#C[Si](C2=CC=CC=C2)(C3=CC=CC=C3)C#CC4=CC=CC=C4)=CC=CC=C1',
  "Diphenyldichlorogermane": 'Cl[Ge](C1=CC=CC=C1)(C2=CC=CC=C2)Cl',
  "Diphenyliodonium chloride": 'C1([I+]C2=CC=CC=C2)=CC=CC=C1.[Cl-]',
  "Diphenylphosphine Oxide": 'O=P(C1=CC=CC=C1)C2=CC=CC=C2',
  "Diphenylphosphinic Chloride": 'O=P(C1=CC=CC=C1)(C2=CC=CC=C2)Cl',
  "Diphenylphosphoryl Azide": 'O=P(C1=CC=CC=C1)(N=[N+]=[N-])C2=CC=CC=C2',
  "Diphenylpropylphosphine": 'CCCP(C1=CC=CC=C1)C2=CC=CC=C2',
  "Diphenylsilane": 'C1([SiH2]C2=CC=CC=C2)=CC=CC=C1',
  "Diphenylsilanediol": 'O[Si](C1=CC=CC=C1)(C2=CC=CC=C2)O',
  "Diphosphoryl Chloride": 'O=P(OP(O)(O)=O)(Cl)O',
  "Dipivaloylmethane": 'CC(C)(C)C(CC(C(C)(C)C)=O)=O',
  "Dipotassium (R)-1,1'-Binaphthyl-2,2'-disulfonate": 'O=S(C1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3S(=O)([O-])=O)([O-])=O.[K+].[K+]',
  "Dipyrido[3,2-a:2',3'-c]phenazine": 'C12=CC=CN=C1C3=NC=CC=C3C4=NC5=CC=CC=C5N=C42',
  "Di-sec-butylmagnesium - Lithium Chloride": 'CCC([Mg]C(C)CC)C.[Li+].[Cl-]',
  "Di-tert-butyl 1,4-Dihydro-2,6-dimethyl-3,5-pyridinedicarboxylate": 'O=C(C1=C(C)NC(C)=C(C(OC(C)(C)C)=O)C1)OC(C)(C)C',
  "Di-tert-butyl Peroxide": 'CC(OOC(C)(C)C)(C)C',
  "DTBP": 'CC(OOC(C)(C)C)(C)C',
  "Di-tert-butyl L-(+)-Tartrate": 'O[C@H]([C@H](C(OC(C)(C)C)=O)O)C(OC(C)(C)C)=O',
  "Di-tert-butylchlorophosphine": 'ClP(C(C)(C)C)C(C)(C)C',
  "Di-tert-butylmethylphosphonium Tetraphenylborate": 'C[PH+](C(C)(C)C)C(C)(C)C.C1([B-](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1',
  "Di-tert-butylphenylphosphine": 'CC(P(C(C)(C)C)C1=CC=CC=C1)(C)C',
  "Di-tert-Butylsilane": 'CC([SiH2]C(C)(C)C)(C)C',
  "DL-Dithiothreitol": 'SC[C@H]([C@@H](CO)O)S',
  "DL-Lactic Acid": 'OC(C)C(O)=O',
  "DL-Malic Acid": 'OC(CC(O)=O)C(O)=O',
  "DL-α-Lipoic Acid": 'O=C(CCCCC1SSCC1)O',
  "D-Methionine": 'N[C@H](CCSC)C(O)=O',
  "DMSO": 'CS(C)=O',
  "Dodecamethylcyclohexasiloxane": 'C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1',
  "Dodecamethylpentasiloxane": 'C[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)C',
  "Dodecyltriethoxysilane": 'CCO[Si](OCC)(CCCCCCCCCCCC)OCC',
  "Dodecyltrimethoxysilane": 'CO[Si](OC)(CCCCCCCCCCCC)OC',
  "Dodecyltrimethylammonium Bromide": 'C[N+](C)(CCCCCCCCCCCC)C.[Br-]',
  "Dodecyltrimethylammonium Chloride": 'C[N+](C)(CCCCCCCCCCCC)C.[Cl-]',
  "D-Phenylalanine": 'N[C@H](CC1=CC=CC=C1)C(O)=O',
  "D-Pipecolic Acid": 'O=C(O)C1CCCCN1',
  "D-Proline": 'O=C(O)[C@@H]1NCCC1',
  "D-Tryptophan": 'N[C@H](CC1=CNC2=C1C=CC=C2)C(O)=O',
  "ethanol": 'OCC',
  "EtOH": 'OCC',
  "Ethoxydiphenylphosphine": 'CCOP(C1=CC=CC=C1)C2=CC=CC=C2',
  "Ethoxytriethylsilane": 'CC[Si](CC)(OCC)CC',
  "Ethyl (11bR)-4-Amino-2,6-bis(3,5-di-tert-butylphenyl)-4,5-dihydro-3H-cyclohepta[1,2-a:7,6-a']dinaphthalene-4-carboxylate": 'O=C(C1(N)CC2=C(C3=CC(C(C)(C)C)=CC(C(C)(C)C)=C3)C=C4C=CC=CC4=C2C5=C6C=CC=CC6=CC(C7=CC(C(C)(C)C)=CC(C(C)(C)C)=C7)=C5C1)OCC',
  "Ethyl 1-Hydroxy-1H-1,2,3-triazole-4-carboxylate": 'O=C(C1=CN(O)N=N1)OCC',
  "ethyl acetate": 'CC(OCC)=O',
  "Ethyl Chloroglyoxylate": 'O=C(Cl)C(OCC)=O',
  "Ethyl chlorooxoacetate": 'O=C(OCC)C(Cl)=O',
  "Ethyl Cyano(hydroxyimino)acetate": 'O=C(OCC)/C(C#N)=N/O',
  "Ethylamine": 'NCC',
  "Ethylamine Hydrochloride": 'NCC.[H]Cl',
  "Ethylboronic Acid": 'CCB(O)O',
  "Ethyldiphenylphosphine": 'CCP(C1=CC=CC=C1)C2=CC=CC=C2',
  "Ethylene diamine": 'NCCN',
  "Ethylenediaminetetraacetic Acid": 'O=C(CN(CCN(CC(O)=O)CC(O)=O)CC(O)=O)O',
  "Ethylenedithiobis(trimethylsilane)": 'C[Si](C)(C)SCCS[Si](C)(C)C',
  "Ethyllithium": '[Li]CC',
  "Ethylmagnesium Bromide": 'Br[Mg]CC',
  "Ethylmagnesium Chloride": 'Cl[Mg]CC',
  "Ethyltrimethylammonium Iodide": 'C[N+](C)(CC)C.[I-]',
  "Europium": '[Eu]',
  "Fluoro-N,N,N',N'-tetramethylformamidinium Hexafluorophosphate": 'C[N+](C)=C(F)N(C)C.F[P-](F)(F)(F)(F)F',
  "Folic Acid Hydrate": 'O=C(O)C(NC(C1=CC=C(NCC2=NC3=C(N=C2)N=C(N)NC3=O)C=C1)=O)CCC(O)=O.[H]O[H]',
  "Formic acid": 'O=CO',
  "Fumaric Acid": 'O=C(O)/C=C/C(O)=O',
  "Gallic Acid Hydrate": 'OC1=CC(C(O)=O)=CC(O)=C1O.[H]O[H]',
  "Glycolic Acid": 'OCC(O)=O',
  "Gold": '[Au]',
  "Gold hydroxide": '[Au+3].[OH-].[OH-].[OH-]',
  "Hafnium": '[Hf]',
  "Hafnium(IV) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Hf+4]',
  "Heptafluorobutyric Anhydride": 'O=C(OC(C(F)(F)C(F)(F)C(F)(F)F)=O)C(F)(F)C(F)(F)C(F)(F)F',
  "Heptafluorobutyryl Chloride": 'O=C(Cl)C(F)(F)C(F)(F)C(F)(F)F',
  "Heptylmagnesium Bromide": 'Br[Mg]CCCCCCC',
  "Hexachlorodisilane": 'Cl[Si](Cl)(Cl)[Si](Cl)(Cl)Cl',
  "Hexadecamethylcyclooctasiloxane": 'C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1',
  "Hexadecyltrimethoxysilane": 'CO[Si](OC)(CCCCCCCCCCCCCCCC)OC',
  "Hexadecyltrimethylammonium Bromide": 'C[N+](C)(CCCCCCCCCCCCCCCC)C.[Br-]',
  "Hexadecyltrimethylammonium Chloride": 'C[N+](C)(CCCCCCCCCCCCCCCC)C.[Cl-]',
  "Hexadecyltrimethylammonium hydroxide": 'C[N+](C)(CCCCCCCCCCCCCCCC)C.[OH-]',
  "Hexadecyltrimethylammonium Hydroxide": 'C[N+](C)(CCCCCCCCCCCCCCCC)C.[OH-]',
  "Hexafluoroacetylacetone": 'O=C(C(F)(F)C(CF)=O)C(F)(F)F',
  "Hexamethonium hydroxide": 'C[N+](CCCCCC[N+](C)(C)C)(C)C.[OH-].[OH-]',
  "Hexamethylbenzene": 'CC1=C(C)C(C)=C(C)C(C)=C1C',
  "Hexamethylcyclotrisiloxane": 'C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O1',
  "Hexamethyldisilane": 'C[Si](C)(C)[Si](C)(C)C',
  "Hexamethyldisiloxane": 'C[Si](C)(C)O[Si](C)(C)C',
  "Hexamethylditin": 'C[Sn](C)(C)[Sn](C)(C)C',
  "Hexamethylenetetramine": 'N12CN3CN(C2)CN(C3)C1',
  "Hexaphenylcyclotrisiloxane": 'C1([Si]2(C3=CC=CC=C3)O[Si](C4=CC=CC=C4)(C5=CC=CC=C5)O[Si](C6=CC=CC=C6)(C7=CC=CC=C7)O2)=CC=CC=C1',
  "Hexaphenyldisilane": 'C1([Si](C2=CC=CC=C2)(C3=CC=CC=C3)[Si](C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC=C1',
  "Hexaphenyldisiloxane": 'C1([Si](C2=CC=CC=C2)(C3=CC=CC=C3)O[Si](C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC=C1',
  "Hexylboronic Acid": 'CCCCCCB(O)O',
  "Hexyllithium": '[Li]CCCCCC',
  "Hexylmagnesium Bromide": 'Br[Mg]CCCCCC',
  "Hexyltriethoxysilane": 'CCO[Si](OCC)(CCCCCC)OCC',
  "Hexyltrimethoxysilane": 'CO[Si](OC)(CCCCCC)OC',
  "Hexyltrimethylammonium Bromide": 'C[N+](C)(CCCCCC)C.[Br-]',
  "Hydriodic Acid": '[H]I',
  "HI": '[H]I',
  "Hydrochloric Acid": '[H]Cl',
  "HCl": '[H]Cl',
  "Hydrogen Bromide": '[H]Br',
  "Hydrogen Chloride": '[H]Cl',
  "Hydrogen peroxide": '[H]OO[H]',
  "H2O2": '[H]OO[H]',
  "Hydroquinidine Hydrochloride": 'O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)CC)C3=CC=NC4=CC=C(OC)C=C34.[H]Cl',
  "Hydroxylamine-O-sulfonic Acid": 'NOS(O)(=O)=O',
  "I0801 (S)-4-Isopropyl-3-(1-naphthylmethyl)-2,5,5-triphenyl-1,3,2-oxazaborolidine (ca. 6% in Toluene, ca. 0.1mol/L)": 'CC([C@@H]1N(CC2=C3C=CC=CC3=CC=C2)B(C4=CC=CC=C4)OC1(C5=CC=CC=C5)C6=CC=CC=C6)C',
  "IF5-Pyridine-HF": '[H]F.FI(F)(F)(F)F.C1=CN=CC=C1',
  "Imidazole": 'C1=NC=CN1',
  "Imidazolium dichromate": 'O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O.C1=[NH+]C=CN1.C2=[NH+]C=CN2',
  "Indan": 'C12=C(CCC2)C=CC=C1',
  "Indene": 'C12=C(CC=C2)C=CC=C1',
  "INDIUM HYDROXIDE": '[In+3].[OH-].[OH-].[OH-]',
  "Indoline": 'C12=C(NCC2)C=CC=C1',
  "Iodine": 'II',
  "Iodobenzene Diacetate": 'IC1=CC=CC=C1.CC(O)=O.CC(O)=O',
  "Iodomesitylene Diacetate": 'CC1=C(I)C(C)=CC(C)=C1.CC(O)=O.CC(O)=O',
  "Iodosodilactone": 'O=C1OI2C3=C1C=CC=C3C(O2)=O',
  "Iridium": '[Ir]',
  "Iridium(III) Chloride Hydrate": '[H]O[H].[Ir+3].[Cl-].[Cl-].[Cl-]',
  "Iron": '[Fe]',
  "Fe": '[Fe]',
  "Iron hydroxide": '[Fe+3].[OH-].[OH-].[OH-]',
  "FeOH": '[Fe+3].[OH-].[OH-].[OH-]',
  "Iron(II) Acetate": 'CC([O-])=O.CC([O-])=O.[Fe+2]',
  "Iron(III) Chloride": '[Fe+3].[Cl-].[Cl-].[Cl-]',
  "FeCl3": '[Fe+3].[Cl-].[Cl-].[Cl-]',
  "Ishikawa's Reagent": 'FC(N(CC)CC)(C(C(F)(F)F)F)F',
  "Isobutylboronic Acid": 'CC(C)CB(O)O',
  "Isobutyllithium": '[Li]CC(C)C',
  "Isobutylmagnesium Bromide": 'Br[Mg]CC(C)C',
  "isopropanpol": 'CC(O)C',
  "iPr": 'CC(O)C',
  "Isopropenyloxytris(trimethylsilyl)silane": 'C[Si]([Si]([Si](C)(C)C)(OC(C)=C)[Si](C)(C)C)(C)C',
  "isopropyl alcohol": 'CC(O)C',
  "Isopropylboronic Acid": 'CC(B(O)O)C',
  "Isopropyldiphenylphosphine": 'CC(P(C1=CC=CC=C1)C2=CC=CC=C2)C',
  "Isopropyllithium": '[Li]C(C)C',
  "Isopropylmagnesium Bromide": 'Br[Mg]C(C)C',
  "Isopropylmagnesium Chloride": 'Cl[Mg]C(C)C',
  "Isopropylmagnesium Chloride - Lithium Chloride": 'Cl[Mg]C(C)C.[Li+].[Cl-]',
  "Isopropylmagnesium chloride lithium chloride complex": '[Mg+]C(C)C.[Li+].[Cl-].[Cl-]',
  "L-(-)-Threonine": 'N[C@@H]([C@H](O)C)C(O)=O',
  "L-(+)-Leucinol": 'N[C@@H](CC(C)C)CO',
  "L-(+)-Tartaric Acid": 'O[C@H]([C@H](C(O)=O)O)C(O)=O',
  "L-Alanine": 'N[C@@H](C)C(O)=O',
  "Lanthanum": '[La]',
  "LANTHANUM HYDROXIDE": '[La+3].[OH-].[OH-].[OH-]',
  "Lanthanum(III) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[La+3]',
  "L-Ascorbic Acid": 'OC([C@H](O1)[C@H](CO)O)=C(O)C1=O',
  "L-Azetidine-2-carboxylic Acid": 'O=C(C1NCC1)O',
  "LEAD HYDROXIDE": '[Pb+2].[OH-].[OH-]',
  "Lead Tetraacetate": 'CC([O-])=O.CC([O-])=O.CC([O-])=O.CC([O-])=O.[Pb+4]',
  "Pb(OAc)4": 'CC([O-])=O.CC([O-])=O.CC([O-])=O.CC([O-])=O.[Pb+4]',
  "Lead(II) Iodide": '[Pb+2].[I-].[I-]',
  "Lithium (E)-4-oxopent-2-en-2-olate": 'C/C([O-])=C\\C(C)=O.[Li+]',
  "Lithium 2,2,6,6-tetramethylpiperidide": 'CC1(C)CCCC(C)(C)[N-]1.[Li+]',
  "Lithium Acetylide Ethylenediamine Complex": 'NCCN.[C-]#[C-].[Li+].[Li+]',
  "Lithium Aluminum Hydride": '[Li+].[Al+3].[H-].[H-].[H-].[H-]',
  "Lithium amide": '[Li+].[NH2-]',
  "Lithium Bis(trimethylsilyl)amide": 'C[Si]([N-][Si](C)(C)C)(C)C.[Li+]',
  "Lithium Borohydride": '[Li+].[BH4-]',
  "LiBH4": '[Li+].[BH4-]',
  "lithium bromid": '[Li+].[Br-]',
  "LiBr": '[Li+].[Br-]',
  "lithium chloride": '[Li+].[Cl-]',
  "LiCl": '[Li+].[Cl-]',
  "Lithium dicyclohexylamide": 'C1([N-]C2CCCCC2)CCCCC1.[Li+]',
  "Lithium diethylamide": 'CC[N-]CC.[Li+]',
  "Lithium Diisopropylamide": 'CC([N-]C(C)C)C.[Li+]',
  "LDA": 'CC([N-]C(C)C)C.[Li+]',
  "Lithium dimethylamide": 'C[N-]C.[Li+]',
  "Lithium Diphenylphosphide (ca. 0.5mol/L in Tetrahydrofuran)": 'C1([P-]C2=CC=CC=C2)=CC=CC=C1.[Li+]',
  "Lithium ethoxide": 'CC[O-].[Li+]',
  "Lithium hydroxide": '[Li+].[OH-]',
  "LiOH": '[Li+].[OH-]',
  "Lithium isopropoxide": 'CC([O-])C.[Li+]',
  "Lithium methoxide": 'C[O-].[Li+]',
  "LiOMe": 'C[O-].[Li+]',
  "Lithium peroxide": '[O-][O-].[Li+].[Li+]',
  "lithium tetrafluoroborate": 'F[B-](F)(F)F.[Li+]',
  "LiBF4": 'F[B-](F)(F)F.[Li+]',
  "Lithium Triethylborohydride": 'CC[BH-](CC)CC.[Li+]',
  "Lithium triisobutyl(2,2,6,6 tetramethylpiperdino)aluminate": 'CC1(C)CCCC(C)(C)N1[Al-](CC(C)C)(CC(C)C)CC(C)C.[Li+]',
  "Lithium trimethylsilanolate": '[O-][Si](C)(C)C.[Li+]',
  "Lithium Tri-tert-butoxyaluminum Hydride": 'CC(O[Al](OC(C)(C)C)OC(C)(C)C)(C)C.[Li+].[H-]',
  "Lithium tert-butoxide": 'CC(C)([O-])C.[Li+]',
  "Lithium tert-Butoxide": 'CC(C)([O-])C.[Li+]',
  "L-Lactic Acid": 'OC(C)C(O)=O',
  "L-Methionine": 'N[C@@H](CCSC)C(O)=O',
  "L-Phenylalanine": 'N[C@@H](CC1=CC=CC=C1)C(O)=O',
  "L-Pipecolic Acid": 'O=C(O)C1CCCCN1',
  "L-Prolinamide": 'O=C(N)[C@H]1NCCC1',
  "L-Prolinanilide": 'O=C(NC1=CC=CC=C1)[C@H]2NCCC2',
  "L-Proline": 'O=C(O)[C@H]1NCCC1',
  "L-Thioproline": 'O=C(O)[C@H]1NCCC1=S',
  "L-Tryptophan": 'N[C@@H](CC1=CNC2=C1C=CC=C2)C(O)=O',
  "Magnesium bis(diisopropyl)amide": 'CC([N-]C(C)C)C.CC([N-]C(C)C)C.[Mg+2]',
  "Magnesium bis(hexamethyldisilazide)": 'C[Si](C)(C)[N-][Si](C)(C)C.C[Si](C)(C)[N-][Si](C)(C)C.[Mg+2]',
  "magnesium bromide": '[Mg+2].[Br-].[Br-]',
  "MgBr2": '[Mg+2].[Br-].[Br-]',
  "magnesium carbonate": '[O-]C([O-])=O.[Mg+2]',
  "MgCO3": '[O-]C([O-])=O.[Mg+2]',
  "magnesium chloride": '[Mg+2].[Cl-].[Cl-]',
  "MgCl2": '[Mg+2].[Cl-].[Cl-]',
  "Magnesium di-tert-butoxide": 'CC(C)([O-])C.CC(C)([O-])C.[Mg+2]',
  "Magnesium ethoxide": 'CC[O-].CC[O-].[Mg+2]',
  "Mg(OEt)2": 'CC[O-].CC[O-].[Mg+2]',
  "MAGNESIUM HYDROXIDE": '[Mg+2].[OH-].[OH-]',
  "Mg(OH)2": '[Mg+2].[OH-].[OH-]',
  "magnesium iodide": '[Mg+2].[I-].[I-]',
  "MgI2": '[Mg+2].[I-].[I-]',
  "Magnesium methoxide": 'C[O-].C[O-].[Mg+2]',
  "Mg(OMe)2": 'C[O-].C[O-].[Mg+2]',
  "magnesium permanganate": 'O=[Mn](=O)([O-])=O.O=[Mn](=O)([O-])=O.[Mg+2]',
  "Mg(MnO4)2": 'O=[Mn](=O)([O-])=O.O=[Mn](=O)([O-])=O.[Mg+2]',
  "Magnesium permanganate hydrate": 'O=[Mn](=O)([O-])=O.O=[Mn](=O)([O-])=O.[H]O[H].[Mg+2]',
  "Magnesium peroxide": '[O-][O-].[Mg+2]',
  "magnesium sulfate": 'O=S([O-])([O-])=O.[Mg+2]',
  "MgSO4": 'O=S([O-])([O-])=O.[Mg+2]',
  "Maleic Acid": 'O=C(O)/C=C\\C(O)=O',
  "Malonic Acid": 'O=C(O)CC(O)=O',
  "Manganese": '[Mn]',
  "manganese molybdate": 'O=[Mo]([O-])([O-])=O.[Mn+2]',
  "manganese perchlorate": 'O=Cl(=O)([O-])=O.O=Cl(=O)([O-])=O.[Mn+2]',
  "manganese sulfate": 'O=S([O-])([O-])=O.[Mn+2]',
  "MnSO4": 'O=S([O-])([O-])=O.[Mn+2]',
  "Manganese(II) Bis(trifluoromethanesulfonyl)imide": 'FC(F)(S(=O)([N-]S(=O)(C(F)(F)F)=O)=O)F.FC(F)(S(=O)([N-]S(=O)(C(F)(F)F)=O)=O)F.[Mn+2]',
  "Manganese(II) Chloride Tetrahydrate": '[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Mn+2].[Cl-].[Cl-]',
  "meso-Tetraphenylchlorin": 'C1(C2=CC=CC=C2)=C(N3)CCC3=C(C4=CC=CC=C4)C5=NC(C=C5)=C(C6=CC=CC=C6)C(N7)=CC=C7C(C8=CC=CC=C8)=C9CCC1=N9',
  "Methallyltrimethylsilane": 'C[Si](C)(CC(C)=C)C',
  "Methanesulfonic Acid": 'CS(=O)(O)=O',
  "methanol": 'CO',
  "MeOH": 'CO',
  "Methoxydimethyl(phenyl)silane": 'C[Si](C)(OC)C1=CC=CC=C1',
  "Methoxydiphenylphosphine": 'COP(C1=CC=CC=C1)C2=CC=CC=C2',
  "Methoxymethyl(diphenyl)phosphine Oxide": 'COCP(C1=CC=CC=C1)(C2=CC=CC=C2)=O',
  "Methoxymethyltrimethylsilane": 'C[Si](C)(COC)C',
  "Methoxytrimethylsilane": 'C[Si](C)(OC)C',
  "Methyl 2,2':6',2''-Terpyridine-4'-carboxylate": 'O=C(C1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1)OC',
  "Methyl 3-(Trimethylsilyloxy)crotonate": 'C/C(O[Si](C)(C)C)=C/C(OC)=O',
  "Methyl Chlorodifluoroacetate": 'O=C(OC)C(F)(Cl)F',
  "Methyl Chloroglyoxylate": 'O=C(Cl)C(OC)=O',
  "Methyl chlorooxoacetate": 'O=C(OC)C(Cl)=O',
  "Methyl Difluoro(fluorosulfonyl)acetate": 'O=C(OC)C(F)(F)S(=O)(F)=O',
  "Methyl(diphenyl)phosphine Oxide": 'CP(C1=CC=CC=C1)(C2=CC=CC=C2)=O',
  "Methylamine": 'NC',
  "Methylboronic Acid": 'CB(O)O',
  "Methyl-d3-magnesium iodide": '[2H]C([Mg]I)([2H])[2H]',
  "Methyldiphenylphosphine": 'CP(C1=CC=CC=C1)C2=CC=CC=C2',
  "Methyldiphenylsilane": 'C[SiH](C1=CC=CC=C1)C2=CC=CC=C2',
  "methylene chloride": 'ClCCl',
  "Methyllithium lithium bromide": '[Li]C.[Li+].[Br-]',
  "Methylmagnesium Bromide": 'Br[Mg]C',
  "MeMgBr": 'Br[Mg]C',
  "Methylmagnesium chloride": 'Cl[Mg]C',
  "MeMgCl": 'Cl[Mg]C',
  "Methylmagnesium Iodide": 'I[Mg]C',
  "MeMgI": 'I[Mg]C',
  "Methyltri-n-octylammonium Bis(trifluoromethanesulfonyl)imide": 'O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O.C[N+](CCCCCCCC)(CCCCCCCC)CCCCCCCC',
  "Methyltri-n-octylammonium Chloride": 'CCCCCCCC[N+](CCCCCCCC)(C)CCCCCCCC.[Cl-]',
  "Methyltri-n-octylammonium Hydrogen Sulfate": 'CCCCCCCC[N+](CCCCCCCC)(C)CCCCCCCC.O=S(O)([O-])=O',
  "Methyltriphenylarsonium Iodide": 'C[As+](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.[I-]',
  "Molybdenum": '[Mo]',
  "Monobutyltin Oxide": 'CCCC[SnH]=O',
  "Monosodium Bromoisocyanurate Hydrate": 'BrC([N-]C(N1)=O)=NC1=O.[Na+]',
  "Morpholine": 'C1CNCCO1',
  "Morpholinosulfur Trifluoride": 'FS(F)(F)N1CCOCC1',
  "m-Tolylmagnesium Bromide": 'Br[Mg]C1=CC=CC(C)=C1',
  "N-(2-Pyridyl)bis(trifluoromethanesulfonimide)": 'O=S(N(C1=NC=CC=C1)S(=O)(C(F)(F)F)=O)(C(F)(F)F)=O',
  "N-(2-Thiophenesulfonyl)-L-prolinamide": 'O=C(NS(=O)(C1=CC=CS1)=O)[C@H]2NCCC2',
  "N-(9-Deoxy-epi-cinchonidin-9-yl)picolinamide": 'O=C(N[C@H]([C@H]1N2CCC(C1)C(C=C)C2)C3=CC=NC4=CC=CC=C34)C5=NC=CC=C5',
  "N-(9-Deoxy-epi-cinchonin-9-yl)picolinamide": 'O=C(N[C@@H](C1N2CCC(C1)C(C=C)C2)C3=CC=NC4=CC=CC=C34)C5=NC=CC=C5',
  "N-(Trifluoromethylthio)phthalimide": 'O=C(N1SC(F)(F)F)C2=CC=CC=C2C1=O',
  "N,N,N',N'-Tetraethylethylenediamine": 'CCN(CC)CCN(CC)CC',
  "N,N,N',N'-Tetrakis(2-pyridylmethyl)ethylenediamine": 'N(CC1=NC=CC=C1)(CC2=NC=CC=C2)CCN(CC3=NC=CC=C3)CC4=NC=CC=C4',
  "N,N,N',N'-Tetramethyl-1,3-diaminopropane": 'CN(C)CCCN(C)C',
  "N,N,N',N'-Tetramethyl-1,4-diaminobutane": 'CN(C)CCCCN(C)C',
  "N,N,N',N'-Tetramethyl-1,6-diaminohexane": 'CN(C)CCCCCCN(C)C',
  "N,N,N',N'-Tetramethylethylenediamine": 'CN(C)CCN(C)C',
  "N,N,N',N'-Tetramethyl-O-(N-succinimidyl)uronium Hexafluorophosphate": 'O=C(N1[O+]=C(N(C)C)N(C)C)CCC1=O.F[P-](F)(F)(F)(F)F',
  "N,N,N',N'-Tetramethyl-O-(N-succinimidyl)uronium Tetrafluoroborate": 'O=C(N1[O+]=C(N(C)C)N(C)C)CCC1=O.F[B-](F)(F)F',
  "N,N,N',N'-Tetramethyl-S-(1-oxido-2-pyridyl)thiouronium Hexafluorophosphate": '[O-][N+]1=CC=CC=C1[S+]=C(N(C)C)N(C)C.F[P-](F)(F)(F)(F)F',
  "N,N,N',N'-Tetramethyl-S-(1-oxido-2-pyridyl)thiouronium Tetrafluoroborate": '[O-][N+]1=CC=CC=C1[S+]=C(N(C)C)N(C)C.F[B-](F)(F)F',
  "N,N',N''-Trimethyldiethylenetriamine": 'CN(CCNC)CCNC',
  "N,N'-Bis(2-aminobenzal)ethylenediamine": 'NC1=CC=CC=C1/C=N/CC/N=C/C2=CC=CC=C2N',
  "N,N'-Bis(2-pyridylmethyl)-1,2-ethylenediamine Tetrahydrochloride Dihydrate": '[H]Cl.[H]Cl.[H]Cl.[H]Cl.[H]O[H].[H]O[H].C1(CNCCNCC2=NC=CC=C2)=NC=CC=C1',
  "N,N'-Bis(3,5-di-tert-butylsalicylidene)-1,1,2,2-tetramethylethylenediamine": 'CC(/N=C/C1=CC(C(C)(C)C)=CC(C(C)(C)C)=C1O)(C)C(C)(C)/N=C/C2=CC(C(C)(C)C)=CC(C(C)(C)C)=C2O',
  "N,N'-Bis(5-hydroxysalicylidene)ethylenediamine": 'OC1=CC=C(O)C=C1/C=N/CC/N=C/C2=CC(O)=CC=C2O',
  "N,N'-Bis(salicylidene)-1,2-phenylenediamine": 'OC1=CC=CC=C1/C=N/C2=CC=CC=C2/N=C/C3=CC=CC=C3O',
  "N,N'-Bis(salicylidene)-1,2-propanediamine": 'CC(/N=C/C1=CC=CC=C1O)C/N=C/C2=CC=CC=C2O',
  "N,N'-Bis(salicylidene)-1,3-propanediamine": 'OC1=CC=CC=C1/C=N/CCC/N=C/C2=CC=CC=C2O',
  "N,N'-Bis(salicylidene)ethylenediamine": 'OC1=CC=CC=C1/C=N/CC/N=C/C2=CC=CC=C2O',
  "N,N-Dichloro-p-toluenesulfonamide": 'CC1=CC=C(S(=O)(N(Cl)Cl)=O)C=C1',
  "N,N'-Dicyclohexyl-1,2-ethanediamine Hydrate": '[H]O[H].C1(NCCNC2CCCCC2)CCCCC1',
  "N,N'-Dicyclohexylcarbodiimide": 'C1(N=C=NC2CCCCC2)CCCCC1',
  "DCC": 'C1(N=C=NC2CCCCC2)CCCCC1',
  "N,N-Diethylaniline Borane": 'CCN(CC)C1=CC=CC=C1.B',
  "N,N'-Diisopropylcarbodiimide": 'CC(N=C=NC(C)C)C',
  "DIC": 'CC(N=C=NC(C)C)C',
  "N,N-Diisopropylethylamine": 'CCN(C(C)C)C(C)C',
  "DIPEA": 'CCN(C(C)C)C(C)C',
  "N,N-Diisopropylmethylamine": 'CN(C(C)C)C(C)C',
  "N,N'-Dimethylethylenediamine": 'CNCCNC',
  "N,N-Dimethyl-N-(methylsulfanylmethylene)ammonium Iodide": 'CSC=[N+](C)C.[I-]',
  "N,N'-Diphenylethylenediamine": 'C1(NCCNC2=CC=CC=C2)=CC=CC=C1',
  "N,N'-Di-tert-butylcarbodiimide": 'CC(N=C=NC(C)(C)C)(C)C',
  "N,N'-Di-tert-butylethylenediamine": 'CC(NCCNC(C)(C)C)(C)C',
  "N-[(11bS)-4,5-Dihydro-3H-dinaphtho[2,1-c:1',2'-e]azepin-2-yl]trifluoromethanesulfonamide": 'O=S(C(F)(F)F)(NC1=C2CNCC3=CC=C4C=CC=CC4=C3C2=C5C=CC=CC5=C1)=O',
  "N-[(9S)-8α-Cinchonan-9-yl]quinoline-8-sulfonamide": 'O=S(C1=C2N=CC=CC2=CC=C1)(N[C@H]([C@@H]3C[C@H]4[C@H](C[N@@]3CC4)C=C)C5=CC=NC6=CC=CC=C56)=O',
  "N-[2-(Trimethylsilyl)ethoxycarbonyloxy]succinimide": 'O=C(N1OC(OCC[Si](C)(C)C)=O)CCC1=O',
  "N-Benzylcinchonidinium Chloride": 'O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=[N+](CC4=CC=CC=C4)C5=CC=CC=C35.[Cl-]',
  "N-Benzylcinchoninium Chloride": 'O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=[N+](CC4=CC=CC=C4)C5=CC=CC=C35.[Cl-]',
  "N-Benzylquinidinium Chloride": 'O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=[N+](CC4=CC=CC=C4)C5=CC=C(OC)C=C35.[Cl-]',
  "N-Benzylquininium Chloride": 'O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=[N+](CC4=CC=CC=C4)C5=CC=C(OC)C=C35.[Cl-]',
  "N-Bromoacetamide": 'CC(NBr)=O',
  "N-Bromophthalimide": 'O=C(N1Br)C2=CC=CC=C2C1=O',
  "N-Bromosaccharin": 'O=C1N(Br)S(=O)(C2=C1C=CC=C2)=O',
  "N-Bromosuccinimide": 'O=C(N1Br)CCC1=O',
  "NBS": 'O=C(N1Br)CCC1=O',
  "n-Butanol": 'CCCCO',
  "n-Butyllithium solution": '[Li]CCCC',
  "BuLi": '[Li]CCCC',
  "N-Chlorobenzenesulfonamide sodium salt": 'O=S(C1=CC=CC=C1)([N-]Cl)=O.[Na+]',
  "N-Chlorophthalimide": 'O=C(N1Cl)C2=CC=CC=C2C1=O',
  "N-Chlorosaccharin": 'O=C1N(Cl)S(=O)(C2=C1C=CC=C2)=O',
  "N-Chlorosuccinimide": 'O=C(N1Cl)CCC1=O',
  "NCS": 'O=C(N1Cl)CCC1=O',
  "Neodymium": '[Nd]',
  "NEODYMIUM HYDRATE": '[H]O[H].[Nd]',
  "Neodymium(III) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Nd+3]',
  "N-Ethyldiisopropylamine": 'CC(N(CC)C(C)C)C',
  "N-Fluorobenzenesulfonimide": 'FN(S(=O)(C1=CC=CC=C1)=O)S(=O)(C2=CC=CC=C2)=O',
  "N-Fluoro-N'-(chloromethyl)triethylenediamine Bis(tetrafluoroborate)": 'F[N+]1(CC2)CC[N+]2(CCl)CC1.F[B-](F)(F)F.F[B-](F)(F)F',
  "n-hexane": 'CCCCCC',
  "N-Hydroxy-5-norbornene-2,3-dicarboximide": 'O=C(C1C2C3C=CC1C3)N(O)C2=O',
  "N-Hydroxyphthalimide": 'O=C(N1O)C2=CC=CC=C2C1=O',
  "N-Hydroxysuccinimide": 'O=C(N1O)CCC1=O',
  "N-Hydroxysulfosuccinimide Sodium": 'O=C(N1O)C(S(=O)([O-])=O)CC1=O.[Na+]',
  "N-Hydroxytetrachlorophthalimide": 'O=C(N1O)C2=C(Cl)C(Cl)=C(Cl)C(Cl)=C2C1=O',
  "Nickel": '[Ni]',
  "Nickel peroxide": '[O-][O-].[Ni+2]',
  "Nickel(II) Benzenesulfonate Hexahydrate": 'O=S(C1=CC=CC=C1)([O-])=O.O=S(C2=CC=CC=C2)([O-])=O.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Ni+2]',
  "NICKEL(II) HYDROXIDE": '[Ni+2].[OH-].[OH-]',
  "Nickel(II) peroxide hydrate ": '[O-][O-].[H]O[H].[Ni+2]',
  "Nickel(II) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Ni+2]',
  "Nickel(II) p-Toluenesulfonate Hexahydrate": 'CC1=CC=C(S(=O)([O-])=O)C=C1.CC2=CC=C(S(=O)([O-])=O)C=C2.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Ni+2]',
  "Niobium hydroxide oxide": '[Nb+5].[Nb+5].[Nb+5].[OH-].[O-2].[OH-].[O-2].[OH-].[O-2].[OH-].[O-2].[OH-].[O-2]',
  "N-Iodosaccharin": 'O=C1N(I)S(=O)(C2=C1C=CC=C2)=O',
  "N-Iodosuccinimide": 'O=C(N1I)CCC1=O',
  "N'-Isopropylidene-2-nitrobenzenesulfonohydrazide": 'O=S(C1=CC=CC=C1[N+]([O-])=O)(NN=C(C)C)=O',
  "Nitric acid": 'O[N+]([O-])=O',
  "HNO3": 'O[N+]([O-])=O',
  "Nitrilotriacetic Acid": 'O=C(O)CN(CC(O)=O)CC(O)=O',
  "nitromethane": 'C[N+]([O-])=O',
  "MeNO2": 'C[N+]([O-])=O',
  "Nitrosyl tetrafluoroborate": 'F[B-](F)(F)(N=O)F',
  "N-Methylbis(trifluoroacetamide)": 'O=C(C(F)(F)F)N(C)C(C(F)(F)F)=O',
  "N-Methyl-N-(trifluoromethylthio)aniline": 'FC(F)(F)SN(C)C1=CC=CC=C1',
  "n-octanol": 'CCCCCCCCO',
  "n-Octyl p-Toluenesulfonate": 'CC1=CC=C(S(=O)(OCCCCCCCC)=O)C=C1',
  "n-Octylboronic Acid": 'CCCCCCCCB(O)O',
  "n-Octylmagnesium Bromide": 'Br[Mg]CCCCCCCC',
  "n-Octyltrimethylammonium Bromide": 'C[N+](C)(CCCCCCCC)C.[Br-]',
  "n-Octyltrimethylammonium Chloride": 'C[N+](C)(CCCCCCCC)C.[Cl-]',
  "Norbornadiene Rhodium(I) Chloride": 'C12C=CC(C2)C=C1.[Rh+].[Cl-]',
  "N-Phenylbis(trifluoromethanesulfonimide)": 'O=S(N(S(=O)(C(F)(F)F)=O)C1=CC=CC=C1)(C(F)(F)F)=O',
  "n-propanol": 'CCCO',
  "N-Salicylideneaniline": 'OC1=CC=CC=C1/C=N/C2=CC=CC=C2',
  "N-tert-Butyl-5-methylisoxazolium Perchlorate": 'CC1=CC=[N+](C(C)(C)C)O1.O=Cl(=O)([O-])=O',
  "N-tert-Butylbenzenesulfenamide": 'CC(NSC1=CC=CC=C1)(C)C',
  "N-tert-Butylbenzenesulfinimidoyl Chloride": 'CC(/N=S(Cl)/C1=CC=CC=C1)(C)C',
  "N-Trimethylsilylimidazole": 'C[Si](N1C=NC=C1)(C)C',
  "O-(3,4-Dihydro-4-oxo-1,2,3-benzotriazin-3-yl)-N,N,N',N'-tetramethyluronium Tetrafluoroborate": 'CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CC=C2C1=O.F[B-](F)(F)F',
  "O-(6-Chlorobenzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Hexafluorophosphate": 'CN(C)C(N(C)C)=[O+]N1N=NC2=CC=C(Cl)C=C21.F[P-](F)(F)(F)(F)F',
  "O-(6-Chlorobenzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Tetrafluoroborate": 'CN(C)C(N(C)C)=[O+]N1N=NC2=CC=C(Cl)C=C21.F[B-](F)(F)F',
  "O-(7-Azabenzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Hexafluorophosphate": 'CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CN=C21.F[P-](F)(F)(F)(F)F',
  "O-(7-Azabenzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Tetrafluoroborate": 'CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CN=C21.F[B-](F)(F)F',
  "O-(Benzotriazol-1-yl)-N,N,N',N'-bis(pentamethylene)uronium Hexafluorophosphate": 'F[P-](F)(F)(F)(F)F.N1(OC(N2CCCCC2)=[N+]3CCCCC3)N=NC4=C1C=CC=C4',
  "O-(Benzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Hexafluorophosphate": 'CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CC=C21.F[P-](F)(F)(F)(F)F',
  "O-(Benzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Tetrafluoroborate": 'CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CC=C21.F[B-](F)(F)F',
  "O,O'-Di-2-pyridyl Thiocarbonate": 'S=C(OC1=NC=CC=C1)OC2=NC=CC=C2',
  "O-[(Ethoxycarbonyl)cyanomethylenamino]-N,N,N',N'-tetramethyluronium Hexafluorophosphate": 'CN(C)C(N(C)C)=[O+]/N=C(C(OCC)=O)\\C#N.F[P-](F)(F)(F)(F)F',
  "O-[(Ethoxycarbonyl)cyanomethylenamino]-N,N,N',N'-tetramethyluronium Tetrafluoroborate": 'CN(C)C(N(C)C)=[O+]/N=C(C(OCC)=O)\\C#N.F[B-](F)(F)F',
  "O-[2-Oxo-1(2H)-pyridyl]-N,N,N',N'-tetramethyluronium Tetrafluoroborate": 'CN(C)C(N(C)C)=[O+]N1C=CC=CC1=O.F[B-](F)(F)F',
  "o-Chloramine T": 'CC1=CC=CC=C1S([N-]Cl)(=O)=O.[Na+]',
  "Octadecamethylcyclononasiloxane": 'C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1',
  "Octadecyltriethoxysilane": 'CCO[Si](OCC)(CCCCCCCCCCCCCCCCCC)OCC',
  "Octadecyltrimethoxysilane": 'CO[Si](OC)(CCCCCCCCCCCCCCCCCC)OC',
  "Octakis(dimethylsilyloxy)octasilsesquioxane": 'C[SiH](C)O[Si]1(O[Si]2(O[SiH](C)C)O[Si](O3)(O[SiH](C)C)O[Si](O4)(O[SiH](C)C)O[Si]5(O[SiH](C)C)O2)O[Si]3(O[SiH](C)C)O[Si]4(O[SiH](C)C)O[Si](O5)(O[SiH](C)C)O1',
  "Octamethylcyclotetrasiloxane": 'C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1',
  "Octamethyltrisiloxane": 'C[Si](C)(C)O[Si](C)(C)O[Si](C)(C)C',
  "Octaphenylcyclotetrasiloxane": 'C1([Si]2(C3=CC=CC=C3)O[Si](C4=CC=CC=C4)(C5=CC=CC=C5)O[Si](C6=CC=CC=C6)(C7=CC=CC=C7)O[Si](C8=CC=CC=C8)(C9=CC=CC=C9)O2)=CC=CC=C1',
  "Octaphenyloctasilsesquioxane": 'C1([Si]2(O3)O[Si](O[Si](O4)(C5=CC=CC=C5)O[Si](O6)(C7=CC=CC=C7)O8)(C9=CC=CC=C9)O[Si]8(C%10=CC=CC=C%10)O[Si]3(C%11=CC=CC=C%11)O[Si]6(C%12=CC=CC=C%12)O[Si]4(C%13=CC=CC=C%13)O2)=CC=CC=C1',
  "Osmium": '[Os]',
  "Osmium Tetroxide": '[Os+8].[O-2].[O-2].[O-2].[O-2]',
  "OsO4": '[Os+8].[O-2].[O-2].[O-2].[O-2]',
  "O-TBDPS-D-Thr-N-Boc-L-tert-Leu-Diphenylphosphine": 'O=C(OC(C)(C)C)N[C@@H](C(C)(C)C)C(N[C@H]([C@@H](O[Si](C1=CC=CC=C1)(C(C)(C)C)C2=CC=CC=C2)C)CP(C3=CC=CC=C3)C4=CC=CC=C4)=O',
  "O-tert-Butyl-L-threonine": 'N[C@@H]([C@H](OC(C)(C)C)C)C(O)=O',
  "o-Tolylmagnesium Bromide": 'Br[Mg]C1=CC=CC=C1C',
  "Oxalyl bromide": 'O=C(Br)C(Br)=O',
  "Oxalyl Chloride": 'O=C(Cl)C(Cl)=O',
  "Oxone": '[O-]S(=O)(=O)[O-].[O-]S(=O)(=O)O.[O-]OS(=O)(=O)O.[O-]OS(=O)(=O)O.[K+].[K+].[K+].[K+].[K+]',
  "Oxygen": 'OO',
  "O2": 'OO',
  "Ozone": 'O=[O+][O-]',
  "O3": 'O=[O+][O-]',
  "Palladium Carbon": '[Pd]',
  "Pd-C": '[Pd]',
  "PdC": '[Pd]',
  "Pd/C": '[Pd]',
  "Palladium hydroxide": '[Pd+2].[OH-].[OH-]',
  "Pd(OH)2": '[Pd+2].[OH-].[OH-]',
  "Palladium(II) Acetate": 'CC([O-])=O.CC([O-])=O.[Pd+2]',
  "Pd(OAc)2": 'CC([O-])=O.CC([O-])=O.[Pd+2]',
  "Palladium(II) Chloride": '[Pd+2].[Cl-].[Cl-]',
  "PdCl2": '[Pd+2].[Cl-].[Cl-]',
  "Palladium(II) Trifluoroacetate": 'O=C([O-])C(F)(F)F.O=C([O-])C(F)(F)F.[Pd+2]',
  "Palmitic Acid": 'CCCCCCCCCCCCCCCC(O)=O',
  "p-Cymene": 'CC1=CC=C(C(C)C)C=C1',
  "Pentadecylmagnesium Bromide": 'Br[Mg]CCCCCCCCCCCCCCC',
  "Pentafluoroanilinium Trifluoromethanesulfonate": 'F[N+](F)(F)C1=CC=CC(F)=C1F.O=S(C(F)(F)F)([O-])=O',
  "Pentafluorobenzoyl Chloride": 'O=C(Cl)C1=C(F)C(F)=C(F)C(F)=C1F',
  "Pentafluorophenol": 'OC1=C(F)C(F)=C(F)C(F)=C1F',
  "Pentafluorophenyl 4-Nitrobenzenesulfonate": 'O=S(C1=CC=C([N+]([O-])=O)C=C1)(OC2=C(F)C(F)=C(F)C(F)=C2F)=O',
  "Pentafluorophenyl Trifluoroacetate": 'O=C(OC1=C(F)C(F)=C(F)C(F)=C1F)C(F)(F)F',
  "Pentafluoropropionic Anhydride": 'O=C(OC(C(F)(F)C(F)(F)F)=O)C(F)(F)C(F)(F)F',
  "pentane": 'CCCCC',
  "Pentylboronic Acid": 'CCCCCB(O)O',
  "Pentylmagnesium Bromide": 'Br[Mg]CCCCC',
  "Pentylmagnesium chloride": 'Cl[Mg]CCCCC',
  "Pentyltriethoxysilane": 'CCO[Si](OCC)(CCCCC)OCC',
  "Periodic acid": 'O=I(=O)(O)=O',
  "HIO4": 'O=I(=O)(O)=O',
  "Perylene": 'C12=CC=CC(C3=CC=CC4=C3C5=CC=C4)=C1C5=CC=C2',
  "PhenoFluor™ Mix": '[Cs]F.Cl[C+](N(C=C1)C2=C(C(C)C)C=CC=C2C(C)C)=[N]1C(C(C(C)C)=CC=C3)=C3C(C)C.[Cl-]',
  "Phenol nitroprusside solution": 'O=N[Fe-2](C#N)(C#N)(C#N)(C#N)C#N.OC1=CC=CC=C1',
  "Phenyl Trifluoromethyl Sulfone": 'O=S(C(F)(F)F)(C1=CC=CC=C1)=O',
  "Phenyl Trifluoromethyl Sulfoxide": 'O=S(C1=CC=CC=C1)C(F)(F)F',
  "Phenyl[3-(trifluoromethyl)phenyl]iodonium triflate": 'FC(C1=CC([I+]C2=CC=CC=C2)=CC=C1)(F)F.O=S([O-])(C(F)(F)F)=O',
  "Phenylarsine Oxide": 'O=[As]C1=CC=CC=C1',
  "Phenylarsonic Acid": 'O=[As](C1=CC=CC=C1)(O)O',
  "Phenylgermanium Trichloride": 'Cl[Ge](Cl)(Cl)C1=CC=CC=C1',
  "Phenylmagnesium Bromide": 'Br[Mg]C1=CC=CC=C1',
  "Phenylmagnesium Chloride": 'Cl[Mg]C1=CC=CC=C1',
  "Phenylmagnesium Iodide": 'I[Mg]C1=CC=CC=C1',
  "Phenylphosphonic Dichloride": 'O=P(C1=CC=CC=C1)(Cl)Cl',
  "Phenylsilane": '[SiH3]C1=CC=CC=C1',
  "Phenyltin Trichloride": 'Cl[Sn](Cl)(Cl)C1=CC=CC=C1',
  "Phosphomolybdic acid": 'O=[Mo](OP(O)(O)=O)(O)=O',
  "Phosphomolybdic Acid Ethanol Solution": 'O=[Mo](OP(O)(O)=O)(O)=O.CCO',
  "Phosphomolybdic Acid Hydrate": 'O=[Mo](OP(O)(O)=O)(O)=O.[H]O[H]',
  "Phosphorus(V) oxychloride": 'ClP(Cl)(Cl)=O',
  "Phosphoryl chloride": 'O=P(Cl)(Cl)Cl',
  "Phthalic Acid": 'O=C(O)C1=CC=CC=C1C(O)=O',
  "Phthalocyanine": 'C1(C2=N3)=C(C=CC=C1)C(N2)=NC4=NC(C5=C4C=CC=C5)=NC(N6)=C7C=CC=CC7=C6N=C8C9=C(C=CC=C9)C3=N8',
  "Piperidine": 'N1CCCCC1',
  "Platinum": '[Pt]',
  "Platinum(IV) Oxide": '[Pt+4].[O-2].[O-2]',
  "Potassium (2-Formylphenyl)trifluoroborate": 'F[B-](F)(C1=CC=CC=C1C=O)F.[K+]',
  "Potassium (2-Methoxyphenyl)trifluoroborate": 'F[B-](F)(C1=CC=CC=C1OC)F.[K+]',
  "Potassium (3-Cyanophenyl)trifluoroborate": 'F[B-](F)(C1=CC=CC(C#N)=C1)F.[K+]',
  "Potassium (3-Methoxyphenyl)trifluoroborate": 'F[B-](F)(C1=CC=CC(OC)=C1)F.[K+]',
  "Potassium (3-Nitrophenyl)trifluoroborate": 'F[B-](F)(C1=CC=CC([N+]([O-])=O)=C1)F.[K+]',
  "Potassium (4-Cyanophenyl)trifluoroborate": 'F[B-](F)(C1=CC=C(C#N)C=C1)F.[K+]',
  "Potassium (4-Methoxyphenyl)trifluoroborate": 'F[B-](F)(C1=CC=C(OC)C=C1)F.[K+]',
  "Potassium (Acetoxymethyl)trifluoroborate": 'F[B-](F)(COC(C)=O)F.[K+]',
  "Potassium (Bromomethyl)trifluoroborate": 'F[B-](F)(CBr)F.[K+]',
  "Potassium (Iodomethyl)trifluoroborate": 'F[B-](F)(CI)F.[K+]',
  "Potassium (Methoxymethyl)trifluoroborate": 'F[B-](F)(COC)F.[K+]',
  "Potassium [[(tert-Butoxycarbonyl)amino]methyl]trifluoroborate": 'F[B-](F)(CNC(OC(C)(C)C)=O)F.[K+]',
  "Potassium 2-iodo-5-methylbenzenesulfonate": 'O=S(C1=CC(C)=CC=C1I)([O-])=O.[K+]',
  "Potassium 2-Thienyltrifluoroborate": 'F[B-](F)(C1=CC=CS1)F.[K+]',
  "Potassium 3-Pyridyltrifluoroborate": 'F[B-](F)(C1=CC=CN=C1)F.[K+]',
  "Potassium 4-Pyridyltrifluoroborate": 'F[B-](F)(C1=CC=NC=C1)F.[K+]',
  "potassium acetate": 'CC([O-])=O.[K+]',
  "KOAc": 'CC([O-])=O.[K+]',
  "Potassium Allyltrifluoroborate": 'F[B-](F)(CC=C)F.[K+]',
  "Potassium Bis(1-pyrazolyl)borohydride": 'N1([BH2-]N2N=CC=C2)N=CC=C1.[K+]',
  "Potassium Bis(trimethylsilyl)amide": 'C[Si]([N-][Si](C)(C)C)(C)C.[K+]',
  "Potassium Borohydride": '[K+].[BH4-]',
  "KBH4": '[K+].[BH4-]',
  "Potassium butanolate": 'CC([O-])(C)C.[K+]',
  "potassium carbonate": '[O-]C([O-])=O.[K+].[K+]',
  "K2CO3": '[O-]C([O-])=O.[K+].[K+]',
  "potassium chloride": '[K+].[Cl-]',
  "KCl": '[K+].[Cl-]',
  "Potassium cyanide": '[C-]#N.[K+]',
  "KCN": '[C-]#N.[K+]',
  "Potassium Cyclopropyltrifluoroborate": 'F[B-](F)(C1CC1)F.[K+]',
  "Potassium dichromate": 'O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O.[K+].[K+]',
  "Potassium ethoxide": 'CC[O-].[K+]',
  "potassium fluoride": '[K+].[F-]',
  "KF": '[K+].[F-]',
  "Potassium Hexafluoroarsenate": 'F[As-](F)(F)(F)(F)F.[K+]',
  "Potassium hydroxide": '[K+].[OH-]',
  "Potassium hydroxide solution": '[K+].[OH-]',
  "KOH": '[K+].[OH-]',
  "potassium iodide": '[K+].[I-]',
  "KI": '[K+].[I-]',
  "Potassium methoxide": 'C[O-].[K+]',
  "KOMe": 'C[O-].[K+]',
  "Potassium monopersulfate triple salt": 'OS(=O)(=O)[O-].OS(=O)(=O)O[O-].OS(=O)(=O)O[O-].[O-]S(=O)(=O)[O-].[K+].[K+].[K+].[K+].[K+]',
  "Potassium nitrate": '[N+](=O)([O-])[O-].[K+]',
  "KNO3": '[N+](=O)([O-])[O-].[K+]',
  "Potassium permanganate": 'O=[Mn](=O)(=O)([K])=O',
  "KMnO4": 'O=[Mn](=O)(=O)([K])=O',
  "Potassium Perrhenate": 'O=[Re](=O)([O-])=O.[K+]',
  "Potassium perruthenate": 'O=[Ru](=O)([O-])=O.[K+]',
  "potassium persulfate": '[O-]S(=O)(OOS(=O)([O-])=O)=O.[K+].[K+]',
  "Potassium Phenyltrifluoroborate": 'F[B-](F)(C1=CC=CC=C1)F.[K+]',
  "Potassium Trifluoro(trifluoromethyl)borate": 'FC([B-](F)(F)F)(F)F.[K+]',
  "Potassium Trifluoroacetate": 'O=C([O-])C(F)(F)F.[K+]',
  "Potassium Trimethoxy(trifluoromethyl)borate": 'FC([B-](OC)(OC)OC)(F)F.[K+]',
  "Potassium trimethylsilanolate": '[O-][Si](C)(C)C.[K+]',
  "Potassium Tris(1-pyrazolyl)borohydride": '[BH-](N1N=CC=C1)(N2N=CC=C2)N3N=CC=C3.[K+]',
  "Potassium Vinyltrifluoroborate": 'F[B-](F)(C=C)F.[K+]',
  "Potassium o-Tolyltrifluoroborate": 'F[B-](F)(C1=CC=CC=C1C)F.[K+]',
  "Potassium p-Tolyltrifluoroborate": 'F[B-](F)(C1=CC=C(C)C=C1)F.[K+]',
  "Potassium tert-Butoxide": 'CC(C)([O-])C.[K+]',
  "Potassium tert-Pentoxide": 'CCC(C)([O-])C.[K+]',
  "Propionic Acid": 'CCC(O)=O',
  "Propylboronic Acid": 'CCCB(O)O',
  "Propylmagnesium Bromide": 'Br[Mg]CCC',
  "Propylmagnesium chloride": 'Cl[Mg]CCC',
  "p-Toluenesulfonic Acid Monohydrate": 'CC1=CC=C(S(=O)(O)=O)C=C1.[H]O[H]',
  "p-Tolylmagnesium Bromide": 'Br[Mg]C1=CC=C(C)C=C1',
  "Pyridine": 'C1=CN=CC=C1',
  "Pyridine - Sulfur Trioxide Complex": 'O=S(=O)=O.C1=CC=NC=C1',
  "Pyridine Iodine Monochloride": 'IC1=NC=CC=C1Cl',
  "Pyridine-2-sulfonyl Fluoride": 'O=S(C1=NC=CC=C1)(F)=O',
  "Pyridinium 3-Nitrobenzenesulfonate": 'O=S(C1=CC=CC([N+]([O-])=O)=C1)([O-])=O.C2=CC=CC=[NH+]2',
  "Pyridinium Bromide Perbromide": 'BrBr.C1=[NH+]C=CC=C1.[Br-]',
  "Pyridinium chlorochromate": '[O-][Cr](=O)(Cl)=O.C1=CC=CC=[NH+]1',
  "Pyridinium dichromate": 'O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O.C1=CC=CC=[NH+]1.C2=CC=CC=[NH+]2',
  "Pyridinium Fluorochromate": '[O-][Cr](=O)(F)=O.C1=CC=CC=[NH+]1',
  "Pyridinium p-Toluenesulfonate": 'CC1=CC=C(S(=O)([O-])=O)C=C1.C2=CC=CC=[NH+]2',
  "Pyrimidine-2-sulfonyl Fluoride": 'O=S(C1=NC=CC=N1)(F)=O',
  "Quincoridine": 'OC[C@H]1C[C@@H]2CC[N@]1C[C@@H]2C=C',
  "Quincorine": 'OC[C@@H]1C[C@@H]2CC[N@]1C[C@@H]2C=C',
  "Quinidine": 'O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=C(OC)C=C34',
  "Quinidine Sulfate Dihydrate": 'O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=C(OC)C=C34.O=S(O)(O)=O.[H]O[H].[H]O[H]',
  "Quinine": 'O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=C(OC)C=C34',
  "Quinine Hydrochloride Dihydrate": 'O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=C(OC)C=C34.[H]Cl.[H]O[H].[H]O[H]',
  "Quinolinium Dichromate": 'O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O.C12=CC=C[NH+]=C1C=CC=C2.C34=CC=C[NH+]=C3C=CC=C4',
  "Quinuclidine": 'N12CCC(CC2)CC1',
  "Rhenium": '[Re]',
  "Rhodium": '[Rh]',
  "rhodium trihydroxide": '[Rh+3].[OH-].[OH-].[OH-]',
  "Rhodium(II) Acetate": 'CC([O-])=O.CC([O-])=O.[Rh+2]',
  "Rhodium(II) Octanoate": 'CCCCCCCC([O-])=O.CCCCCCCC([O-])=O.[Rh+2]',
  "RHODIUM(III) OXIDE HYDRATE": '[H]O[H].[Rh+3].[Rh+3].[O-2].[O-2].[O-2]',
  "Rubidium hydroxide": '[Rb+].[OH-]',
  "Ruthenium": '[Ru]',
  "Ruthenium(III) Chloride": '[Ru+3].[Cl-].[Cl-].[Cl-]',
  "RuCl3": '[Ru+3].[Cl-].[Cl-].[Cl-]',
  "Salcomine [=N,N'-Bis(salicylidene)ethylenediiminocobalt(II)]": '[O-]C(C=CC=C1)=C1/C=N/CC/N=C/C2=CC=CC=C2[O-].[Co+2]',
  "Samarium": '[Sm]',
  "Samarium(II) Iodide": '[Sm+2].[I-].[I-]',
  "Scandium": '[Sc]',
  "Scandium(III) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Sc+3]',
  "sec-Butyllithium": '[Li]C(C)CC',
  "sec-Butylmagnesium Bromide": 'Br[Mg]C(C)CC',
  "sec-Butylmagnesium chloride": 'Cl[Mg]C(C)CC',
  "sec-Butylmagnesium Chloride - Lithium Chloride": 'Cl[Mg]C(C)CC.[Li+].[Cl-]',
  "Selenium dioxide": 'O=[Se]=O',
  "SeO2": 'O=[Se]=O',
  "S-Ethyl Trifluorothioacetate": 'O=C(SCC)C(F)(F)F',
  "Silver": '[Ag]',
  "Silver Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.[Ag+]',
  "Silver(I) Trifluoromethanethiolate": '[S-]C(F)(F)F.[Ag+]',
  "Silver(II) picolinate": 'O=C([O-])C1=NC=CC=C1.O=C([O-])C2=NC=CC=C2.[Ag+2]',
  "Silver(II) Pyridine-2-carboxylate": 'O=C(C1=NC=CC=C1)[O-].O=C(C2=NC=CC=C2)[O-].[Ag+2]',
  "Sodium (Trihydroxy)phenylborate": 'O[B-](O)(O)C1=CC=CC=C1.[Na+]',
  "Sodium amide": '[Na+].[NH2-]',
  "NaNH2": '[Na+].[NH2-]',
  "Sodium ascorbate": '[O-]C([C@H](O1)[C@H](CO)O)=C(O)C1=O.[Na+]',
  "sodium azide": '[N-]=[N+]=[N-].[Na+]',
  "NaN3": '[N-]=[N+]=[N-].[Na+]',
  "Sodium Bis(2-methoxyethoxy)aluminum Dihydride": 'COCCO[Al+]OCCOC.[Na+].[H-].[H-]',
  "Sodium Bis(trimethylsilyl)amide": 'C[Si]([N-][Si](C)(C)C)(C)C.[Na+]',
  "Sodium Borohydride": '[Na+].[BH4-]',
  "NaBH4": '[Na+].[BH4-]',
  "sodium bromate": 'O=Br([O-])=O.[Na+]',
  "sodium bromide": '[Na+].[Br-]',
  "NaBr": '[Na+].[Br-]',
  "Sodium Bromodifluoroacetate": 'O=C([O-])C(F)(Br)F.[Na+]',
  "sodium carbonate": '[O-]C([O-])=O.[Na+].[Na+]',
  "Na2CO3": '[O-]C([O-])=O.[Na+].[Na+]',
  "Sodium carbonate decahydrate": '[O-]C([O-])=O.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Na+].[Na+]',
  "sodium chloride": '[Na+].[Cl-]',
  "NaCl": '[Na+].[Cl-]',
  "Sodium Chlorodifluoroacetate": 'O=C([O-])C(F)(Cl)F.[Na+]',
  "Sodium Cyanoborohydride": '[BH3-]C#N.[Na+]',
  "sodium cycanide": '[C-]#N.[Na+]',
  "NaCN": '[C-]#N.[Na+]',
  "sodium D,L lactate": 'OC(C)C([O-])=O.[Na+]',
  "Sodium Dichloroisocyanurate": 'ClN1C([N-]C(N(C1=O)Cl)=O)=O.[Na+]',
  "Sodium dichloroisocyanurate dihydrate": 'O=C1N=C([O-])N(Cl)C(N1Cl)=O.O.O.[Na+]',
  "Sodium dichromate dihydrate": 'O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O.[H]O[H].[H]O[H].[Na+].[Na+]',
  "Sodium Diphenylphosphinobenzene-3-sulfonate": 'O=S(C1=CC(P(C2=CC=CC=C2)C3=CC=CC=C3)=CC=C1)([O-])=O.[Na+]',
  "Sodium Ethoxide": 'CC[O-].[Na+]',
  "NaOEt": 'CC[O-].[Na+]',
  "sodium fluoride": '[Na+].[F-]',
  "NaF": '[Na+].[F-]',
  "Sodium hydride": '[NaH]',
  "NaH": '[NaH]',
  "sodium hydrogen difluoride": '[Na+].[H+].[F-].[F-]',
  "Sodium hydrogen periodate": 'O=I(=O)([O-])=O.O=I(=O)([O-])=O.[Na+].[H+]',
  "Sodium Hydroxide": '[Na+].[OH-]',
  "NaOH": '[Na+].[OH-]',
  "Sodium Hypochlorite Pentahydrate": 'Cl[O-].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Na+]',
  "Sodium iodide": '[Na+].[I-]',
  "NaI": '[Na+].[I-]',
  "sodium L-tartrate": 'O[C@H]([C@H](C([O-])=O)O)C([O-])=O.[Na+].[Na+]',
  "Sodium Methoxide": 'C[O-].[Na+]',
  "NaOMe": 'C[O-].[Na+]',
  "Sodium nitrate": '[N+](=O)([O-])[O-].[Na+]',
  "NaNO3": '[N+](=O)([O-])[O-].[Na+]',
  "Sodium percarbonate": 'O=C(O[O-])[O-].[Na+].[Na+]',
  "sodium perchlorate": 'O=Cl(=O)([O-])=O.[Na+]',
  "Sodium permanganate": 'O=[Mn](=O)([O-])=O.[Na+]',
  "NaMnO4": 'O=[Mn](=O)([O-])=O.[Na+]',
  "Sodium peroxide": '[O-][O-].[Na+].[Na+]',
  "Na2O2": '[O-][O-].[Na+].[Na+]',
  "sodium persulfate": '[O-]S(=O)(OOS(=O)([O-])=O)=O.[Na+].[Na+]',
  "Sodium phosphomolybdate hydrate": 'O=[Mo](OP(O)(O)=O)([O-])=O.[H]O[H].[Na+]',
  "sodium sulfite": 'O=S([O-])[O-].[Na+].[Na+]',
  "Sodium Tellurite Pentahydrate": 'O=[Te]([O-])[O-].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Na+].[Na+]',
  "sodium tetraborate": 'O[B-]12OBO[B-](OBO2)(O)O1.[Na+].[Na+]',
  "Sodium Tetrachloropalladate(II)": 'Cl[Pd-2](Cl)(Cl)Cl.[Na+].[Na+]',
  "Sodium Tetrafluoroborate": 'F[B-](F)(F)F.[Na+]',
  "Sodium Triacetoxyborohydride": 'O=C(O[BH-](OC(C)=O)OC(C)=O)C.[Na+]',
  "Sodium Trifluoroacetate": 'O=C([O-])C(F)(F)F.[Na+]',
  "Sodium Trifluoromethanesulfinate": 'O=S(C(F)(F)F)[O-].[Na+]',
  "Sodium trimethylsilanolate": '[O-][Si](C)(C)C.[Na+]',
  "Sodium Tris(1,1,1,3,3,3-hexafluoroisopropoxy)borohydride": 'FC(F)(C(C(F)(F)F)O[BH-](OC(C(F)(F)F)C(F)(F)F)OC(C(F)(F)F)C(F)(F)F)F.[Na+]',
  "Sodium tert-Butoxide": 'CC(C)([O-])C.[Na+]',
  "Sodium tert-Pentoxide": 'CCC(C)([O-])C.[Na+]',
  "Sorbic Acid": 'C/C=C/C=C/C(O)=O',
  "Strontium hydroxide": '[Sr+2].[OH-].[OH-]',
  "STRONTIUM HYDROXIDE OCTAHYDRATE": '[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Sr+2].[OH-].[OH-]',
  "Strontium peroxide": '[O-][O-].[Sr+2]',
  "Succinic Acid": 'O=C(O)CCC(O)=O',
  "Sulfanilic Acid": 'O=S(O)(C1=CC=C(N)C=C1)=O',
  "sulfolane": 'O=S1(CCCC1)=O',
  "Sulfur Trioxide": 'O=S(=O)=O',
  "SO3": 'O=S(=O)=O',
  "Sulfur Trioxide - Triethylamine Complex": 'O=S(=O)=O.CCN(CC)CC',
  "Sulfuric acid": 'O=S(O)(O)=O',
  "H2SO4": 'O=S(O)(O)=O',
  "Sulfuryl chloride": 'O=S(Cl)(Cl)=O',
  "SO2Cl2": 'O=S(Cl)(Cl)=O',
  "Tebbe reagent": 'C[Al-]1(C[Ti+2][Cl+]1)C.[cH-]1cccc1.[cH-]1cccc1',
  "Telluric Acid": 'O=[Te](O)(O)=O',
  "Tellurium(IV) Oxide": '[Te+4].[O-2].[O-2]',
  "Terbium": '[Tb]',
  "tert-Butyl hydroperoxide": 'CC(C)(OO)C',
  "TBHP": 'CC(C)(OO)C',
  "tert-Butyl Hydroperoxide": 'CC(C)(OO)C',
  "tert-Butyldimethylsilane": 'C[SiH](C(C)(C)C)C',
  "tert-Butyldimethylsilanol": 'O[Si](C)(C(C)(C)C)C',
  "tert-Butyldiphenylsilane": 'CC([SiH](C1=CC=CC=C1)C2=CC=CC=C2)(C)C',
  "tert-Butyllithium": '[Li]C(C)(C)C',
  "tBuLi": '[Li]C(C)(C)C',
  "tert-Butylmagnesium Chloride": 'Cl[Mg]C(C)(C)C',
  "tert-Butylmethoxyphenylsilyl Bromide": 'CO[Si](C(C)(C)C)(Br)C1=CC=CC=C1',
  "Tetra(decyl)ammonium Bromide": 'CCCCCCCCCC[N+](CCCCCCCCCC)(CCCCCCCCCC)CCCCCCCCCC.[Br-]',
  "Tetraallyltin": 'C=CC[Sn](CC=C)(CC=C)CC=C',
  "Tetraamylammonium Bromide": 'CCCCC[N+](CCCCC)(CCCCC)CCCCC.[Br-]',
  "Tetraamylammonium Chloride": 'CCCCC[N+](CCCCC)(CCCCC)CCCCC.[Cl-]',
  "Tetraamylammonium Iodide": 'CCCCC[N+](CCCCC)(CCCCC)CCCCC.[I-]',
  "Tetrabromofluorescein": 'O=C1OC2(C3=C(OC4=C2C=CC(O)=C4)C=C(O)C=C3)C5=C1C(Br)=C(Br)C(Br)=C5Br',
  "Tetrabutyl Orthosilicate": 'CCCCO[Si](OCCCC)(OCCCC)OCCCC',
  "Tetrabutyl Orthotitanate": 'CCCCO[Ti](OCCCC)(OCCCC)OCCCC',
  "Tetrabutyl Orthotitanate Tetramer": '[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[Ti+4].[Ti+4].[O-2].[O-2].[Ti+4].[O-2].[Ti+4]',
  "Tetrabutylammonium (meta)periodate": 'CCCC[N+](CCCC)(CCCC)CCCC.O=I(=O)([O-])=O',
  "Tetrabutylammonium Acetate": 'CCCC[N+](CCCC)(CCCC)CCCC.CC([O-])=O',
  "Tetrabutylammonium Bifluoride": 'CCCC[N+](CCCC)(CCCC)CCCC.[H]F.[F-]',
  "Tetrabutylammonium Borohydride": 'CCCC[N+](CCCC)(CCCC)CCCC.[BH4-]',
  "Tetrabutylammonium Bromide": 'CCCC[N+](CCCC)(CCCC)CCCC.[Br-]',
  "Tetrabutylammonium Chloride": 'CCCC[N+](CCCC)(CCCC)CCCC.[Cl-]',
  "Tetrabutylammonium chlorochromate": 'CCCC[N+](CCCC)(CCCC)CCCC.[O-][Cr](=O)(Cl)=O',
  "Tetrabutylammonium Difluorotriphenylstannate": 'CCCC[N+](CCCC)(CCCC)CCCC.F[Sn-](C1=CC=CC=C1)(C2=CC=CC=C2)(F)C3=CC=CC=C3',
  "Tetrabutylammonium Dihydrogen Trifluoride": 'CCCC[N+](CCCC)(CCCC)CCCC.[H+].[H+].[F-].[F-].[F-]',
  "Tetrabutylammonium ethoxide": 'CCCC[N+](CCCC)(CCCC)CCCC.CC[O-]',
  "Tetrabutylammonium Fluoride": 'CCCC[N+](CCCC)(CCCC)CCCC.[F-]',
  "Tetrabutylammonium Fluoride Hydrate": 'CCCC[N+](CCCC)(CCCC)CCCC.[H]O[H].[F-]',
  "Tetrabutylammonium Hexafluorophosphate": 'CCCC[N+](CCCC)(CCCC)CCCC.F[P-](F)(F)(F)(F)F',
  "Tetrabutylammonium Hydrogen Sulfate": 'CCCC[N+](CCCC)(CCCC)CCCC.O=S(O)([O-])=O',
  "Tetrabutylammonium hydroxide": 'CCCC[N+](CCCC)(CCCC)CCCC.[OH-]',
  "Tetrabutylammonium Iodide": 'CCCC[N+](CCCC)(CCCC)CCCC.[I-]',
  "Tetrabutylammonium methoxide": 'CCCC[N+](CCCC)(CCCC)CCCC.C[O-]',
  "Tetrabutylammonium Perchlorate": 'CCCC[N+](CCCC)(CCCC)CCCC.O=Cl(=O)([O-])=O',
  "Tetrabutylammonium Perrhenate": 'CCCC[N+](CCCC)(CCCC)CCCC.O=[Re](=O)([O-])=O',
  "Tetrabutylammonium Tetrafluoroborate": 'CCCC[N+](CCCC)(CCCC)CCCC.F[B-](F)(F)F',
  "Tetrabutylammonium Tetraphenylborate": 'CCCC[N+](CCCC)(CCCC)CCCC.C1([B-](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1',
  "Tetrabutylammonium Thiocyanate": 'CCCC[N+](CCCC)(CCCC)CCCC.[S-]C#N',
  "Tetrabutylammonium Tribromide": 'CCCC[N+](CCCC)(CCCC)CCCC.[Br-].[Br-].[Br-]',
  "Tetrabutylammonium Trifluoromethanesulfonate": 'CCCC[N+](CCCC)(CCCC)CCCC.O=S(C(F)(F)F)([O-])=O',
  "Tetrabutylammonium Triiodide": 'CCCC[N+](CCCC)(CCCC)CCCC.[I-].[I-].[I-]',
  "Tetrabutylgermane": 'CCCC[Ge](CCCC)(CCCC)CCCC',
  "Tetrabutylphosphonium Bromide": 'CCCC[P+](CCCC)(CCCC)CCCC.[Br-]',
  "Tetrabutylphosphonium Chloride": 'CCCC[P+](CCCC)(CCCC)CCCC.[Cl-]',
  "Tetrabutylphosphonium Hexafluorophosphate": 'CCCC[P+](CCCC)(CCCC)CCCC.F[P-](F)(F)(F)(F)F',
  "Tetrabutylphosphonium Hydroxide": 'CCCC[P+](CCCC)(CCCC)CCCC.[OH-]',
  "Tetrabutylphosphonium malonate": 'CCCC[P+](CCCC)(CCCC)CCCC.CCCC[P+](CCCC)(CCCC)CCCC.O=C([O-])CC([O-])=O',
  "Tetrabutylphosphonium Tetrafluoroborate": 'CCCC[P+](CCCC)(CCCC)CCCC.F[B-](F)(F)F',
  "Tetrabutyltin": 'CCCC[Sn](CCCC)(CCCC)CCCC',
  "Tetracyanoethylene": 'N#CC(C#N)=C(C#N)C#N',
  "Tetradecamethylcycloheptasiloxane": 'C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1',
  "Tetradecyltrimethylammonium Bromide": 'C[N+](C)(CCCCCCCCCCCCCC)C.[Br-]',
  "Tetraethyl 1,4,8,11-Tetraazacyclotetradecane-1,4,8,11-tetraacetate": 'O=C(OCC)CN1CCN(CC(OCC)=O)CCCN(CC(OCC)=O)CCN(CC(OCC)=O)CCC1',
  "Tetraethyl Orthosilicate": 'CCO[Si](OCC)(OCC)OCC',
  "Tetraethylammonium Borohydride": 'CC[N+](CC)(CC)CC.[BH4-]',
  "Tetraethylammonium Bromide": 'CC[N+](CC)(CC)CC.[Br-]',
  "Tetraethylammonium Chloride": 'CC[N+](CC)(CC)CC.[Cl-]',
  "Tetraethylammonium Fluoride Tetrahydrofluoride": 'CC[N+](CC)(CC)CC.[H]F.[H]F.[H]F.[H]F.[F-]',
  "Tetraethylammonium Fluoride Trihydrofluoride": 'CC[N+](CC)(CC)CC.[H]F.[H]F.[H]F.[F-]',
  "Tetraethylammonium Hydroxide": 'CC[N+](CC)(CC)CC.[OH-]',
  "Tetraethylammonium Iodide": 'CC[N+](CC)(CC)CC.[I-]',
  "Tetraethylammonium Nitrate": 'CC[N+](CC)(CC)CC.[O-][N+]([O-])=O',
  "Tetraethylammonium Tetrafluoroborate": 'CC[N+](CC)(CC)CC.F[B-](F)(F)F',
  "Tetraethylammonium Trifluoromethanesulfonate": 'CC[N+](CC)(CC)CC.O=S(C(F)(F)F)([O-])=O',
  "Tetraethylammonium p-Toluenesulfonate": 'CC[N+](CC)(CC)CC.CC1=CC=C(S(=O)([O-])=O)C=C1',
  "Tetraethylgermane": 'CC[Ge](CC)(CC)CC',
  "Tetraethylphosphonium Bromide": 'CC[P+](CC)(CC)CC.[Br-]',
  "Tetraethylphosphonium Hexafluorophosphate": 'CC[P+](CC)(CC)CC.F[P-](F)(F)(F)(F)F',
  "Tetraethylphosphonium Tetrafluoroborate": 'CC[P+](CC)(CC)CC.F[B-](F)(F)F',
  "Tetraethylsilane": 'CC[Si](CC)(CC)CC',
  "Tetrafluoroboronic acid diethylether complex": 'F[B-](F)(F)F.CCOCC.[H+]',
  "Tetraheptylammonium Bromide": 'CCCCCCC[N+](CCCCCCC)(CCCCCCC)CCCCCCC.[Br-]',
  "Tetraheptylammonium Iodide": 'CCCCCCC[N+](CCCCCCC)(CCCCCCC)CCCCCCC.[I-]',
  "Tetrahexylammonium Bromide": 'CCCCCC[N+](CCCCCC)(CCCCCC)CCCCCC.[Br-]',
  "Tetrahexylammonium Hydrogen Sulfate": 'CCCCCC[N+](CCCCCC)(CCCCCC)CCCCCC.O=S(O)([O-])=O',
  "Tetrahexylammonium Hydroxide": 'CCCCCC[N+](CCCCCC)(CCCCCC)CCCCCC.[OH-]',
  "Tetrahexylammonium Iodide": 'CCCCCC[N+](CCCCCC)(CCCCCC)CCCCCC.[I-]',
  "Tetrahydroxydiboron": 'OB(O)B(O)O',
  "tetrahydroxytitanium": 'O[Ti](O)(O)O',
  "Tetraisobutyl Orthotitanate": 'CC(C)CO[Ti](OCC(C)C)(OCC(C)C)OCC(C)C',
  "Tetraisopropyl Orthosilicate": 'CC(O[Si](OC(C)C)(OC(C)C)OC(C)C)C',
  "Tetraisopropyl Orthotitanate": 'CC(O[Ti](OC(C)C)(OC(C)C)OC(C)C)C',
  "Tetrakis(2,4-pentanedionato)titanium(IV)": '[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[O-]CCC.[O-]CCC.[Ti+4]',
  "Tetrakis(acetonitrile)copper(I) Hexafluorophosphate": 'CC#N.CC#N.CC#N.CC#N.F[P-](F)(F)(F)(F)F.[Cu+]',
  "Tetrakis(acetonitrile)copper(I) Tetrafluoroborate": 'CC#N.CC#N.CC#N.CC#N.F[B-](F)(F)F.[Cu+]',
  "Tetrakis(acetonitrile)palladium(II) Bis(trifluoromethanesulfonate)": 'CC#N.CC#N.CC#N.CC#N.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Pd+2]',
  "Tetrakis(decyl)ammonium hydroxide": 'CCCCCCCCCC[N+](CCCCCCCCCC)(CCCCCCCCCC)CCCCCCCCCC.[OH-]',
  "Tetrakis(dimethylamino)titanium(IV)": 'CN([Ti](N(C)C)(N(C)C)N(C)C)C',
  "Tetrakis(dimethylsilyl)silane": 'C[SiH]([Si]([SiH](C)C)([SiH](C)C)[SiH](C)C)C',
  "Tetrakis(dimethylsilyloxy)silane": 'C[SiH](O[Si](O[SiH](C)C)(O[SiH](C)C)O[SiH](C)C)C',
  "Tetrakis(hydroxymethyl)phosphonium Chloride": 'OC[P+](CO)(CO)CO.[Cl-]',
  "Tetrakis(hydroxymethyl)phosphonium Sulfate": 'OC[P+](CO)(CO)CO.OC[P+](CO)(CO)CO.O=S([O-])([O-])=O',
  "Tetrakis(trimethylsilyl)silane": 'C[Si]([Si]([Si](C)(C)C)([Si](C)(C)C)[Si](C)(C)C)(C)C',
  "Tetrakis(triphenylphosphine)palladium(0)": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9.P(C%10=CC=CC=C%10)(C%11=CC=CC=C%11)C%12=CC=CC=C%12.[Pd]',
  "Tetrakis": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9.P(C%10=CC=CC=C%10)(C%11=CC=CC=C%11)C%12=CC=CC=C%12.[Pd]',
  "Pd(Ph3)4": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9.P(C%10=CC=CC=C%10)(C%11=CC=CC=C%11)C%12=CC=CC=C%12.[Pd]',
  "Tetrakis[dimethyl(vinyl)silyl] Orthosilicate": 'C=C[Si](O[Si](O[Si](C)(C)C=C)(O[Si](C)(C)C=C)O[Si](C)(C)C=C)(C)C',
  "Tetramethylammonium Acetate": 'C[N+](C)(C)C.CC([O-])=O',
  "Tetramethylammonium Borohydride": 'C[N+](C)(C)C.[BH4-]',
  "Tetramethylammonium Bromide": 'C[N+](C)(C)C.[Br-]',
  "Tetramethylammonium Chloride": 'C[N+](C)(C)C.[Cl-]',
  "Tetramethylammonium Dichloroiodate": 'C[N+](C)(C)C.Cl[I-]Cl',
  "Tetramethylammonium Fluoride Tetrahydrate": 'C[N+](C)(C)C.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[F-]',
  "Tetramethylammonium Hexafluorophosphate": 'C[N+](C)(C)C.F[P-](F)(F)(F)(F)F',
  "Tetramethylammonium Hydroxide": 'C[N+](C)(C)C.[OH-]',
  "Tetramethylammonium Iodide": 'C[N+](C)(C)C.[I-]',
  "Tetramethylammonium Sulfate": 'C[N+](C)(C)C.C[N+](C)(C)C.O=S([O-])([O-])=O',
  "Tetramethylammonium Tetrafluoroborate": 'C[N+](C)(C)C.F[B-](F)(F)F',
  "Tetramethylammonium Triacetoxyborohydride": 'C[N+](C)(C)C.O=C(O[BH-](OC(C)=O)OC(C)=O)C',
  "Tetramethylgermane": 'C[Ge](C)(C)C',
  "Tetramethylsilane": 'C[Si](C)(C)C',
  "TMS": 'C[Si](C)(C)C',
  "Tetra-n-octylammonium Bromide": 'CCCCCCCC[N+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[Br-]',
  "Tetra-n-octylammonium Iodide": 'CCCCCCCC[N+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[I-]',
  "Tetra-n-octylphosphonium Bromide": 'CCCCCCCC[P+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[Br-]',
  "Tetraoctylammonium hydroxide": 'CCCCCCCC[N+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[OH-]',
  "Tetrapentylammonium hydroxide": 'CCCCC[N+](CCCCC)(CCCCC)CCCCC.[OH-]',
  "Tetraphenyl Lead": 'C1([Pb](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1',
  "Tetraphenylarsonium Chloride Hydrochloride Hydrate": '[H]O[H].[H]Cl.C1([As+](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1.[Cl-]',
  "Tetraphenylcyclopentadienone": 'O=C1C(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C1C5=CC=CC=C5',
  "Tetraphenylgermane": 'C1([Ge](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1',
  "Tetraphenylphosphonium Bromide": 'C1([P+](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1.[Br-]',
  "Tetraphenylphosphonium Chloride": 'C1([P+](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1.[Cl-]',
  "Tetraphenylporphyrin": 'C1(C2=CC=CC=C2)=C3C=CC(C(C4=CC=CC=C4)=C5C=CC(N5)=C(C6=CC=CC=C6)C(C=C7)=NC7=C(C8=CC=CC=C8)C9=CC=C1N9)=N3',
  "Tetraphenylsilane": 'C1([Si](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1',
  "Tetraphenyltin": 'C1([Sn](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1',
  "Tetrapropyl Orthosilicate": 'CCCO[Si](OCCC)(OCCC)OCCC',
  "Tetrapropylammonium Bromide": 'CCC[N+](CCC)(CCC)CCC.[Br-]',
  "Tetrapropylammonium Chloride": 'CCC[N+](CCC)(CCC)CCC.[Cl-]',
  "Tetrapropylammonium Hydroxide": 'CCC[N+](CCC)(CCC)CCC.[OH-]',
  "Tetrapropylammonium Iodide": 'CCC[N+](CCC)(CCC)CCC.[I-]',
  "Tetrapropylammonium Perruthenate": 'CCC[N+](CCC)(CCC)CCC.O=[Ru](=O)([O-])=O',
  "Thallium(III) Trifluoroacetate": 'O=C([O-])C(F)(F)F.O=C([O-])C(F)(F)F.O=C([O-])C(F)(F)F.[Tl+3]',
  "THF": 'C1CCCO1',
  "Thioacetic Acid": 'CC(O)=S',
  "Thioglycolic Acid": 'SCC(O)=O',
  "Thionyl Chloride": 'O=S(Cl)Cl',
  "Thiophosphoryl Chloride": 'OP(O)(Cl)=S',
  "Thulium": '[Tm]',
  "Thulium(III) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Tm+3]',
  "tin hydroxide": '[Sn+4].[OH-].[OH-].[OH-].[OH-]',
  "Tin(II) 2-Ethylhexanoate": 'CCCCC(CC)C([O-])=O.CCCCC(CC)C([O-])=O.[Sn+2]',
  "Tin(II) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Sn+2]',
  "Tin(IV) Chloride": '[Sn+4].[Cl-].[Cl-].[Cl-].[Cl-]',
  "Titanium": '[Ti]',
  "titanium hydroxide": '[Ti+4].[OH-].[OH-].[OH-].[OH-]',
  "Titanium(IV) Chloride": '[Ti+4].[Cl-].[Cl-].[Cl-].[Cl-]',
  "TMS-Imidazole (=N-Trimethylsilylimidazole)": 'C[Si](N1C=NC=C1)(C)C',
  "toluene": 'CC1=CC=CC=C1',
  "trans,trans-1,5-Bis(4-fluorophenyl)-1,4-pentadien-3-one": 'O=C(/C=C/C1=CC=C(F)C=C1)/C=C/C2=CC=C(F)C=C2',
  "trans,trans-1,5-Bis(4-methoxyphenyl)-1,4-pentadien-3-one": 'O=C(/C=C/C1=CC=C(OC)C=C1)/C=C/C2=CC=C(OC)C=C2',
  "trans,trans-1,5-Bis[4-(trifluoromethyl)phenyl]-1,4-pentadien-3-one": 'O=C(/C=C/C1=CC=C(C(F)(F)F)C=C1)/C=C/C2=CC=C(C(F)(F)F)C=C2',
  "trans,trans-1,5-Diphenyl-1,4-pentadien-3-one": 'O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2',
  "trans-1,2-Bis(diphenylphosphino)ethylene": 'P(/C=C/P(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4',
  "trans-2,6-Diisopropyl-N-(2-pyridylmethylene)aniline": 'CC(C1=C(C(C(C)C)=CC=C1)/N=C/C2=NC=CC=C2)C',
  "trans-2-Butene-1,4-bis(triphenylphosphonium Chloride)": 'C1([P+](C/C=C/C[P+](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC=C1.[Cl-].[Cl-]',
  "trans-4-(tert-Butyldiphenylsilyloxy)-L-proline": 'O=C(O)[C@H]1NC[C@H](O[Si](C2=CC=CC=C2)(C(C)(C)C)C3=CC=CC=C3)C1',
  "trans-4-Fluoro-L-proline": 'O=C(O)[C@H]1NC[C@H](F)C1',
  "Tri(2-furyl)phosphine": 'P(C1=CC=CO1)(C2=CC=CO2)C3=CC=CO3',
  "Tri(2-thienyl)phosphine": 'P(C1=CC=CS1)(C2=CC=CS2)C3=CC=CS3',
  "Tri(cyclohexa-2,5-dien-1-yl)silane": '[SiH](C1C=CCC=C1)(C2C=CCC=C2)C3C=CCC=C3',
  "Tri(m-tolyl)phosphine": 'CC1=CC(P(C2=CC=CC(C)=C2)C3=CC=CC(C)=C3)=CC=C1',
  "Tri(o-tolyl)phosphine": 'CC1=CC=CC=C1P(C2=CC=CC=C2C)C3=CC=CC=C3C',
  "Tri(p-tolyl)phosphine": 'CC1=CC=C(P(C2=CC=C(C)C=C2)C3=CC=C(C)C=C3)C=C1',
  "Triacetoxyborohydride": 'O=C(O[BH-](OC(C)=O)OC(C)=O)C',
  "Triacetoxyethylsilane": 'CC[Si](OC(C)=O)(OC(C)=O)OC(C)=O',
  "Triallyl(methyl)silane": 'C[Si](CC=C)(CC=C)CC=C',
  "Triallyl(phenyl)silane": 'C=CC[Si](CC=C)(CC=C)C1=CC=CC=C1',
  "Tribenzylsilane": '[SiH](CC1=CC=CC=C1)(CC2=CC=CC=C2)CC3=CC=CC=C3',
  "Tribromoacetic Acid": 'O=C(O)C(Br)(Br)Br',
  "Tributyl borate": 'B(OCCCC)(OCCCC)OCCCC',
  "Tributyl(2-methoxyethyl)phosphonium Bis(trifluoromethanesulfonyl)imide": 'CCCC[P+](CCCC)(CCCC)CCOC.FC(F)(S([N-]S(C(F)(F)F)(=O)=O)(=O)=O)F',
  "Tributyl(cyanomethyl)phosphonium Chloride": 'N#CC[P+](CCCC)(CCCC)CCCC.[Cl-]',
  "Tributyl(ethyl)phosphonium Diethyl Phosphate": 'CC[P+](CCCC)(CCCC)CCCC.O=P(OCC)([O-])OCC',
  "Tributyl(methyl)ammonium Dicyanamide": '[H]C(C([H])([H])C([H])([H])C([H])([H])[H])([H])[N+](C([H])([H])C([H])([H])C([H])([H])C([H])([H])[H])(C([H])([H])[H])C([H])([H])C([H])([H])C([H])([H])C([H])([H])[H].[N-]=C=NC#N',
  "Tributylamine": 'CCCCN(CCCC)CCCC',
  "Tributylchlorosilane": 'Cl[Si](CCCC)(CCCC)CCCC',
  "Tributyldodecylphosphonium Bromide": 'CCCCCCCCCCCC[P+](CCCC)(CCCC)CCCC.[Br-]',
  "Tributylhexadecylphosphonium Bromide": 'CCCCCCCCCCCCCCCC[P+](CCCC)(CCCC)CCCC.[Br-]',
  "Tributylmethylammonium Bis(trifluoromethanesulfonyl)imide": 'FC(F)(S([N-]S(C(F)(F)F)(=O)=O)(=O)=O)F.C[N+](CCCC)(CCCC)CCCC',
  "Tributylmethylammonium Chloride": 'C[N+](CCCC)(CCCC)CCCC.[Cl-]',
  "Tributylmethylphosphonium Bis(trifluoromethanesulfonyl)imide": 'C[P+](CCCC)(CCCC)CCCC.FC(F)(S([N-]S(C(F)(F)F)(=O)=O)(=O)=O)F',
  "Tributylmethylphosphonium Iodide": 'C[P+](CCCC)(CCCC)CCCC.[I-]',
  "Tributyl-n-octylphosphonium Bromide": 'CCCCCCCC[P+](CCCC)(CCCC)CCCC.[Br-]',
  "Tributylphosphine Oxide": 'CCCCP(CCCC)(CCCC)=O',
  "Tributylsilane": 'CCCC[SiH](CCCC)CCCC',
  "Tributylsulfonium Iodide": 'CCCC[S+](CCCC)CCCC.[I-]',
  "Tricarbonyl(cyclooctatetraene)iron": '[C]=O.[C]=O.[C]=O.[Fe].C1=C\\C=C/C=C\\C=C/1',
  "Trichloro(3,3,3-trifluoropropyl)silane": 'FC(F)(F)CC[Si](Cl)(Cl)Cl',
  "Trichloro-2-cyanoethylsilane": 'N#CCC[Si](Cl)(Cl)Cl',
  "Trichloroacetic Acid": 'O=C(O)C(Cl)(Cl)Cl',
  "Trichlorocyclopentylsilane": 'Cl[Si](Cl)(Cl)C1CCCC1',
  "Trichloroisocyanuric Acid": 'O=C(N(Cl)C(N1Cl)=O)N(Cl)C1=O',
  "Trichloromethanesulfonyl Chloride": 'O=S(C(Cl)(Cl)Cl)(Cl)=O',
  "Tricyclohexylphosphine": 'P(C1CCCCC1)(C2CCCCC2)C3CCCCC3',
  "Tricyclohexylphosphonium Tetrafluoroborate": 'F[B-](F)(F)F.[PH+](C1CCCCC1)(C2CCCCC2)C3CCCCC3',
  "Triethoxy(3-glycidyloxypropyl)silane": 'CCO[Si](OCC)(OCC)CCCOCC1CO1',
  "Triethoxy(pentafluorophenyl)silane": 'FC1=C(F)C(F)=C(F)C(F)=C1[Si](OCC)(OCC)OCC',
  "Triethoxy(propyl)silane": 'CCC[Si](OCC)(OCC)OCC',
  "Triethoxy[2-(7-oxabicyclo[4.1.0]heptan-3-yl)ethyl]silane": 'CCO[Si](OCC)(OCC)CCC1CC2OC2CC1',
  "Triethoxy-1H,1H,2H,2H-tridecafluoro-n-octylsilane": 'F[SiH2]C(F)(F)CC(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(OCC)(OCC)OCC',
  "Triethoxyethylsilane": 'CC[Si](OCC)(OCC)OCC',
  "Triethoxyfluorosilane": 'F[Si](OCC)(OCC)OCC',
  "Triethoxymethylsilane": 'C[Si](OCC)(OCC)OCC',
  "Triethoxy-n-octylsilane": 'CCCCCCCC[Si](OCC)(OCC)OCC',
  "Triethoxyphenylsilane": 'CCO[Si](OCC)(OCC)C1=CC=CC=C1',
  "Triethoxyvinylsilane": 'C=C[Si](OCC)(OCC)OCC',
  "Triethyl 2-Fluoro-2-phosphonoacetate": 'O=C(OCC)C(F)P(OCC)(OCC)=O',
  "Triethyl(trifluoromethyl)silane": 'FC([Si](CC)(CC)CC)(F)F',
  "Triethylamine": 'CCN(CC)CC',
  "Triethylamine Borane": 'CCN(CC)CC.B',
  "Triethylamine Trihydrofluoride": 'CCN(CC)CC.[H]F.[H]F.[H]F',
  "Triethylchlorogermane": 'Cl[Ge](CC)(CC)CC',
  "Triethylenetetramine": 'NCCNCCNCCN',
  "Triethylmethylammonium Chloride": 'C[N+](CC)(CC)CC.[Cl-]',
  "Triethylmethylammonium hydroxide": 'C[N+](CC)(CC)CC.[OH-]',
  "Triethylphenylammonium Chloride": 'CC[N+](CC)(CC)C1=CC=CC=C1.[Cl-]',
  "Triethylsilane": 'CC[SiH](CC)CC',
  "TES": 'CC[SiH](CC)CC',
  "Triethylsilanol": 'O[Si](CC)(CC)CC',
  "Triethylsilylacetylene": 'C#C[Si](CC)(CC)CC',
  "Triethylsulfonium Bis(trifluoromethanesulfonyl)imide": 'O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O.CC[S+](CC)CC',
  "Triethylvinylsilane": 'C=C[Si](CC)(CC)CC',
  "Trifluoroacetic Acid": 'O=C(O)C(F)(F)F',
  "TFA": 'O=C(O)C(F)(F)F',
  "Trifluoroacetic Anhydride": 'O=C(OC(C(F)(F)F)=O)C(F)(F)F',
  "TFAA": 'O=C(OC(C(F)(F)F)=O)C(F)(F)F',
  "Trifluoroacetyl Triflate": 'O=S(OC(C(F)(F)F)=O)(C(F)(F)F)=O',
  "Trifluoroacetylacetone": 'O=C(CC(C)=O)C(F)(F)F',
  "Trifluoroboronic acid diethyletherate": 'FB(F)F.CCOCC',
  "Trifluoromethanesulfonanilide": 'O=S(C(F)(F)F)(NC1=CC=CC=C1)=O',
  "Trifluoromethanesulfonic Acid": 'O=S(C(F)(F)F)(O)=O',
  "Trifluoromethanesulfonic Anhydride": 'O=S(C(F)(F)F)(OS(C(F)(F)F)(=O)=O)=O',
  "Trifluoromethanesulfonyl Chloride": 'O=S(C(F)(F)F)(Cl)=O',
  "Trihexyl(tetradecyl)phosphonium Chloride": 'CCCCCCCCCCCCCC[P+](CCCCCC)(CCCCCC)CCCCCC.[Cl-]',
  "Trihexyl(tetradecyl)phosphonium Dicyanamide": 'CCCCCC[P+](CCCCCC)(CCCCCC)CCCCCCCCCCCCCC.N#C[N-]C#N',
  "Trihexylphosphine": 'CCCCCCP(CCCCCC)CCCCCC',
  "Trihexylsilane": 'CCCCCC[SiH](CCCCCC)CCCCCC',
  "Triisopropyl[(trimethylsilyl)ethynyl]silane": 'C[Si](C#C[Si](C(C)C)(C(C)C)C(C)C)(C)C',
  "Triisopropylsilane": 'CC([SiH](C(C)C)C(C)C)C',
  "Triisopropylsilylacetylene": 'C#C[Si](C(C)C)(C(C)C)C(C)C',
  "Trimagnesium Dicitrate Nonahydrate": 'OC(C([O-])=O)(CC([O-])=O)CC([O-])=O.OC(C([O-])=O)(CC([O-])=O)CC([O-])=O.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Mg+2].[Mg+2].[Mg+2]',
  "Trimethoxy(1H,1H,2H,2H-tridecafluoro-n-octyl)silane": 'FC(C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F)(F)CC[Si](OC)(OC)OC',
  "Trimethoxy(2-phenylethyl)silane": 'CO[Si](OC)(OC)CCC1=CC=CC=C1',
  "Trimethoxy(3,3,3-trifluoropropyl)silane": 'FC(F)(F)CC[Si](OC)(OC)OC',
  "Trimethoxy(4-methoxyphenyl)silane": 'COC1=CC=C([Si](OC)(OC)OC)C=C1',
  "Trimethoxy(methyl)silane": 'C[Si](OC)(OC)OC',
  "Trimethoxy(phenylethyl)silane": 'CO[Si](OC)(OC)CCC1=CC=CC=C1',
  "Trimethoxy(propyl)silane": 'CCC[Si](OC)(OC)OC',
  "Trimethoxy[3-(methylamino)propyl]silane": 'CNCCC[Si](OC)(OC)OC',
  "Trimethoxy[3-(phenylamino)propyl]silane": 'CO[Si](OC)(OC)CCCNC1=CC=CC=C1',
  "Trimethoxy-n-octylsilane": 'CCCCCCCC[Si](OC)(OC)OC',
  "Trimethoxyphenylsilane": 'CO[Si](OC)(OC)C1=CC=CC=C1',
  "Trimethyl 2,2':6',2''-Terpyridine-4,4',4''-tricarboxylate": 'O=C(C1=CC(C2=NC(C3=NC=CC(C(OC)=O)=C3)=CC(C(OC)=O)=C2)=NC=C1)OC',
  "Trimethyl(2-pyridyl)tin": 'C[Sn](C)(C)C1=NC=CC=C1',
  "Trimethyl(4-pyridyl)tin": 'C[Sn](C)(C)C1=CC=NC=C1',
  "Trimethyl(heptafluoropropyl)silane": 'FC([Si](C)(C)C)(F)C(F)(F)C(F)(F)F',
  "Trimethyl(nonafluorobutyl)silane": 'FC([Si](C)(C)C)(F)C(F)(F)C(F)(F)C(F)(F)F',
  "Trimethyl(pentafluoroethyl)silane": 'FC([Si](C)(C)C)(F)C(F)(F)F',
  "Trimethyl(pentafluorophenyl)silane": 'FC1=C(F)C(F)=C(F)C(F)=C1[Si](C)(C)C',
  "Trimethyl(tridecafluorohexyl)silane": 'FC([Si](C)(C)C)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F',
  "Trimethyl[2-[(trimethylsilyl)methyl]benzyl]ammonium Iodide": 'C[Si](CC1=CC=CC=C1C[N+](C)(C)C)(C)C.[I-]',
  "Trimethyl[3-(trimethoxysilyl)propyl]ammonium Chloride": 'CO[Si](CCC[N+](C)(C)C)(OC)OC.[Cl-]',
  "Trimethylamine Borane": 'CN(C)C.B',
  "Trimethylamine N-Oxide": 'C[N+](C)([O-])C',
  "Trimethylamine N-Oxide Dihydrate": 'C[N+](C)([O-])C.[H]O[H].[H]O[H]',
  "Trimethylchlorogermane": 'Cl[Ge](C)(C)C',
  "Trimethylethoxysilane": 'CCO[Si](C)(C)C',
  "Trimethylnonylammonium Bromide": 'CCCCCCCCC[N+](C)(C)C.[Br-]',
  "Trimethylphenylammonium Bromide": 'C[N+](C)(C)C1=CC=CC=C1.[Br-]',
  "Trimethylphenylammonium Chloride": 'C[N+](C)(C)C1=CC=CC=C1.[Cl-]',
  "Trimethylphenylammonium Hydroxide": 'C[N+](C)(C)C1=CC=CC=C1.[OH-]',
  "Trimethylphenylammonium Tribromide": 'C[N+](C)(C)C1=CC=CC=C1.[Br-].[Br-].[Br-]',
  "Trimethylphenylsilane": 'C[Si](C)(C)C1=CC=CC=C1',
  "Trimethylphosphine": 'CP(C)C',
  "Trimethylpropylammonium Bis(trifluoromethanesulfonyl)imide": 'CCC[N+](C)(C)C.O=S([N-]S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O',
  "Trimethylpropylammonium Bromide": 'CCC[N+](C)(C)C.[Br-]',
  "Trimethylsilyl Chloride": 'C[Si](C)(Cl)C',
  "TMSCl": 'C[Si](C)(Cl)C',
  "TMS-chloride": 'C[Si](C)(Cl)C',
  "Trimethylsilyl Azide": 'C[Si](C)(N=[N+]=[N-])C',
  "TMS-Azide": 'C[Si](C)(N=[N+]=[N-])C',
  "TMSA": 'C[Si](C)(N=[N+]=[N-])C',
  "TMSN3": 'C[Si](C)(N=[N+]=[N-])C',
  "Trimethylsilyl Acetate": 'CC(O[Si](C)(C)C)=O',
  "Trimethylsilyl Difluoro(fluorosulfonyl)acetate": 'O=C(O[Si](C)(C)C)C(F)(F)S(=O)(F)=O',
  "Trimethylsilyl Propionate": 'CCC(O[Si](C)(C)C)=O',
  "Trimethylsilyl Trifluoroacetate": 'O=C(O[Si](C)(C)C)C(F)(F)F',
  "Trimethylsilylacetylene": 'C#C[Si](C)(C)C',
  "Trimethylsilylcyclopentadiene": 'C[Si](C1=CC=CC1)(C)C',
  "Trimethylsilylketene Ethyl Trimethylsilyl Acetal": 'C[Si](/C=C(OCC)/O[Si](C)(C)C)(C)C',
  "Trimethylsilylmethylmagnesium Chloride": 'Cl[Mg]C[Si](C)(C)C',
  "Trimethylstearylammonium Bromide": 'CCCCCCCCCCCCCCCCCC[N+](C)(C)C.[Br-]',
  "Trimethylsulfonium Iodide": 'C[S+](C)C.[I-]',
  "Trimethyltetradecylammonium Chloride": 'CCCCCCCCCCCCCC[N+](C)(C)C.[Cl-]',
  "Trimethyltin Chloride": 'C[Sn](C)(Cl)C',
  "Tri-n-octylphosphine": 'CCCCCCCCP(CCCCCCCC)CCCCCCCC',
  "Tri-n-octylphosphine Oxide": 'CCCCCCCCP(CCCCCCCC)(CCCCCCCC)=O',
  "Trioctylmethylammonium Chloride": 'C[N+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[Cl-]',
  "Triphenyl Phosphite": 'P(OC1=CC=CC=C1)(OC2=CC=CC=C2)OC3=CC=CC=C3',
  "Triphenylantimony": '[Sb](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylantimony Diacetate": 'CC(O)=O.CC(O)=O.[Sb](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylantimony Dichloride": 'Cl[Sb](C1=CC=CC=C1)(C2=CC=CC=C2)(Cl)C3=CC=CC=C3',
  "Triphenylantimony Oxide": 'O=[Sb](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylarsine": '[As](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylchlorogermane": 'Cl[Ge](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylphosphine": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylphosphine Borane": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.B',
  "Triphenylphosphine Oxide": 'O=P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylphosphine Selenide": '[Se]=P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylsilane": '[SiH](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylsilanol": 'O[Si](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphenylvinylsilane": 'C=C[Si](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3',
  "Triphosgene": 'ClC(Cl)(OC(OC(Cl)(Cl)Cl)=O)Cl',
  "Tris(1-pyrazolyl)methane": 'C(N1N=CC=C1)(N2N=CC=C2)N3N=CC=C3',
  "Tris(2,2'-bipyridyl)ruthenium(II) Chloride Hexahydrate": '[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].C1(C2=NC=CC=C2)=NC=CC=C1.C3(C4=NC=CC=C4)=NC=CC=C3.C5(C6=NC=CC=C6)=NC=CC=C5.[Ru+2].[Cl-].[Cl-]',
  "Tris(2,4-di-tert-butylphenyl) Phosphite": 'CC(C1=CC=C(OP(OC2=CC=C(C(C)(C)C)C=C2C(C)(C)C)OC3=CC=C(C(C)(C)C)C=C3C(C)(C)C)C(C(C)(C)C)=C1)(C)C',
  "Tris(2,4-pentanedionato)cobalt(III)": 'CC(/C=C(C)\\[O-])=O.[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[Co+3]',
  "Tris(2,4-pentanedionato)iron(III)": '[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[Fe+3]',
  "Tris(2,4-pentanedionato)manganese(III)": 'CC(/C=C(C)\\[O-])=O.[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[Mn+3]',
  "Tris(2,6-dimethoxyphenyl)phosphine": 'COC1=C(P(C2=C(OC)C=CC=C2OC)C3=C(OC)C=CC=C3OC)C(OC)=CC=C1',
  "Tris(2-aminoethyl)amine": 'NCCN(CCN)CCN',
  "Tris(2-benzimidazolylmethyl)amine": 'N(CC1=NC2=CC=CC=C2N1)(CC3=NC4=CC=CC=C4N3)CC5=NC6=CC=CC=C6N5',
  "Tris(2-carboxyethyl)phosphine Hydrochloride": 'O=C(CCP(CCC(O)=O)CCC(O)=O)O.[H]Cl',
  "Tris(2-methoxyphenyl)phosphine": 'COC1=CC=CC=C1P(C2=CC=CC=C2OC)C3=CC=CC=C3OC',
  "Tris(2-phenylpyridinato)iridium(III)": 'C1(C2=CC=CC=C2)=NC=CC=C1.C3(C4=CC=CC=C4)=NC=CC=C3.C5(C6=CC=CC=C6)=NC=CC=C5.[Ir]',
  "Tris(2-pyridylmethyl)amine": 'N(CC1=NC=CC=C1)(CC2=NC=CC=C2)CC3=NC=CC=C3',
  "Tris(3,5-dimethyl-1-pyrazolyl)methane": 'CC1=CC(C)=NN1C(N2N=C(C)C=C2C)N3N=C(C)C=C3C',
  "Tris(3-hydroxypropyltriazolylmethyl)amine": 'OCCCC(N(C(CCCO)C1=CNN=N1)C(CCCO)C2=CNN=N2)C3=CNN=N3',
  "Tris(4-fluorophenyl)phosphine": 'FC1=CC=C(P(C2=CC=C(F)C=C2)C3=CC=C(F)C=C3)C=C1',
  "Tris(4-methoxyphenyl)phosphine": 'COC1=CC=C(P(C2=CC=C(OC)C=C2)C3=CC=C(OC)C=C3)C=C1',
  "Tris(dibenzoylmethanato) Iron": '[O-]/C(C1=CC=CC=C1)=C\\C(C2=CC=CC=C2)=O.[O-]/C(C3=CC=CC=C3)=C\\C(C4=CC=CC=C4)=O.[O-]/C(C5=CC=CC=C5)=C\\C(C6=CC=CC=C6)=O.[Fe+3]',
  "Tris(dibenzylideneacetone)dipalladium(0)": 'O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2.O=C(/C=C/C3=CC=CC=C3)/C=C/C4=CC=CC=C4.O=C(/C=C/C5=CC=CC=C5)/C=C/C6=CC=CC=C6.[Pd].[Pd]',
  "Pd2(dba)3": 'O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2.O=C(/C=C/C3=CC=CC=C3)/C=C/C4=CC=CC=C4.O=C(/C=C/C5=CC=CC=C5)/C=C/C6=CC=CC=C6.[Pd].[Pd]',
  "Tris(diethylamino)phosphine": 'CCN(P(N(CC)CC)N(CC)CC)CC',
  "Tris(hydroxymethyl)aminomethane": 'NC(CO)(CO)CO',
  "Tris(pentafluorophenyl)phosphine": 'FC1=C(F)C(F)=C(F)C(F)=C1P(C2=C(F)C(F)=C(F)C(F)=C2F)C3=C(F)C(F)=C(F)C(F)=C3F',
  "Tris(trimethylsilyloxy)silane": 'C[Si](O[SiH](O[Si](C)(C)C)O[Si](C)(C)C)(C)C',
  "Tris(triphenylphosphine)rhodium(I) Chloride": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9.[Rh+].[Cl-]',
  "Tris(triphenylphosphine)ruthenium(II) Dichloride": 'P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9.[Ru+2].[Cl-].[Cl-]',
  "Tris[(1-benzyl-1H-1,2,3-triazol-4-yl)methyl]amine": 'N(CC1=CN(CC2=CC=CC=C2)N=N1)(CC3=CN(CC4=CC=CC=C4)N=N3)CC5=CN(CC6=CC=CC=C6)N=N5',
  "Tris[2-(dimethylamino)ethyl]amine": 'CN(CCN(CCN(C)C)CCN(C)C)C',
  "Tris[3,5-bis(trifluoromethyl)phenyl]phosphine": 'FC(C1=CC(C(F)(F)F)=CC(P(C2=CC(C(F)(F)F)=CC(C(F)(F)F)=C2)C3=CC(C(F)(F)F)=CC(C(F)(F)F)=C3)=C1)(F)F',
  "Trisodium Paraperiodate": 'O=I([O-])([O-])(O)(O)[O-].[Na+].[Na+].[Na+]',
  "Tri-tert-butyl 1,4,7,10-Tetraazacyclododecane-1,4,7,10-tetraacetate": 'O=C(OC(C)(C)C)CN1CCN(CC(OC(C)(C)C)=O)CCN(CC(OC(C)(C)C)=O)CCN(CC([O-])=O)CC1',
  "Tri-tert-butyl 1,4,7,10-Tetraazacyclododecane-1,4,7-triacetate": 'O=C(OC(C)(C)C)CN1CCN(CC(OC(C)(C)C)=O)CCN(CC(OC(C)(C)C)=O)CCNCC1',
  "Tri-tert-butylphosphonium Tetrafluoroborate": 'CC([PH+](C(C)(C)C)C(C)(C)C)(C)C.F[B-](F)(F)F',
  "Tungsten": '[W]',
  "Urea hydrogen peroxide": 'NC(N)=O.OO',
  "Vanadium": '[V]',
  "Vinylmagnesium Bromide": 'Br[Mg]C=C',
  "Vinyltrimethoxysilane": 'CO[Si](OC)(C=C)OC',
  "Vinyltrimethylsilane": 'C[Si](C)(C=C)C',
  "Vinyltris(2-methoxyethoxy)silane": 'COCCO[Si](OCCOC)(C=C)OCCOC',
  "water": '[H]O[H]',
  "Ytterbium": '[Yb]',
  "Ytterbium(III) Trifluoromethanesulfonate Hydrate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[H]O[H].[Yb+3]',
  "Yttrium": '[Y]',
  "Zinc Bromide": '[Zn+2].[Br-].[Br-]',
  "Zinc Chloride": '[Zn+2].[Cl-].[Cl-]',
  "Zinc peroxide": '[O-][O-].[Zn+2]',
  "Zinc(II) Bis(trifluoromethanesulfinate) Dihydrate": 'O=S(C(F)(F)F)[O-].O=S(C(F)(F)F)[O-].[H]O[H].[H]O[H].[Zn+2]',
  "Zinc(II) Trifluoromethanesulfonate": 'O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Zn+2]',
  "zink hydroxide": '[Zn+2].[OH-].[OH-]',
  "zink stannate": 'O=[Sn]([O-])[O-].[Zn+2]',
  "Zirconium": '[Zr]',
  "Zirconium hydroxide": '[Zr+4].[OH-].[OH-].[OH-].[OH-]',
  "α-Methyl-L-proline": 'O=C(O)[C@@]1(C)NCCC1',
  "β-Isocupreidine": '[H][C@@](C1([H])[H])(C([H])([H])C2([H])[H])[C@@]3(C([H])([H])C([H])([H])[H])O[C@](C4=C(C([H])=C5O[H])C(C([H])=C5[H])=NC([H])=C4[H])([H])[C@]1([H])[N@@]2C3([H])[H]',
  "Di-tert-butyl dicarbonate": 'O=C(OC(OC(C)(C)C)=O)OC(C)(C)C',
  "Boc2O": 'O=C(OC(OC(C)(C)C)=O)OC(C)(C)C',
  "N-(9H-Fluoren-9-ylmethoxycarbonyloxy)succinimide": 'O=C(ON(C1=O)C(CC1)=O)OCC2C(C=CC=C3)=C3C4=C2C=CC=C4',
  "Fmoc-Osu": 'O=C(ON(C1=O)C(CC1)=O)OCC2C(C=CC=C3)=C3C4=C2C=CC=C4',
};
